import { isEqual } from 'lodash';
import { StateCreator, StoreMutatorIdentifier } from 'zustand';

type ProtocolVersion = <
  T extends { protocol: any },
  A,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  f: StateCreator<T, [...Mps, ['protocolVersion', A]], Mcs>,
) => StateCreator<T, Mps, [['protocolVersion', A], ...Mcs]>;

declare module 'zustand' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface StoreMutators<S, A> {
    protocolVersion: Write<Cast<S, object>, { localVersion: number }>;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ProtocolVersionImpl = <T extends { protocol: any }, A>(
  initializer: StateCreator<T, [], []>,
) => StateCreator<T, [], []>;

const protocolVersionImpl: ProtocolVersionImpl =
  (initializer) => (set, get, store) => {
    const setState: typeof store.setState = (updater, replace, ...a) => {
      const previousState = get();
      const nextState =
        typeof updater === 'function' ? updater(previousState) : updater;

      if (!isEqual(previousState.protocol, nextState.protocol)) {
        // increase local version when protocol has changed
        return set(
          {
            ...nextState,
            protocol: {
              ...(nextState.protocol ?? {}),
              localVersion: (get().protocol?.localVersion ?? 0) + 1,
            },
          } as any,
          replace,
          ...a,
        );
      } else {
        // otherwise just set the state
        return set(nextState, replace, ...a);
      }
    };

    return initializer(setState, get, store);
  };

export const protocolVersion =
  protocolVersionImpl as unknown as ProtocolVersion;

type Write<T extends object, U extends object> = Omit<T, keyof U> & U;

type Cast<T, U> = T extends U ? T : U;
