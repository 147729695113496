import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';

interface FileOptions {
  acceptedExtension: string;
  encoding?: string;
}

export const readFile = (
  file: File,
  fileOption: FileOptions,
): Promise<string | ArrayBuffer | null> => {
  if (!file.name.endsWith(fileOption.acceptedExtension)) {
    throw new AppError(ErrorType.INVALID_FILE_TYPE, file.name.toString());
  }

  const reader = new FileReader();
  const readerPromise = new Promise<string | ArrayBuffer | null>(
    (resolve, reject) => {
      reader.onabort = () =>
        reject(new AppError(ErrorType.FILE_IMPORT_FAILED, file.name));

      reader.onerror = () =>
        new AppError(ErrorType.FILE_IMPORT_FAILED, file.name);
      reader.onload = () => {
        resolve(reader.result);
      };
    },
  );
  reader.readAsText(file, fileOption.encoding);
  return readerPromise;
};
