import AddCircleIcon from '@mui/icons-material/AddCircle';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';
import { PrimaryButton } from '@/common/ui/buttons';

const StyledDropZone = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
  borderRadius: theme.shape.borderRadius,
  height: 90,
  width: '100%',
  padding: theme.spacing(2, 4),
}));

const UploadIcon = styled(DriveFolderUploadRoundedIcon)(({ theme }) => ({
  height: '100%',
  width: 50,
  color: alpha(theme.palette.primary.main, 0.5),
}));

const UploadText = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  fontSize: theme.typography.body2.fontSize,
  padding: theme.spacing(0, 3),
}));

type AcceptedFiles =
  | { 'text/vcard': '.vcf'[] }
  | {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx'[];
    };

export interface DropzoneFile {
  dropzoneFileName: string;
  file: string | ArrayBuffer;
}

interface Props {
  onDrop: (newFiles: File[]) => void;
  dragActiveText: string;
  dragNotActiveText: string;
  buttonText: string;
  accepted: AcceptedFiles;
  onError: (error: AppError[]) => void;
  isLoading?: boolean;
}

export const Dropzone = ({
  isLoading,
  dragActiveText,
  dragNotActiveText,
  buttonText,
  onDrop,
  accepted,
  onError,
  ...props
}: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accepted,
    onDropRejected: (rejectedFiles) => {
      onError(
        rejectedFiles.map(
          (rejected) =>
            new AppError(ErrorType.INVALID_FILE_TYPE, rejected.file.name),
        ),
      );
    },
  });

  return (
    <div {...props}>
      <StyledDropZone {...getRootProps()}>
        <input {...getInputProps()} />
        <UploadIcon />
        <UploadText>
          {isDragActive ? dragActiveText : dragNotActiveText}
        </UploadText>
        <PrimaryButton
          loading={isLoading}
          size={'small'}
          startIcon={<AddCircleIcon />}
          style={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}
        >
          {buttonText}
        </PrimaryButton>
      </StyledDropZone>
    </div>
  );
};
