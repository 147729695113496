import {
  CircularProgress,
  ListItemIcon,
  ListItemIconProps,
  SvgIconProps,
} from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactElement<SvgIconProps>;
  isLoading?: boolean;
}

export const LoadingIcon = ({
  isLoading,
  children,
  ...props
}: Props & ListItemIconProps) => {
  return (
    <ListItemIcon {...props}>
      {isLoading ? <CircularProgress size={24} /> : children}
    </ListItemIcon>
  );
};
