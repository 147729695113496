import React from 'react';

import { ProtocolUserDto } from '@ibag/common';

import { OverflowActionMenu } from '@/common/ui/buttons/overflow-action-menu';

import { LockMenuItem, MakeOfflineAvailableMenuItem } from '../menu-items';

interface Props {
  protocolId: string;
  lockedBy: ProtocolUserDto | null;
}

export const ProtocolTableRowOverflowMenu = ({
  protocolId,
  lockedBy,
}: Props) => {
  // make sure that menu items do have their final size directly,
  // otherwise the dropdown-menu will not be placed correctly
  return (
    <OverflowActionMenu
      overflowActions={[
        <MakeOfflineAvailableMenuItem
          protocolId={protocolId}
          key={'make_offline_available'}
        />,
        <LockMenuItem
          protocolId={protocolId}
          lockedBy={lockedBy}
          key={'lock'}
        />,
      ]}
    />
  );
};
