import { initializeApp } from 'firebase/app';

import { ENV } from '@/common/config';
import { AuthService } from '@/services/auth';

import devConfig from '../firebase-config.development.json';
import prodConfig from '../firebase-config.production.json';
import { httpClient } from './http-client-instance';

// initialize firebase app
initializeApp(ENV === 'production' ? prodConfig : devConfig);

export const authService = new AuthService(httpClient);
