import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';

interface Props {
  readonly: boolean;
}

/**
 * Updates the editable state of the editor, whenever readonly changes.
 */
export const EditablePlugin = ({ readonly }: Props) => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    editor.setEditable(!readonly);
  }, [editor, readonly]);

  return null;
};
