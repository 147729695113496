import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssigneePreset } from '@ibag/common';

import { GroupHeader } from '@/common/styles';
import { PrimaryButton, SecondaryButton } from '@/common/ui/buttons';
import { Dialog } from '@/common/ui/dialog';
import { useProtocolState } from '@/pages/edit-protocol/common';

import { SigekoStore } from '../../sigeko-store';

const DialogContent = styled('div')(({ theme }) => ({
  margin: theme.spacing(5, 0),
  display: 'flex',
  flexDirection: 'column',
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const enum OptionGroup {
  TEXT = 'TEXT',
  CONTACT = 'CONTACT',
  ORGANISATION = 'ORGANISATION',
}

interface Props {
  isDialogOpen: boolean;
  onClose: () => void;
  defectId: string;
  assignees: string[] | null;
}

export function SetAssigneesDialog({
  isDialogOpen,
  onClose,
  defectId,
  assignees,
}: Props) {
  const { t } = useTranslation();

  const [assigneesValue, setAssigneesValue] = useState<string[]>(
    assignees !== null ? assignees : [],
  );
  const [contacts, setAssignees] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.contacts,
    s.setAssignees,
  ]);

  const handleSubmit = () => {
    setAssignees(defectId, assigneesValue);
    onClose();
  };

  const textBlockOptions = Object.values(AssigneePreset).map((value) =>
    t(`common.assignee_presets.${value}`),
  );
  const contactOptions = _(contacts)
    .filter((it) => !!it.name)
    .map((option) => `${option.name} (${option.organisation})`)
    .sort()
    .value();

  const organisationOptions = _(contacts)
    .filter((it) => !!it.organisation)
    .map((option) => option.organisation)
    .uniq()
    .sort()
    .value();

  const allOptions = [
    ...textBlockOptions,
    ...organisationOptions,
    ...contactOptions,
  ];

  const optionsToGroupMapping = {
    ...createMapping(textBlockOptions, OptionGroup.TEXT),
    ...createMapping(contactOptions, OptionGroup.CONTACT),
    ...createMapping(organisationOptions, OptionGroup.ORGANISATION),
  };

  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={onClose}
      title={t('page.edit_protocol.actions.add_assignee.dialog.title')}
      contentPadding={3}
      actions={() => {
        return (
          <>
            <SecondaryButton onClick={onClose}>
              {t(
                'page.edit_protocol.actions.add_assignee.dialog.button_cancel',
              )}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmit}>
              {t(
                'page.edit_protocol.actions.add_assignee.dialog.button_submit',
              )}
            </PrimaryButton>
          </>
        );
      }}
    >
      <DialogContent>
        <Stack spacing={3} sx={{ width: 500 }}>
          <Autocomplete
            multiple
            options={allOptions}
            groupBy={(option) =>
              t(
                `page.edit_protocol.actions.add_assignee.dialog.option_groups.${optionsToGroupMapping[option]}`,
              )
            }
            value={assigneesValue ?? undefined}
            onChange={(e, newAssignees) => {
              setAssigneesValue(convertOptionObjectsToStrings(newAssignees));
            }}
            filterSelectedOptions
            freeSolo
            renderGroup={(params) => (
              <div key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </div>
            )}
            renderTags={(newAssignees, getAssigneeProps) => {
              return convertOptionObjectsToStrings(newAssignees).map(
                (option: string, index: number) => {
                  return (
                    // Problem: always error, when using key: 'key' is specified more than once, so this usage will be overwritten.
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      variant="filled"
                      label={option}
                      {...getAssigneeProps({ index })}
                    />
                  );
                },
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  'page.edit_protocol.actions.add_assignee.dialog.label_assignees',
                )}
                placeholder={t(
                  'page.edit_protocol.actions.add_assignee.dialog.placeholder',
                )}
              />
            )}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

const convertOptionObjectsToStrings = (
  options: (string | { name: string; group: string })[],
): string[] => {
  return options.map((option) => {
    if (typeof option === 'string') {
      return option;
    } else {
      return option.name;
    }
  });
};

const createMapping = (
  key: string[],
  value: string,
): Record<string, string> => {
  const mapping: Record<string, string> = {};

  key.forEach((option) => {
    mapping[option] = value;
  });

  return mapping;
};
