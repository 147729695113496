import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Deadline,
  SigekoProtocolDefect as Defect,
} from '@/common/types/protocol';
import { ActionButton } from '@/common/ui/buttons';
import { convertDateToDateString } from '@/common/utils/format-date';

import { SetDeadlineDialog } from './SetDeadlineDialog';

interface Props {
  defect: Defect;
  disabled?: boolean;
}

export const SetDeadlineButton = ({ defect, disabled }: Props) => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useDialog<void, void>();

  const convertDeadlineToString = (deadline: Deadline | null): string => {
    if (typeof deadline === 'string') {
      return t(`common.deadline_presets.${deadline}`);
    } else if (defect.deadline instanceof Date) {
      return convertDateToDateString(defect.deadline);
    } else {
      return t('page.edit_protocol.actions.add_deadline.button_title');
    }
  };
  return (
    <>
      <ActionButton
        key={'deadline'}
        disabled={disabled}
        startIcon={<CalendarMonthIcon />}
        onClick={() => open()}
        label={convertDeadlineToString(defect.deadline)}
      />
      <SetDeadlineDialog
        defectId={defect.id}
        isDialogOpen={isOpen}
        onClose={close}
        deadline={defect.deadline}
      />
    </>
  );
};
