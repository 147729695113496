import { useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUser } from '@/common/hooks/use-user';
import { createLockStatus } from '@/common/ui/lock-status-icon';
import { convertDateToDateString } from '@/common/utils/format-date';
import {
  LockMenuItem,
  MakeOfflineAvailableMenuItem,
} from '@/pages/home/menu-items';
import { routes } from '@/routing';

import { BaseCard } from './BaseCard';
import { createCardIcon, createSyncStatus } from './common';
import { SigekoCardContent } from './types';

interface Props {
  protocol: SigekoCardContent;
}

export const ProtocolCard = ({ protocol }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const user = useUser();
  const navigate = useNavigate();

  return (
    <BaseCard
      title={protocol.title}
      subTitle={protocol.location}
      icon={createCardIcon(protocol.status, theme).icon}
      iconColor={createCardIcon(protocol.status, theme).color}
      onClick={() => navigate(routes.generateEditProtocolPath(protocol.id))}
      details={[
        {
          description: t(
            'page.home.offline_available_protocol_cards.protocol_card.created_by',
          ),
          value: protocol.writtenBy,
        },
        {
          description: t(
            'page.home.offline_available_protocol_cards.protocol_card.version',
          ),
          value: protocol.protocolNumber,
        },
        {
          description: t(
            'page.home.offline_available_protocol_cards.protocol_card.last_inspection',
          ),
          value: convertDateToDateString(protocol.date),
        },
      ]}
      detailStatuses={[
        createLockStatus(protocol.lockedBy, user),
        createSyncStatus(protocol.isSynced),
      ]}
      actions={[
        <LockMenuItem
          key="lock"
          protocolId={protocol.id}
          lockedBy={protocol.lockedBy}
        />,
        <MakeOfflineAvailableMenuItem
          protocolId={protocol.id}
          key="make_offline_available"
        />,
      ]}
    />
  );
};
