import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

export const useContact = (contactId: string) => {
  const [contacts] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.contacts.find((contact) => contact.id === contactId),
    s.updateHeaderField,
  ]);
  return contacts;
};
