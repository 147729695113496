import { styled } from '@mui/material/styles';
import React from 'react';

import { LexicalContent } from '@/common/types/protocol';
import { PrintEditor } from '@/common/ui/text-editor/editor';
import { Headline } from '@/pages/export/ui/Headline';

export const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

interface Props {
  editorText: LexicalContent;
  title: string;
}

export const TextEditorPrintView = ({ editorText, title }: Props) => {
  return (
    <Container>
      <Headline>{title}</Headline>
      <PrintEditor initialContent={editorText} />
    </Container>
  );
};
