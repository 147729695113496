import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateUserPayload } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';

export const useEditUser = (id: string, onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const { userService } = useServices();
  return useMutation<void, AppError, CreateUserPayload>(
    async (userRequest) => {
      await userService.editUser(userRequest, id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        onSuccess();
      },
    },
  );
};
