export var ProtocolStatus;
(function (ProtocolStatus) {
    ProtocolStatus["NEW"] = "NEW";
    ProtocolStatus["READY_FOR_EDITING"] = "READY_FOR_EDITING";
    ProtocolStatus["IN_EDITING"] = "IN_EDITING";
    ProtocolStatus["COMPLETED"] = "COMPLETED";
    ProtocolStatus["ARCHIVED"] = "ARCHIVED";
})(ProtocolStatus || (ProtocolStatus = {}));
export function nextStatus(status) {
    switch (status) {
        case ProtocolStatus.READY_FOR_EDITING:
            return ProtocolStatus.IN_EDITING;
        case ProtocolStatus.IN_EDITING:
            return ProtocolStatus.COMPLETED;
        case ProtocolStatus.NEW:
        case ProtocolStatus.COMPLETED:
        case ProtocolStatus.ARCHIVED:
            return ProtocolStatus.READY_FOR_EDITING;
    }
}
