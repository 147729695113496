import { max } from 'lodash';
import { nanoid } from 'nanoid';

import { ProtocolUserDto, TemplateType } from '@ibag/common';

import {
  Deadline,
  EditorContentState,
  ImageSize,
  KeyOfSigekoProtocolHeader,
  RichTextSelector,
  SigekoProtocol,
  SigekoProtocolDefect,
  SigekoProtocolHeader,
} from '@/common/types/protocol';
import { Database, generateServerImageUrl } from '@/services/database';

import { BaseStore, ProtocolState, createStoreWithMiddleware } from '../common';
import { convertTextToLexicalContent, nextDefectNumber } from './logic';

export type SigekoProtocolState = ProtocolState<SigekoProtocol>;
export type SigekoRichTextSelector = RichTextSelector<SigekoProtocolState>;

export interface SigekoStore extends BaseStore<SigekoProtocolState> {
  addDefect: () => void;
  addDefectUpdate: (defectId: string, defaultText: string) => void;
  setDeadline: (defectId: string, newDeadline: Deadline | null) => void;
  setAssignees: (defectId: string, assignees: string[] | null) => void;

  // we use a generic helper type because the value type has to adapt depending on the fieldname type.
  updateHeaderField: <F extends KeyOfSigekoProtocolHeader>(
    fieldName: F,
    value: SigekoProtocolHeader[F],
  ) => void;
  setWrittenBy: (user: ProtocolUserDto | null) => void;
  deleteDefectUpdate: (defectId: string, updateId: string) => void;
  deleteDefect: (defectId: string) => void;
  markDefectAsDone: (defectId: string) => void;
  markDefectAsNotDone: (defectId: string) => void;

  showDone: boolean;
  toggleShowDone: () => void;
}

export const createSigekoProtocolStore = (
  protocol: SigekoProtocolState,
  database: Database,
) => {
  return createStoreWithMiddleware<SigekoStore>(
    protocol.id,
    database,
    (set) => ({
      protocol,

      replaceProtocol: (newProtocol) =>
        set((s) => {
          s.protocol = newProtocol;
        }),

      updateText(
        selector: SigekoRichTextSelector,
        editorState: EditorContentState,
      ): void {
        set((s) => {
          const richText = selector(s.protocol);
          if (richText) {
            richText.text = editorState.lexicalContent;
            richText.title = editorState.title;
          }
        });
      },

      addDefect: () =>
        set((s) => {
          s.protocol.content.defects.push({
            id: nanoid(8),
            defectNumber: nextDefectNumber(s.protocol),
            text: null,
            title: null,
            images: [],
            deadline: null,
            assignees: null,
            updates: [],
            done: null,
          });
        }),

      addImages: (selector, images) =>
        set((s) => {
          const richText = selector(s.protocol);
          if (richText) {
            richText.images.push(
              ...images.map((dbImage) => ({
                id: dbImage.id,
                size: ImageSize.MEDIUM,
                url: generateServerImageUrl(dbImage),
              })),
            );
          }
        }),

      sortImages: (selector, startIdx, targetIdx) =>
        set((s) => {
          const richText = selector(s.protocol);
          if (richText) {
            const dragImage = richText.images[startIdx];
            richText.images.splice(startIdx, 1); // remove from old position
            richText.images.splice(targetIdx, 0, dragImage); // insert at new position
          }
        }),

      changeImageSize: (selector, imageId, size) =>
        set((s) => {
          const richText = selector(s.protocol);
          const image = richText?.images.find((i) => i.id === imageId);
          if (image) {
            image.size = size;
          }
        }),

      deleteImage: (selector, imageId) => {
        set((s) => {
          const richText = selector(s.protocol);
          if (richText) {
            richText.images = richText.images.filter((i) => i.id !== imageId);
          }
        });
      },
      addDefectUpdate(defectId: string, defaultText: string): void {
        set((s) => {
          const defect = findDefect(s, defectId);
          defect?.updates.push({
            id: nanoid(),
            text: convertTextToLexicalContent(defaultText),
            title: defaultText,
            images: [],
          });
        });
      },

      setDeadline: (defectId, newDeadline) => {
        set((s) => {
          const defect = findDefect(s, defectId);
          if (defect) {
            defect.deadline = newDeadline;
          }
        });
      },

      setAssignees: (defectId, newAssignees) => {
        set((s) => {
          const defect = findDefect(s, defectId);
          if (defect) {
            defect.assignees =
              newAssignees !== null
                ? newAssignees.length === 0
                  ? null
                  : newAssignees
                : null;
          }
        });
      },

      updateHeaderField: (fieldName, value) => {
        set((s) => {
          s.protocol.header[fieldName] = value;
        });
      },

      setWrittenBy(user: ProtocolUserDto | null): void {
        set((s) => {
          s.protocol.writtenBy = user;
        });
      },

      deleteDefectUpdate: (defectId: string, updateId: string): void => {
        set((s) => {
          const defect = findDefect(s, defectId);
          if (defect) {
            const updateIndex = defect.updates.findIndex(
              (update) => updateId === update.id,
            );
            defect.updates.splice(updateIndex, 1);
          }
        });
      },

      deleteDefect: (defectId: string): void => {
        set((s) => {
          const defects = s.protocol.content.defects;
          const defectIndexToDelete = defects.findIndex(
            (update) => defectId === update.id,
          );
          const [deleted] = defects.splice(defectIndexToDelete, 1);

          // update defect numbers of items with deleted protocolNumber
          defects
            .filter(
              (it) =>
                it.defectNumber.protocolNumber ===
                deleted.defectNumber.protocolNumber,
            )
            .forEach((defect, index) => {
              defect.defectNumber.itemNumber = index + 1;
            });
        });
      },

      markDefectAsDone: (defectId: string) => {
        set((s) => {
          const defect = findDefect(s, defectId);
          if (defect) {
            defect.done = s.protocol.header.protocolNumber;
          }
        });
      },
      markDefectAsNotDone: (defectId: string) => {
        set((s) => {
          const defect = findDefect(s, defectId);
          if (defect) {
            defect.done = null;
          }
        });
      },
      title: protocol.title,
      setTitle: (newTitle: string) => {
        set((s) => {
          s.protocol.title = newTitle;
        });
      },
      templateTypeFilter: null,
      setTemplateTypeFilter: (newTemplateTypeFilter: TemplateType | null) => {
        set((s) => {
          s.templateTypeFilter = newTemplateTypeFilter;
        });
      },
      activeEditorId: null,
      setActiveEditorId: (id) =>
        set((s) => {
          s.activeEditorId = id;
        }),

      showDone: false,
      toggleShowDone: () =>
        set((s) => {
          s.showDone = !s.showDone;
        }),
      protocolNumberOnOpen: protocol.header.protocolNumber,
      setProtocolNumberOnOpen: (protocolNumber: number) =>
        set((s) => {
          s.protocolNumberOnOpen = protocolNumber;
        }),

      changeProtocolNumber: (newProtocolNumber) =>
        set((s) => {
          const minProtocolNumber =
            max(
              s.protocol.content.defects.map(
                (it) => it.defectNumber.protocolNumber,
              ),
            ) ?? newProtocolNumber;

          console.log(
            'minProtocolNumber',
            minProtocolNumber,
            'newProtocolNumber',
            newProtocolNumber,
          );

          if (newProtocolNumber >= minProtocolNumber) {
            s.protocol.header.protocolNumber = newProtocolNumber;
          }
        }),
    }),
  );
};

/**
 * Helper functions
 */
function findDefect(
  s: SigekoStore,
  defectId: string,
): SigekoProtocolDefect | null {
  return s.protocol.content.defects.find((i) => i.id === defectId) ?? null;
}
