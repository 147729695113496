import produce from 'immer';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useLiveProtocol } from '@/common/hooks/use-live-protocol';
import { VisibilityChangeProvider } from '@/common/providers/visibility-change-provider';
import { SigekoProtocol, filterOpenDefects } from '@/common/types/protocol';

import { ExportView } from './ExportView';

export const ExportViewPage = () => {
  const { id } = useParams();
  const protocol = useLiveProtocol(id!);
  return protocol ? (
    <VisibilityChangeProvider>
      <ExportView protocol={filterDoneProtocolDefects(protocol)} />
    </VisibilityChangeProvider>
  ) : null;
};

export const filterDoneProtocolDefects = (
  protocol: SigekoProtocol,
): SigekoProtocol => {
  return produce(protocol, (draft) => {
    draft.content.defects = filterOpenDefects(
      protocol.content.defects,
      protocol.header.protocolNumber,
    );
  });
};
