import { nanoid } from 'nanoid';
import quotedPrintable from 'quoted-printable';
import vCard from 'vcf';

import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';
import { Contact, ContactCategory } from '@/common/types/protocol';

export function parseVcf(filename: string, raw: string): Contact[] {
  try {
    const convertedResult = quotedPrintable.decode(raw);

    // split multi-vcard-string into separate strings
    const regex = /BEGIN:VCARD[\s\S]*?END:VCARD/g;
    const vcards = Array.from(
      convertedResult.matchAll(regex),
      (match) => match[0],
    );

    return vcards.map((rawVcard) => {
      const newVCard = new vCard().parse(rawVcard);

      let name = newVCard.get('fn').valueOf().toString();
      const organisation = newVCard.get('org')?.valueOf()?.toString() ?? '';

      if (name.includes(organisation)) {
        name = removeLeadingCommasAndWhitespace(name.replace(organisation, ''));
      }

      return {
        id: nanoid(),
        name,
        organisation,
        category: ContactCategory.CONTRACTOR,
      };
    });
  } catch (err) {
    throw new AppError(
      ErrorType.INVALID_VCARD,
      `${filename}  ${(err as Error).message}`,
    );
  }
}

function removeLeadingCommasAndWhitespace(str: string): string {
  const regex = /^\s*,*/;
  return str.replace(regex, '');
}
