import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolStatus } from '@ibag/common';

import { PrimaryButton } from '@/common/ui/buttons';
import { DropDownMenuItem } from '@/common/ui/dropdown';
import { BaseSelect } from '@/common/ui/select';

interface Props {
  onFilterChange: (selection: ProtocolStatus | null) => void;
  selectedFilter: ProtocolStatus | null;
  label: string;
}

export const StatusFilter = ({
  onFilterChange,
  selectedFilter,
  label,
  ...props
}: Props & React.ComponentProps<typeof PrimaryButton>) => {
  const { t } = useTranslation();

  const statusFilterOptions = Object.values(ProtocolStatus).map((status) => {
    return (
      <DropDownMenuItem
        key={status}
        onClick={() => {
          onFilterChange(status);
        }}
      >
        {t(`common.protocol_status.${status}`)}
      </DropDownMenuItem>
    );
  });

  const additionalFilterOptions = [
    <DropDownMenuItem
      key={'label_no_filter'}
      onClick={() => {
        onFilterChange(null);
      }}
    >
      {t('page.home.protocol_table.filter.status.label_no_filter')}
    </DropDownMenuItem>,
  ];

  return (
    <BaseSelect
      name={`${label} (${
        selectedFilter
          ? t(`common.protocol_status.${selectedFilter}`)
          : t('page.home.protocol_table.filter.status.label_no_filter')
      })`}
      actions={[...statusFilterOptions, ...additionalFilterOptions]}
      {...props}
    />
  );
};
