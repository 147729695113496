import { InputLabel, Radio } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { GridColDef, gridClasses } from '@mui/x-data-grid';
import produce from 'immer';
import { compact } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledDataGrid } from '@/common/styles';
import { Contact, ContactCategory } from '@/common/types/protocol';
import { DeleteIconButton } from '@/common/ui/buttons';
import { useEditable, useProtocolState } from '@/pages/edit-protocol/common';

import { SigekoStore } from '../../sigeko-store';
import { VcfDropzone } from '../VcfDropzone';
import { AddContactButton } from './add-contact-button';
import { EditContactButton } from './edit-contact-button';
import { MailingListSummary } from './mailing-list-count';
import { MailingListTableRow } from './type';

const VcfTableContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
  marginBottom: theme.spacing(1),
  maxHeight: 600,
  overflow: 'auto',
}));

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const VcfDropzoneContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const ActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

interface Props {
  onContactsChanged: (newContactList: Contact[]) => void;
  contacts: Contact[];
}

export const MailingList = ({ onContactsChanged, contacts }: Props) => {
  const { t } = useTranslation();
  const [variant] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.variant,
  ]);
  const editable = useEditable();

  const setCategory = (id: string, category: ContactCategory) => {
    onContactsChanged(
      produce(contacts, (draft) => {
        const contactToChange = draft.find((contact) => contact.id === id);
        if (contactToChange !== undefined) {
          contactToChange.category = category;
        }
      }),
    );
  };

  const handleDeleteContact = (id: string) => {
    onContactsChanged([...contacts].filter((contact) => contact.id !== id));
  };
  const columns: GridColDef<MailingListTableRow>[] = [
    {
      field: 'title',
      headerName: t(
        `page.edit_protocol.${variant}.header.inputs.mailing_list.table.name`,
      ),
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'client',
      headerName: t(
        `page.edit_protocol.${variant}.header.inputs.mailing_list.table.client`,
      ),
      flex: 1,
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Radio
            disabled={!editable}
            checked={params.row.category === ContactCategory.CLIENT}
            onChange={() => setCategory(params.row.id, ContactCategory.CLIENT)}
          />
        );
      },
    },
    {
      field: 'contractor',
      headerName: t(
        `page.edit_protocol.${variant}.header.inputs.mailing_list.table.contractor`,
      ),
      flex: 1,
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Radio
            disabled={!editable}
            checked={params.row.category === ContactCategory.CONTRACTOR}
            onClick={() =>
              setCategory(params.row.id, ContactCategory.CONTRACTOR)
            }
          />
        );
      },
    },

    {
      field: 'subcontractor',
      headerName: t(
        `page.edit_protocol.${variant}.header.inputs.mailing_list.table.subcontractor`,
      ),
      flex: 1,
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Radio
            disabled={!editable}
            checked={params.row.category === ContactCategory.SUBCONTRACTOR}
            onChange={() =>
              setCategory(params.row.id, ContactCategory.SUBCONTRACTOR)
            }
          />
        );
      },
    },
    {
      field: 'other',
      headerName: t(
        `page.edit_protocol.${variant}.header.inputs.mailing_list.table.other`,
      ),
      flex: 1,
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Radio
            disabled={!editable}
            checked={params.row.category === ContactCategory.OTHER}
            onChange={() => setCategory(params.row.id, ContactCategory.OTHER)}
          />
        );
      },
    },

    {
      field: 'actions',
      sortable: false,
      align: 'right',
      flex: 1,
      minWidth: 100,
      headerName: t(
        `page.edit_protocol.${variant}.header.inputs.mailing_list.table.actions`,
      ),
      renderCell: (params) => {
        return (
          <ActionContainer>
            <EditContactButton disabled={!editable} id={params.row.id} />
            <DeleteIconButton
              disabled={!editable}
              onDelete={() => handleDeleteContact(params.id.toString())}
            />
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <>
      <InputLabel>
        {t(`page.edit_protocol.${variant}.header.inputs.mailing_list.label`)}
      </InputLabel>
      {editable && (
        <VcfDropzoneContainer>
          <VcfDropzone
            onChangeContacts={onContactsChanged}
            contacts={contacts}
          />
        </VcfDropzoneContainer>
      )}
      <VcfTableContainer>
        <StyledDataGrid
          sortingMode={'client'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'active', sort: 'desc' }],
            },
          }}
          disableVirtualization
          getRowHeight={() => 'auto'}
          disableColumnMenu
          localeText={{
            noRowsLabel: t(
              `page.edit_protocol.${variant}.header.inputs.mailing_list.table.no_rows_label`,
            ),
          }}
          hideFooter
          hideFooterPagination
          autoHeight
          disableSelectionOnClick
          rows={
            convertContactsToMailingListTableRows(contacts).sort((a, b) => {
              return a.title.localeCompare(b.title, 'de', {
                sensitivity: 'base',
              });
            }) ?? []
          }
          rowsPerPageOptions={[]}
          columns={columns}
          sx={{
            [`& .${gridClasses.cell}`]: {
              // Problem: If you will show 2 lines of text, you need getRowHeight to return "auto".
              // This causes smaller padding, so we will need py:1, for the same look and feel as before.
              py: 1,
            },
          }}
        />
      </VcfTableContainer>
      <ButtonContainer>
        <MailingListSummary contacts={contacts} />
        <AddContactButton disabled={!editable} />
      </ButtonContainer>
    </>
  );
};

const convertContactsToMailingListTableRows = (
  contacts: Contact[],
): MailingListTableRow[] => {
  return contacts.map((contact) => {
    return {
      id: contact.id,
      title: compact([contact.organisation, contact.name]).join(', '),
      category: contact.category,
    };
  });
};
