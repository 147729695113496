import { Redo, Undo } from '@mui/icons-material';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW,
  REDO_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import React, { useEffect, useState } from 'react';

import { useToolbarApi } from '../../context';
import { ToolbarIconButton } from '../ToolbarIconButton';

export const HistoryButtons = () => {
  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  const { editor } = useToolbarApi();

  /* Registering the commands with the editor. */
  useEffect(() => {
    const removeUndoListener = editor.registerCommand(
      CAN_UNDO_COMMAND,
      (payload: boolean) => {
        setCanUndo(payload);
        return false;
      },
      COMMAND_PRIORITY_LOW,
    );

    const removeRedoListener = editor.registerCommand(
      CAN_REDO_COMMAND,
      (payload: boolean) => {
        setCanRedo(payload);
        return false;
      },
      COMMAND_PRIORITY_LOW,
    );

    return () => {
      removeUndoListener();
      removeRedoListener();
    };
  }, [editor]);

  return (
    <>
      <ToolbarIconButton
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
      >
        <Undo />
      </ToolbarIconButton>
      <ToolbarIconButton
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
      >
        <Redo />
      </ToolbarIconButton>
    </>
  );
};
