import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { WhiteTypography } from '@/common/styles';

import { OfflineAvailableProtocolCards } from './OfflineAvailableProtocolCards';
import { SyncProtocolsButton } from './sync-button';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
}));

export const OfflineAvailableProtocols = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <WhiteTypography variant={'h2'}>
          {t('page.home.offline_protocols')}
        </WhiteTypography>
        <SyncProtocolsButton />
      </Container>

      <OfflineAvailableProtocolCards />
    </>
  );
};
