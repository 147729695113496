import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { CircularProgress, IconButton, IconButtonProps } from '@mui/material';
import React from 'react';

interface Props {
  onDelete: () => void;
  isLoading?: boolean;
}

export const DeleteIconButton = ({
  isLoading,
  onDelete,
  ...props
}: Props & IconButtonProps) => {
  return (
    <IconButton color={'error'} onClick={onDelete} {...props}>
      {!isLoading ? (
        <DeleteForeverRoundedIcon />
      ) : (
        <CircularProgress size={24} color={'error'} />
      )}
    </IconButton>
  );
};
