import React from 'react';

import { AuthUser } from '@/common/types/auth';

import { useServices } from '../../providers/service-provider';

export function useUser() {
  const { authService } = useServices();

  const [user, setUser] = React.useState<AuthUser | null>(null);

  React.useEffect(() => {
    return authService.onUserChanged(setUser);
  }, [authService]);

  return user;
}
