import React from 'react';

import { MenuLayout } from '@/common/layout/MenuLayout';

export const wrapMenuLayout =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <MenuLayout>
        <Component {...props} />
      </MenuLayout>
    );
