import { Paper, styled } from '@mui/material';

export const StyledToolbarPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.primary.main,
  flexWrap: 'wrap',
  borderRadius: theme.spacing(1, 1, 0, 0),
  padding: theme.spacing(0.5, 1),
}));

export const StyledToolbar = styled('div')(({ theme }) => ({
  width: '100%',
  zIndex: 1,
  padding: theme.spacing(0),
  position: 'sticky',
  top: 0,
}));

export const Spacer = styled('div')({
  flexGrow: 1,
});
