import { Config } from '@bugsnag/core';

import { BUGSNAG_API_KEY, ENV } from '@/common/config';

import PackageJson from '../../../package.json';

export const bugsnagOptions: Config = {
  apiKey: BUGSNAG_API_KEY,
  releaseStage: ENV,
  enabledReleaseStages: ['production'], // enable bug tracking only on production
  appVersion: PackageJson.version,
};
