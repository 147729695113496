import { Divider, styled } from '@mui/material';
import React from 'react';

import { SigekoDefectUpdate } from '@/common/types/protocol';
import { PrintEditor } from '@/common/ui/text-editor/editor';
import { ImagesPrintView } from '@/pages/export/ui';

const Container = styled('div')(() => ({
  breakInside: 'avoid',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

interface Props {
  update: SigekoDefectUpdate;
  activeStyle: boolean;
}

export const Update = ({ update, activeStyle }: Props) => {
  return (
    <Container style={activeStyle ? { fontWeight: 700 } : undefined}>
      <StyledDivider />
      <PrintEditor initialContent={update.text} />
      <ImagesPrintView images={update.images} />
    </Container>
  );
};
