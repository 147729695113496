import Dexie from 'dexie';
import _ from 'lodash';

import { TemplateType } from '@ibag/common';

import { Protocol } from '@/common/types/protocol';
import { Template } from '@/common/types/template';
import { Logger } from '@/services/logger';

import { DatabaseImage } from './database-image';
import { Message } from './message';
import { Task, deserializeTask } from './task';

export class Database extends Dexie {
  protocols!: Dexie.Table<Protocol, string>;
  images!: Dexie.Table<DatabaseImage, string>;
  tasks!: Dexie.Table<Task, number>;
  messages!: Dexie.Table<Message, number>;
  templates!: Dexie.Table<Template, number>;

  constructor() {
    super('ibag');

    this.version(8)
      .stores({
        protocols: 'id, status, createdAt, updatedAt',
        images: 'id',
        tasks: 'id++,type,group',
        messages: 'id++',
        templates: 'id,updatedAt,title,rawText,type',
      })
      /* all template type to MISC*/
      .upgrade((tx) => {
        return tx
          .table('templates')
          .toCollection()
          .modify((template) => {
            template.type = 'MISC';
          });
      });
  }

  /**
   * Returns offline available protocols or protocols with unsynced changes
   */
  async fetchProtocolsToSync(): Promise<Protocol[]> {
    return (await this.protocols.toArray()).filter(
      (it) => it.offlineAvailable || it.localVersion !== null,
    );
  }

  async fetchProtocols(): Promise<Protocol[]> {
    return this.protocols.toArray();
  }

  async fetchOfflineProtocols(): Promise<Protocol[]> {
    const offlineAvailableProtocols = (await this.protocols.toArray()).filter(
      (protocol) => protocol.offlineAvailable,
    );

    return offlineAvailableProtocols.sort((a, b) => {
      return b.updatedAt.getTime() - a.updatedAt.getTime();
    });
  }

  async findProtocol(id: string): Promise<Protocol | null> {
    const protocol = await this.protocols.get(id);
    return protocol ?? null;
  }

  async saveProtocol(protocol: Protocol): Promise<void> {
    await this.protocols.put(protocol);
  }

  async updateProtocol(id: string, update: Partial<Protocol>): Promise<void> {
    await this.protocols.update(id, update);
  }

  async deleteProtocol(protocolId: string) {
    await this.protocols.delete(protocolId);
  }

  async saveImage(image: DatabaseImage): Promise<DatabaseImage> {
    await this.images.put(image);
    return image;
  }

  async findImage(id: string): Promise<DatabaseImage | null> {
    const img = await this.images.get(id);
    return img ?? null;
  }

  async fetchImages(): Promise<DatabaseImage[]> {
    return this.images.toArray();
  }

  async deleteImage(id: string) {
    await this.images.delete(id);
  }

  async addTask(task: Task): Promise<number> {
    return this.transaction('rw?', this.tasks, async () => {
      const deleted = await this.tasks
        .where('group')
        .equals(task.group)
        .and((it) => task.clears().includes(it.type))
        .delete();
      Logger.instance.log(
        `Deleted ${deleted} tasks in group ${task.group} before adding new task ${task.type}`,
      );
      return this.tasks.add(task);
    });
  }

  addTasks(tasks: Task[]) {
    Logger.instance.log(`Adding ${tasks.length} tasks to database`);
    return this.tasks.bulkAdd(tasks);
  }

  async deleteTask(id: number): Promise<void> {
    Logger.instance.log(`Deleting task with id ${id}`);
    await this.tasks.delete(id);
  }

  async deleteTasksByGroupAndTypes(group: string, types: Task['type'][]) {
    Logger.instance.log(`Deleting tasks in group ${group} with types ${types}`);
    await this.tasks
      .where('group')
      .equals(group)
      .and((it) => types.includes(it.type))
      .delete();
  }

  async getNextTaskForEachGroup(): Promise<Task[]> {
    const tasks = (await this.tasks.orderBy('id').toArray()).map(
      deserializeTask,
    );

    // get first task for each group
    const firstTaskPerGroup = _(tasks)
      .groupBy((it) => it.group)
      .mapValues((tasksInGroup) => tasksInGroup[0])
      .values()
      .compact()
      .value();

    return firstTaskPerGroup;
  }

  async updateTask(task: Task) {
    Logger.instance.log('update task', { task });
    await this.tasks.update(task.id!, task);
  }

  /**
   * Only saves message if a similar message is not already in database.
   */
  async saveMessageDistinct(message: Message) {
    const equalMessageCount = await this.messages
      .filter((it) => _.isEqual(_.omit(it, 'id'), _.omit(message, 'id')))
      .count();

    if (equalMessageCount === 0) {
      return this.messages.add(message);
    }
  }

  deleteMessage(id: number) {
    return this.messages.delete(id);
  }

  fetchMessages(): Promise<Message[]> {
    return this.messages.toArray();
  }

  async getTemplateUpdateDate(): Promise<Date | null> {
    const template = await this.templates.orderBy('updatedAt').last();
    return template?.updatedAt ?? null;
  }

  updateTemplates(templates: Template[], updatedAt: Date) {
    return this.transaction('rw?', this.templates, async () => {
      Logger.instance.log(
        `Updating ${templates.length} templates in db, setting updatedAt to ${updatedAt}`,
      );
      await this.templates.bulkPut(templates);
      await this.templates.toCollection().modify((it) => {
        Logger.instance.log('Updating updatedAt for template', it);
        it.updatedAt = updatedAt;
      });
    });
  }

  deleteTemplates(templateIds: number[]) {
    Logger.instance.log('Deleting templates with ids', templateIds);
    return this.templates.bulkDelete(templateIds);
  }

  async searchTemplates(
    search: string,
    type: TemplateType | null,
    limit = 10,
  ): Promise<Template[]> {
    const lowerCaseQuery = search.toLowerCase();

    const matchesQuery = (str: string) =>
      str.toLowerCase().includes(lowerCaseQuery);

    const titleMatchComparator = (a: Template, b: Template) => {
      const aTitleMatch = matchesQuery(a.title);
      const bTitleMatch = matchesQuery(b.title);
      return (bTitleMatch ? 1 : 0) - (aTitleMatch ? 1 : 0);
    };

    const templates = await this.templates
      .filter((template) => type === null || template.type === type)
      .filter(
        (template) =>
          matchesQuery(template.title) || matchesQuery(template.rawText),
      )
      .toArray();

    return templates.sort(titleMatchComparator).slice(0, limit);
  }
}
