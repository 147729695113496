import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolUserDto } from '@ibag/common';

import { createErrorMessage } from '@/common/utils/errors';
import { useEditable } from '@/pages/edit-protocol/common';

import { useFetchProtocolUsers } from './useFetchProtocolUsers';

interface Props {
  label: string;
  value: ProtocolUserDto | null;
  onChange: (value: ProtocolUserDto | null) => void;
}

export const WrittenBySelect = ({ label, value, onChange }: Props) => {
  const editable = useEditable();
  const { isFetching, users, error } = useFetchProtocolUsers();
  const { t } = useTranslation();

  return (
    <Autocomplete
      disabled={!editable}
      onChange={(_, value) => onChange(value)}
      disablePortal
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={users ?? []}
      getOptionLabel={(option) => option.name}
      loading={isFetching}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error ? createErrorMessage(t, error) : undefined}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={label}
        />
      )}
    />
  );
};
