import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';
import { MakeProtocolOfflineAvailableTask } from '@/services/database';

export function useMakeOfflineAvailable(
  protocolId: string,
  options: {
    showSuccessSnackbar?: boolean;
    showErrorSnackbar?: boolean;
    onSuccess?: () => void;
    onError?: (err: AppError) => void;
  },
) {
  const { taskQueue } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { isLoading, mutate, error } = useMutation<void, AppError, void>(
    async () => {
      await taskQueue.addTask(
        MakeProtocolOfflineAvailableTask.create(protocolId),
        { awaitResult: true },
      );
    },
    {
      networkMode: 'always', // also execute when offline
      onSuccess: () => {
        if (options.showSuccessSnackbar) {
          showSnackbar({
            variant: 'success',
            content: t('common.messages.protocol_offline_success'),
          });
        }
        options.onSuccess?.();
      },
      onError: (err) => {
        if (options.showErrorSnackbar) {
          showSnackbar({
            variant: 'error',
            content: createErrorMessage(t, err),
          });
        }
        options.onError?.(err);
      },
    },
  );

  return {
    isLoading,
    makeOfflineAvailable: mutate,
    error,
  };
}
