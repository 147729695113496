import { ProtocolType } from '@ibag/common';

import { ProtocolHolder } from './common/protocol-holder';
import { Protocol } from './protocol';
import { SigekoProtocol, SigekoProtocolHolder } from './sigeko';

export function createProtocolHolder(
  protocol: SigekoProtocol,
): SigekoProtocolHolder;

export function createProtocolHolder(
  protocol: Protocol,
): ProtocolHolder<Protocol>;

export function createProtocolHolder(protocol: Protocol) {
  switch (protocol.type) {
    case ProtocolType.SIGEKO:
      return new SigekoProtocolHolder(protocol);
    default:
      throw new Error(`Unknown protocol type: ${protocol.type}`);
  }
}
