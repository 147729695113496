import { ProtocolStatus } from '@ibag/common';

import { useLiveProtocol } from '@/common/hooks/use-live-protocol';

export function useIsOfflineAvailable(protocolId: string) {
  const protocol = useLiveProtocol(protocolId);

  return {
    canMakeOfflineAvailable:
      protocol === undefined
        ? false
        : !protocol?.offlineAvailable &&
          protocol?.status !== ProtocolStatus.ARCHIVED,
    isOfflineAvailable: protocol === null ? false : protocol?.offlineAvailable,
    hasUnsyncedChanges:
      protocol === null ? false : !(protocol?.localVersion === null),
  };
}
