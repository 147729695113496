import { Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from '../../styles';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(-0.5, 0, 1),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

interface Props {
  assignees: string[] | null;
}

export const Assignees = ({ assignees }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Label>
        {t('page.edit_protocol.actions.add_assignee.dialog.label_assignees') +
          ': '}
      </Label>
      <Typography sx={{ fontWeight: 'bold' }}>
        {assignees ? assignees.join(', ') : '---'}
      </Typography>
    </Container>
  );
};
