import { styled } from '@mui/material';
import React from 'react';

const Image = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.rounded,
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  pageBreakInside: 'avoid',
  marginBottom: theme.spacing(3),
}));

interface Props {
  src: string;
  width: string;
}

export const SingleImage = ({ src, width }: Props) => {
  return (
    <ImageContainer style={{ width: width }}>
      <Image key={src} src={src} width={'100%'} />
    </ImageContainer>
  );
};
