import { listProtocolService } from '@/app/service-instances/list-protocol-service';
import { syncProtocolsService } from '@/app/service-instances/sync-protocols-service-instance';
import { templateService } from '@/app/service-instances/template-service';
import { Services } from '@/common/providers/service-provider';

import { authService } from './auth-service-instance';
import { authorizedHttpClient } from './authenticated-http-client-instance';
import { database } from './database-instance';
import { messageService } from './message-service-instance';
import { printProtocolService } from './print-protocol-service-instance';
import { protocolImageService } from './protocol-image-service-instance';
import { protocolService } from './protocol-service-instance';
import { serviceWorkerRegistrationService } from './service-worker-registration-service-instance';
import { taskQueue } from './task-queue-instance';
import { updateTemplatesService } from './update-templates-service-instance';
import { userService } from './user-service-instance';

export const services: Services = {
  authService,
  authorizedHttpClient,
  userService,
  database,
  protocolService,
  protocolImageService,
  printProtocolService,
  taskQueue,
  listProtocolService,
  templateService,
  syncProtocolsService,
  messageService,
  updateTemplatesService,
  serviceWorkerRegistrationService,
};
