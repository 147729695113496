import React, { CSSProperties } from 'react';

import { HeaderExport } from '../../types';
import { HeaderItem } from './HeaderItem';

interface Props {
  header: HeaderExport;
  className?: string | undefined;
  style?: CSSProperties;
}

export const ProtocolHeaderPrintView = ({
  header,
  className,
  style,
  ...props
}: Props) => {
  const items = header.items.map((headerExportItem) => (
    <HeaderItem
      key={headerExportItem.label}
      label={headerExportItem.label}
      value={headerExportItem.value}
    />
  ));

  return (
    <div style={style} className={className} {...props}>
      {items}
    </div>
  );
};
