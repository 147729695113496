import { AutoLinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { Klass, LexicalNode } from 'lexical';

import { ImageNode } from '../plugins';

export const nodes: ReadonlyArray<Klass<LexicalNode>> = [
  ImageNode,
  HeadingNode,
  AutoLinkNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  ListNode,
  ListItemNode,
];
