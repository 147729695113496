import { CloudDownload, FileDownloadOff } from '@mui/icons-material';
import { ListItemText } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useClearOfflineAvailable,
  useIsOfflineAvailable,
  useMakeOfflineAvailable,
} from '@/common/hooks/offline-available';
import { DropDownMenuItem, LoadingIcon } from '@/common/ui/dropdown';
import { DropDownMenuItemProps } from '@/common/ui/dropdown/types';

interface Props {
  protocolId: string;
  close?: () => void;
}

export const MakeOfflineAvailableMenuItem = ({ protocolId, close }: Props) => {
  const { t } = useTranslation();

  const { isOfflineAvailable, hasUnsyncedChanges, canMakeOfflineAvailable } =
    useIsOfflineAvailable(protocolId);
  const { makeOfflineAvailable, isLoading } = useMakeOfflineAvailable(
    protocolId,
    {
      showErrorSnackbar: true,
      showSuccessSnackbar: true,
      onSuccess: close,
      onError: close,
    },
  );

  const { clearOfflineAvailable, isLoading: isClearing } =
    useClearOfflineAvailable(protocolId, {
      showErrorSnackbar: true,
      onSuccess: close,
      onError: close,
    });

  if (isOfflineAvailable) {
    const props: DropDownMenuItemProps = hasUnsyncedChanges
      ? {
          confirmable: {
            title: t('common.actions.confirm_clear.title'),
            text: t('common.actions.confirm_clear.text'),
            onConfirm: () => clearOfflineAvailable(),
          },
        }
      : {
          onClick: () => clearOfflineAvailable(),
        };

    return (
      <DropDownMenuItem {...props}>
        <LoadingIcon isLoading={isClearing}>
          <FileDownloadOff />
        </LoadingIcon>
        <ListItemText>
          {t('common.actions.clear_offline_available')}
        </ListItemText>
      </DropDownMenuItem>
    );
  } else {
    return (
      <DropDownMenuItem
        disabled={!canMakeOfflineAvailable}
        onClick={() => {
          makeOfflineAvailable();
        }}
      >
        <LoadingIcon isLoading={isLoading}>
          <CloudDownload />
        </LoadingIcon>
        <ListItemText>
          {t('common.actions.make_offline_available')}
        </ListItemText>
      </DropDownMenuItem>
    );
  }
};
