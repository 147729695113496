import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { BaseButton } from '@/common/ui/buttons';
import { HOME } from '@/routing/routes';

interface Props {
  tableOfContent: React.ReactNode;
  actions: React.ReactNode;
}

const StyledBaseButton = styled(BaseButton)(({ theme }) => ({
  padding: theme.spacing(theme.spacing(1), theme.spacing(2)),
  paddingLeft: theme.spacing(0.5),
}));

const TableOfContentContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  height: '100%',
  overflow: 'auto',
}));

const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
});

const ActionsContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  paddingTop: theme.spacing(3),
}));

export const SideMenu = ({ tableOfContent, actions }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <StyledNavLink to={HOME}>
          <StyledBaseButton startIcon={<ArrowBackIosIcon />}>
            {t('page.edit_protocol.side_menu.go_back_button')}
          </StyledBaseButton>
        </StyledNavLink>
      </div>
      <TableOfContentContainer>{tableOfContent}</TableOfContentContainer>
      <ActionsContainer>{actions}</ActionsContainer>
    </>
  );
};
