import { styled } from '@mui/material';
import React from 'react';

import { Image, ImageSize } from '@/common/types/protocol';

import { SingleImage } from './SingleImage';

const IMAGE_GAP_IN_PIXEL = 24;

export const ImageRow = styled('div')(() => ({
  display: 'flex',
  pageBreakInside: 'avoid',
  gap: IMAGE_GAP_IN_PIXEL,
}));

interface Props {
  images: Image[];
}

/**
 * We have 3 different Image Sizes: small 25%, medium 33% and large 50%
 * We create a div for every row with images, because page-break doesn't work with flex-wrap.
 */
export const ImagesPrintView = ({ images }: Props) => {
  let rowSize = 0;
  let imageRow: React.ReactNode[] = [];
  const imageRows: React.ReactNode[] = [];

  images.forEach((image, index) => {
    let imageWidth: string;
    let sizeAsNumber: number;
    switch (image.size) {
      case ImageSize.SMALL:
        imageWidth = `calc(25% - ${IMAGE_GAP_IN_PIXEL * 0.75}px)`;
        sizeAsNumber = 0.25;
        break;
      case ImageSize.MEDIUM:
        imageWidth = `calc(${100 / 3}% - ${(IMAGE_GAP_IN_PIXEL * 2) / 3}px)`;
        sizeAsNumber = 1 / 3;
        break;
      case ImageSize.LARGE:
        imageWidth = `calc(50% - ${IMAGE_GAP_IN_PIXEL * 0.5}px)`;
        sizeAsNumber = 0.5;
        break;
    }
    rowSize += sizeAsNumber;

    if (rowSize <= 1) {
      // row is not full yet
      imageRow.push(
        <SingleImage key={image.id} src={image.url} width={imageWidth} />,
      );
    } else {
      // row is full, push it and create a new one
      imageRows.push(<ImageRow key={imageRows.length}>{imageRow}</ImageRow>);
      imageRow = [];
      rowSize = sizeAsNumber;
      imageRow.push(
        <SingleImage key={image.id} src={image.url} width={imageWidth} />,
      );
    }

    if (images.length - 1 === index) {
      // last image, push the row in any case
      imageRows.push(<ImageRow key={imageRows.length}>{imageRow}</ImageRow>);
    }
  });

  return <>{imageRows}</>;
};
