import { CreateUserPayload, ListUserDto } from '@ibag/common';

import { ListUser } from '@/common/types/user';

import { HttpClient } from '../http-client';

export class UserService {
  constructor(private readonly authorizedHttpClient: HttpClient) {}

  async addUser(userRequest: CreateUserPayload) {
    await this.authorizedHttpClient.jsonRequest(
      'POST',
      '/api/users',
      undefined,
      userRequest,
    );
  }

  async editUser(userRequest: CreateUserPayload, id: string) {
    await this.authorizedHttpClient.jsonRequest(
      'PUT',
      `/api/users/${id}`,
      undefined,
      userRequest,
    );
  }

  async inviteUser(id: string) {
    await this.authorizedHttpClient.jsonRequest(
      'POST',
      `/api/users/${id}/invitation`,
      undefined,
    );
  }

  async changeUserStatus(id: string, active: boolean) {
    await this.authorizedHttpClient.jsonRequest(
      'PUT',
      `/api/users/${id}/status`,
      undefined,
      { active },
    );
  }

  async fetchUsers(): Promise<ListUser[]> {
    return this.authorizedHttpClient.jsonRequest<ListUserDto[]>(
      'GET',
      `/api/users`,
    );
  }
}
