import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '@/common/hooks/use-user';
import { wrapMenuLayout } from '@/common/layout';
import { SpaceBetweenRowContainer } from '@/common/ui/containers';

import { CreateProtocolButton } from './CreateProtocolButton';
import { OfflineAvailableProtocols } from './offline-available-protocols';
import { ProtocolTable } from './protocol-table';

const Header = SpaceBetweenRowContainer;
const HeaderLeft = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const HomePage = wrapMenuLayout(() => {
  const { t } = useTranslation();
  const user = useUser();
  const theme = useTheme();

  return (
    <>
      <Header>
        <HeaderLeft>
          <Typography color={theme.palette.common.white} variant={'h1'}>
            {t('page.home.title')}
          </Typography>
          {user && (
            <Typography color={theme.palette.common.white}>
              {t('page.home.greeting', {
                displayName: user.displayName,
              })}
            </Typography>
          )}
        </HeaderLeft>
        <CreateProtocolButton />
      </Header>
      <OfflineAvailableProtocols />

      <ProtocolTable />
    </>
  );
});
