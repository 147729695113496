import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorType } from '@ibag/common';

import { useFetchUsers } from '@/common/hooks/use-fetch-users';
import { useUser } from '@/common/hooks/use-user';
import { AppError } from '@/common/types/errors';
import { ListUser } from '@/common/types/user';
import { PrimaryButton } from '@/common/ui/buttons';
import { DropDownMenuItem } from '@/common/ui/dropdown';
import { BaseSelect } from '@/common/ui/select';

interface Props {
  onFilterChange: (selection: string | null) => void;
  selectedFilter: string | null;
  label: string;
}

export const UserFilter = ({
  onFilterChange,
  selectedFilter,
  label,
  ...props
}: Props & React.ComponentProps<typeof PrimaryButton>) => {
  const { t } = useTranslation();
  const { fetchedUsers } = useFetchUsers();
  const user = useUser();

  const userFilterOptions =
    fetchedUsers && user?.id
      ? sortByNameAndMeFirst(fetchedUsers, user?.id).map((listUser) => {
          return (
            <DropDownMenuItem
              key={listUser.id}
              onClick={() => {
                onFilterChange(listUser.id);
              }}
            >
              {user.id === listUser?.id
                ? t('page.home.protocol_table.filter.user.label_myself')
                : convertToFullName(listUser)}
            </DropDownMenuItem>
          );
        })
      : [];

  const additionalFilterOptions = [
    <DropDownMenuItem
      key={'no_filter'}
      onClick={() => {
        onFilterChange(null);
      }}
    >
      {t('page.home.protocol_table.filter.user.label_no_filter')}
    </DropDownMenuItem>,
  ];

  const convertIdToName = (id: string) => {
    const user = fetchedUsers?.find((user) => user.id === id);
    if (user) {
      return user;
    }
    throw new AppError(ErrorType.NOT_FOUND, 'no user found');
  };

  return (
    <BaseSelect
      name={
        (selectedFilter &&
          (selectedFilter === user?.id
            ? t('page.home.protocol_table.filter.user.label_myself')
            : convertToFullName(convertIdToName(selectedFilter)))) ??
        label
      }
      actions={[...additionalFilterOptions, ...userFilterOptions]}
      {...props}
    />
  );
};

const convertToFullName = (user: ListUser): string => {
  return `${user.firstName} ${user.lastName}`;
};

function sortByNameAndMeFirst(arr: ListUser[], id: string): ListUser[] {
  // Problem: .sort error while reloading - Solution: make a copy of the array
  const copy = [...arr];
  return copy.sort(function (a: ListUser, b: ListUser) {
    if (a.id === id) {
      return -1; // a kommt vor b
    }
    if (b.id === id) {
      return 1; // b kommt vor a
    }
    return a.firstName.localeCompare(b.firstName, 'de', {
      sensitivity: 'base',
    });
  });
}
