import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';
import { HttpClient } from '@/services/http-client';

import { PrintPayload } from './print-payload';

export class PrintProtocolService {
  constructor(private readonly authorizedHttpClient: HttpClient) {}

  /**
   * Export to PDF
   * We send the html to a firebase cloud function and receive a pdf
   */
  async exportPDF(printPayload: PrintPayload) {
    const authorization = await this.authorizedHttpClient.authorizeRequest();
    try {
      const response = await fetch(import.meta.env.VITE_CLOUD_FUNCTION_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authorization,
        },
        body: JSON.stringify(printPayload),
      });

      if (response.ok) {
        return await response.blob();
      } else {
        // noinspection ExceptionCaughtLocallyJS
        throw new AppError(
          ErrorType.EXPORT_FAILED,
          (await response.json()).message,
        );
      }
    } catch (error) {
      if (error instanceof AppError) {
        console.error(error.message);
        throw error;
      } else {
        throw new AppError(ErrorType.NETWORK);
      }
    }
  }
}
