import { Theme, createTheme } from '@mui/material/styles';

import { baseTheme } from '@/common/theme/ThemeProvider';

export const printTheme: Theme = createTheme({
  ...baseTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.editor-heading-h1': {
          fontSize: 15,
        },
        '.editor-heading-h2': {
          fontSize: 13,
        },
        '.editor-heading-h3': {
          fontSize: 12,
        },
        '.editor-paragraph, .editor-listItem': {
          fontSize: 12,
        },
        '.text-editor': {
          // might prevent words split in half when printing in some cases
          overflow: 'auto',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: 10,
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
    },
    h1: {
      fontSize: 24,
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
      fontSize: 16,
    },
    h5: {
      fontWeight: 700,
      fontSize: 12,
    },
    h6: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
  },
});
