import { FormatListBulleted, FormatListNumbered } from '@mui/icons-material';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { styled } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlockType } from '../block-type';

const StyledText = styled('div')(({ theme }) => ({
  fontWeight: 900,
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(1),
}));

const StyledIcon = styled(SvgIcon)(({ theme }) => ({
  width: 25,
  marginRight: theme.spacing(1),
}));

interface Props {
  blockType: BlockType;
  color: string;
}
const createBlockTitle = (
  iconElement: React.ReactNode,
  title: string,
  color: string,
): JSX.Element => {
  return (
    <>
      {typeof iconElement === 'string' ? (
        <StyledText sx={{ color }}>{iconElement}</StyledText>
      ) : (
        <StyledIcon sx={{ color }}>{iconElement}</StyledIcon>
      )}
      <div style={{ color, paddingRight: 16 }}>{title}</div>
    </>
  );
};

export const BlockName = ({ blockType, color }: Props): JSX.Element => {
  const { t } = useTranslation();

  switch (blockType) {
    case BlockType.paragraph:
      return createBlockTitle(
        <ViewHeadlineIcon />,
        t('editor.blockType.normal'),
        color,
      );

    case BlockType.h1:
      return createBlockTitle('H1', t('editor.blockType.h1'), color);

    case BlockType.h2:
      return createBlockTitle('H2', t('editor.blockType.h2'), color);

    case BlockType.h3:
      return createBlockTitle('H3', t('editor.blockType.h3'), color);

    case BlockType.unorderedList:
      return createBlockTitle(
        <FormatListBulleted />,
        t('editor.blockType.unordered_list'),
        color,
      );

    case BlockType.orderedList:
      return createBlockTitle(
        <FormatListNumbered />,
        t('editor.blockType.ordered_list'),
        color,
      );

    default:
      return createBlockTitle(
        '?',
        'Item is not registered in the types',
        color,
      );
  }
};
