import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

import { OverflowActionMenu } from '@/common/ui/buttons/overflow-action-menu';
import { DropDownMenuItemElement } from '@/common/ui/dropdown';

import {
  Detail,
  DetailContainer,
  DetailStatusContainer,
  Header,
  RoundedCardContainer,
  SmallText,
  StyledSvgContainer,
  Title,
  TitleContainer,
  TransparentSmallText,
} from './styles';
import { BaseCardDetail, BaseCardDetailStatus } from './types';

interface Props {
  title: string;
  subTitle: string;
  onClick?: () => void;
  icon: React.ReactElement;
  iconColor: string;
  details: BaseCardDetail[];
  detailStatuses: (BaseCardDetailStatus | null)[];
  actions: DropDownMenuItemElement[];
}

export const BaseCard = (props: Props) => {
  // if less details than 3, add an empty detail for better style
  const details = Array(3).fill({ value: '', description: '' });
  props.details.forEach((detail, index) => {
    details[index] = detail;
  });

  return (
    <RoundedCardContainer onClick={props.onClick}>
      <Header>
        <StyledSvgContainer color={props.iconColor}>
          {props.icon}
        </StyledSvgContainer>
        <TitleContainer>
          <Title title={props.title}>{props.title}</Title>
          <TransparentSmallText>{props.subTitle}</TransparentSmallText>
        </TitleContainer>

        <OverflowActionMenu overflowActions={props.actions} />
      </Header>
      <DetailContainer>
        {details.map((detail: BaseCardDetail, index) => {
          return (
            <Detail key={index}>
              <TransparentSmallText>{detail.description}</TransparentSmallText>
              <SmallText>{detail.value}</SmallText>
            </Detail>
          );
        })}
      </DetailContainer>
      <DetailStatusContainer>
        {props.detailStatuses.map((statusIcon, index) => {
          if (statusIcon !== null) {
            return (
              <Tooltip
                title={statusIcon.description}
                enterTouchDelay={0}
                onClick={(e) => e.stopPropagation()}
                key={index}
              >
                <IconButton size={'small'}>{statusIcon.icon}</IconButton>
              </Tooltip>
            );
          } else {
            return null;
          }
        })}
      </DetailStatusContainer>
    </RoundedCardContainer>
  );
};
