import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';
import { Database, NetworkMode, Task } from '@/services/database';
import { Logger } from '@/services/logger';

type Callbacks = { onSuccess: () => void; onError: (err: AppError) => void };

/**
 * This class provides a service to execute tasks in the same order as they were added.
 * The tasks will be executed by the service-worker using {@link TaskHandler} service.
 */
export class TaskQueue {
  // map of task that are waiting for a response from the service worker
  private readonly pendingTasks: Map<number, Callbacks>;

  constructor(private readonly database: Database) {
    this.pendingTasks = new Map();

    navigator.serviceWorker.addEventListener('message', (evt) => {
      if (evt.data.type === 'task-completed') {
        const { taskId, error } = evt.data;

        if (error) {
          Logger.instance.logWarning(`Task ${taskId} completed with error`, {
            error,
          });
        } else {
          Logger.instance.log(`Task ${taskId} completed successfully`);
        }

        const callbacks = this.pendingTasks.get(taskId);
        if (!callbacks) return;

        if (error) {
          const appError = new AppError(error.errorType, error.message); // deserialize error
          callbacks.onError(appError);
        } else {
          callbacks.onSuccess();
        }

        this.pendingTasks.delete(taskId);
      }
    });
  }

  /**
   * Adds a task to the queue and triggers the service worker to execute it.
   * @param task {@link Task} to be executed
   * @param options
   * @param options.awaitResult If true, the promise will resolve once the task has been executed. If false (default), the promise will resolve once the task is queued.
   */
  async addTask(
    task: Task,
    options?: {
      awaitResult?: boolean;
    },
  ): Promise<void> {
    Logger.instance.log(`Adding task ${task.type} to queue`, { task });

    const { awaitResult = false } = options ?? {};

    if (task.networkMode === NetworkMode.ONLINE && !navigator.onLine) {
      Logger.instance.log(
        `Task ${task.id} has to be executed online, but device is offline. Throw error.`,
      );
      throw new AppError(ErrorType.NETWORK, 'Network is not available');
    }

    const id = await this.database.addTask(task);

    const promise = awaitResult
      ? new Promise<void>((resolve, reject) => {
          // store resolve and reject functions to be called when the service worker has executed the task
          this.pendingTasks.set(id, {
            onSuccess: () => resolve(),
            onError: (err) => reject(err),
          });
        })
      : null;

    await this.triggerServiceWorker();

    if (promise) {
      return promise;
    }
  }

  async clearTasks(group: string, types: Task['type'][]) {
    Logger.instance.log(`Clearing tasks ${types} for group ${group}`);
    await this.database.deleteTasksByGroupAndTypes(group, types);
  }

  /**
   * Synchronous version of addTask that delegates the insertion of the task
   * to the service worker. Note that the synchronicity of this method is only
   * guaranteed if the service worker is already active.
   */
  async addTaskViaServiceWorker(task: Task) {
    Logger.instance.log(`Adding task to queue via service worker`, { task });
    const registration = await this.getServiceWorker();
    registration.active?.postMessage({ type: 'add-task', task });
  }

  /**
   * Notifies the service worker that a task has been added to the queue.
   * Depending on the network mode, this will be done via background-sync or
   * by sending a message to the service worker.
   */
  private async triggerServiceWorker(): Promise<void> {
    const registration = await this.getServiceWorker();

    Logger.instance.log('Register background sync for task-queue...');
    try {
      await registration.sync.register('task-queue');
    } catch (e) {
      Logger.instance.logError(
        'Failed to register background sync',
        e as Error,
      );
      throw e;
    }
    Logger.instance.log('Registered background sync for task-queue');
  }

  private getServiceWorker() {
    if ('serviceWorker' in navigator) {
      return (navigator.serviceWorker as any).ready;
    } else {
      throw new Error('Service Worker not supported');
    }
  }
}
