import { styled } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';

import { BaseMessageCard } from './BaseMessageCard';
import { ProtocolClonedMessageCard } from './ProtocolClonedMessageCard';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  width: '33%',
  maxWidth: 350,
}));

export const Messages = () => {
  const { t } = useTranslation();

  const { messageService } = useServices();
  const messages = useLiveQuery(() => messageService.fetchMessages(), []) ?? [];

  return (
    <Root>
      {messages.map((message) => {
        switch (message.type) {
          case 'PROTOCOL_DUPLICATED':
            return (
              <ProtocolClonedMessageCard key={message.id} message={message} />
            );
          case 'PROTOCOL_LOCKED':
            return (
              <BaseMessageCard
                key={message.id}
                id={message.id!}
                title={t('common.message_cards.protocol_locked.title')}
              >
                {t('common.message_cards.protocol_locked.text', {
                  title: message.protocolTitle,
                })}
              </BaseMessageCard>
            );
          case 'PROTOCOL_DELETED':
            return (
              <BaseMessageCard
                key={message.id}
                id={message.id!}
                title={t('common.message_cards.protocol_deleted.title')}
              >
                {t('common.message_cards.protocol_deleted.text', {
                  title: message.protocolTitle,
                })}
              </BaseMessageCard>
            );
          default:
            return null;
        }
      })}
    </Root>
  );
};
