import { MenuItem } from '@mui/material';
import { omit } from 'lodash';
import React from 'react';

import { Confirm } from '@/common/ui/confirm-dialog';
import { DropDownMenuItemProps } from '@/common/ui/dropdown/types';

/**
 * @param confirmable ConfirmProps for configuring the Confirm Dialog. Do not pass onClick and confirmable together, onClick will not be called!
 */
export const DropDownMenuItem = ({
  confirmable,
  ...props
}: DropDownMenuItemProps) => {
  const menuItemProps = omit(props, 'close');

  return !confirmable ? (
    <MenuItem {...menuItemProps} />
  ) : (
    <Confirm {...confirmable}>
      {(onPress) => (
        <MenuItem
          {...menuItemProps}
          onClick={() => {
            onPress();
          }}
        />
      )}
    </Confirm>
  );
};
