import { Menu, MenuItem, useTheme } from '@mui/material';
import React from 'react';

import { BlockType } from './block-type';
import { BlockName } from './common';

interface Props {
  onMenuItemClicked: (type: BlockType) => void;
  onClose: () => void;
  anchorEl: null | HTMLElement;
}

export const BlockTypesDropDown = ({
  onClose,
  onMenuItemClicked,
  anchorEl,
}: Props) => {
  const theme = useTheme();

  return (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
      {Object.values(BlockType).map((type: BlockType) => {
        return (
          <MenuItem
            key={type}
            onClick={() => {
              onMenuItemClicked(type);
            }}
          >
            <BlockName blockType={type} color={theme.palette.grey[700]} />
          </MenuItem>
        );
      })}
    </Menu>
  );
};
