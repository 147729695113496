import { TFunction } from 'i18next';
import _ from 'lodash';

import { KeyOfSigekoProtocolHeader } from '@/common/types/protocol';
import { convertDateToDateString } from '@/common/utils/format-date';
import { HeaderExport, HeaderExportItem } from '@/pages/export/types';

export const convertSigekoHeaderToExportHeader = (
  t: TFunction,
  header: Record<KeyOfSigekoProtocolHeader, any>,
  exportFields: KeyOfSigekoProtocolHeader[],
): HeaderExport => {
  const headerItems = exportFields
    .filter((field) => !!header[field])
    .map((field): HeaderExportItem | null => {
      const value = header[field];
      return {
        label:
          t(
            `page.edit_protocol.${header.variant}.header.inputs.${_.snakeCase(
              field,
            )}`,
          ) + `: `,
        value:
          value instanceof Date ? convertDateToDateString(value) : value ?? '',
      };
    });

  return {
    items: _.compact(headerItems),
  };
};
