export var ErrorType;
(function (ErrorType) {
    ErrorType["DEVELOPER"] = "DEVELOPER";
    ErrorType["UNAUTHENTICATED"] = "UNAUTHENTICATED";
    ErrorType["FORBIDDEN"] = "FORBIDDEN";
    ErrorType["NOT_FOUND"] = "NOT_FOUND";
    ErrorType["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    ErrorType["INVALID_TOKEN"] = "INVALID_TOKEN";
    ErrorType["INVALID_REQUEST"] = "INVALID_REQUEST";
    ErrorType["CREATE_USER_FAILED"] = "CREATE_USER_FAILED";
    ErrorType["UPDATE_USER_FAILED"] = "UPDATE_USER_FAILED";
    ErrorType["FAILED_TO_DELETE"] = "FAILED_TO_DELETE";
    ErrorType["USER_EXISTS"] = "USER_EXISTS";
    ErrorType["INVITATION_CODE_ALREADY_REDEEMED"] = "INVITATION_CODE_ALREADY_REDEEMED";
    ErrorType["INVITATION_CODE_NOT_FOUND"] = "INVITATION_CODE_NOT_FOUND";
    ErrorType["PROTOCOL_REVISION_CONFLICT"] = "PROTOCOL_REVISION_CONFLICT";
    ErrorType["PROTOCOL_LOCKED"] = "PROTOCOL_LOCKED";
    ErrorType["PROTOCOL_DELETED"] = "PROTOCOL_DELETED";
    ErrorType["FILE_UPLOAD_FAILED"] = "FILE_UPLOAD_FAILED";
    ErrorType["INVALID_DOCX"] = "INVALID_DOCX";
    ErrorType["UNKNOWN"] = "UNKNOWN";
    ErrorType["NETWORK"] = "NETWORK";
    ErrorType["DUPLICATE_EMAIL"] = "DUPLICATE_EMAIL";
    ErrorType["INVALID_CREDENTIALS"] = "INVALID_CREDENTIALS";
    ErrorType["FILE_IMPORT_FAILED"] = "FILE_IMPORT_FAILED";
    ErrorType["INVALID_FILE_TYPE"] = "INVALID_FILE_TYPE";
    ErrorType["INVALID_VCARD"] = "INVALID_VCARD";
    ErrorType["FAILED_IMAGE_CACHING"] = "FAILED_IMAGE_CACHING";
    ErrorType["PROTOCOL_CLONED"] = "PROTOCOL_CLONED";
    ErrorType["SERVICE_WORKER_NOT_FOUND"] = "SERVICE_WORKER_NOT_FOUND";
    ErrorType["EXPORT_FAILED"] = "EXPORT_FAILED";
})(ErrorType || (ErrorType = {}));
