import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { styled, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserRole } from '@ibag/common';

import { useFetchUsers } from '@/common/hooks/use-fetch-users';
import { useUser } from '@/common/hooks/use-user';
import { wrapMenuLayout } from '@/common/layout';
import { StyledDataGrid, WhiteTypography } from '@/common/styles';
import { User } from '@/common/types/user';
import { TableRoundedContainer } from '@/common/ui/containers';
import { ErrorMessage } from '@/common/ui/error-message';
import { EditUserButton } from '@/pages/manage-user/edit-user';

import { CircleIcon } from './CircleIcon';
import { AddUserButton } from './add-user';
import { SendInvitationButton } from './send-invitation';

const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(5),
}));

export const ManageUserPage = wrapMenuLayout(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useUser();
  const { fetchedUsers, isLoading, error } = useFetchUsers();
  const columns: GridColDef<User>[] = [
    {
      field: 'firstName',
      headerName: t('page.add_user.table.first_name'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'lastName',
      headerName: t('page.add_user.table.last_name'),
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'role',
      headerName: t('page.add_user.table.role'),
      flex: 1,
      renderCell: (params) => t(`common.user_role.${params.value}`),
    },
    {
      field: 'acceptedInvitation',
      headerName: t('page.add_user.table.accepted_invitation'),
      flex: 1,
      headerAlign: 'center',
      minWidth: 100,
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            {params.value ? (
              <CheckIcon sx={{ color: 'success.main' }} />
            ) : (
              <ClearIcon sx={{ color: 'error.main' }} />
            )}
            {params.value === false && (
              <SendInvitationButton id={params.row.id} />
            )}
          </>
        );
      },
    },
    {
      field: 'active',
      headerName: t('page.add_user.table.status'),
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        switch (params.value) {
          case false:
            return <CircleIcon color={theme.palette.warning.main} />;
          case true:
            return <CircleIcon color={theme.palette.success.main} />;
        }
      },
    },
    {
      field: 'Actions',
      sortable: false,
      headerAlign: 'center',
      headerName: t('page.add_user.table.actions'),
      flex: 1,
      align: 'right',
      renderCell: (params) => {
        return (
          <>
            <EditUserButton currentUser={params.row} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <WhiteTypography variant={'h1'}>
        {t('page.add_user.title')}
      </WhiteTypography>

      <ActionsContainer>
        {user?.role === UserRole.ADMIN && <AddUserButton />}
      </ActionsContainer>
      <TableRoundedContainer>
        <StyledDataGrid
          sortingMode={'client'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'active', sort: 'desc' }],
            },
          }}
          disableVirtualization
          columnVisibilityModel={{
            Actions: user?.role === UserRole.ADMIN,
          }}
          disableColumnMenu
          hideFooter
          hideFooterPagination
          autoHeight
          disableSelectionOnClick
          rows={fetchedUsers ?? []}
          loading={isLoading}
          rowsPerPageOptions={[]}
          columns={columns}
        />
      </TableRoundedContainer>
      <ErrorMessage error={error} />
    </>
  );
});
