import { Inventory } from '@mui/icons-material';
import ReportIcon from '@mui/icons-material/Report';
import { Theme } from '@mui/material/styles';
import React from 'react';

import { ProtocolStatus } from '@ibag/common';

import {
  CompletedSvg,
  InEditingSvg,
  NewSvg,
  ReadyForEditingsSvg,
} from '@/assets/svg/protocol-status';

import { ProtocolCardIcon } from '../types';

export const createCardIcon = (
  status: ProtocolStatus,
  theme: Theme,
): ProtocolCardIcon => {
  switch (status) {
    case ProtocolStatus.NEW:
      return { icon: <NewSvg />, color: theme.palette.primary.dark };
    case ProtocolStatus.IN_EDITING:
      return { icon: <InEditingSvg />, color: theme.palette.primary.main };
    case ProtocolStatus.COMPLETED:
      return { icon: <CompletedSvg />, color: theme.palette.success.main };
    case ProtocolStatus.READY_FOR_EDITING:
      return {
        icon: <ReadyForEditingsSvg />,
        color: theme.palette.warning.main,
      };
    case ProtocolStatus.ARCHIVED:
      return {
        icon: <Inventory sx={{ margin: 'auto', fill: 'inherit' }} />,
        color: theme.palette.primary.main,
      };
    default:
      return { icon: <ReportIcon />, color: theme.palette.error.main };
  }
};
