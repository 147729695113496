export interface DefectNumber {
  protocolNumber: number; // number of the inspection, where this defect was added
  itemNumber: number; // consecutive number in the inspection
}

function formatNumber(number: number): string {
  return number.toString().padStart(2, '0');
}

export function formatDefectNumber(defectNumber: DefectNumber): string {
  return `${formatNumber(defectNumber.protocolNumber)}.${formatNumber(
    defectNumber.itemNumber,
  )}`;
}
