import { Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: grey[100],
  padding: theme.spacing(1),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(1),
  flexWrap: 'nowrap',
}));

interface Props {
  children: React.ReactNode;
  right?: React.ReactNode;
}

export const Headline = ({ children, right }: Props) => {
  return (
    <Container>
      <Typography variant={'h2'}>{children}</Typography>
      {right && <div>{right}</div>}
    </Container>
  );
};
