import { useQuery } from '@tanstack/react-query';

import { ProtocolStatus, SortOrder } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { ProtocolList } from '@/common/types/protocol';

export const useFetchPageOfProtocols = (
  page: number,
  pageSize: number,
  sortBy: 'id' | 'title' | 'status' | 'updatedAt' | 'revision',
  sortOrder: SortOrder,
  status?: ProtocolStatus,
  createdBy?: string,
  q?: string,
) => {
  const { listProtocolService } = useServices();

  const {
    data: fetchedProtocols,
    isLoading,
    error: error,
  } = useQuery<ProtocolList, AppError>(
    ['protocol-list', page, pageSize, status, sortBy, sortOrder, createdBy, q],
    () => {
      return listProtocolService.fetchProtocolListFromServer(
        page,
        pageSize,
        sortBy,
        sortOrder,
        status,
        createdBy,
        q,
      );
    },
    {
      keepPreviousData: true,
      networkMode: 'always',
    },
  );
  return { fetchedProtocols, isLoading, error } as const;
};
