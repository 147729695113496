import { styled } from '@mui/material';
import React, { useId } from 'react';

import { useServices } from '@/common/providers/service-provider';
import {
  HtmlContent,
  Image,
  LexicalContent,
  RichTextSelector,
} from '@/common/types/protocol';
import { OverflowActionMenu } from '@/common/ui/buttons';
import { DropDownMenuItemElement } from '@/common/ui/dropdown';
import { TextEditor } from '@/common/ui/text-editor';
import { createNanoId } from '@/common/utils/nanoid';
import { UploadLocalImagesTask } from '@/services/database';

import { useProtocolState } from '../ProtocolProvider';
import { BaseStore } from '../store';
import { ImageRow } from './images';

const StyledOverflowActionMenuSmall = styled(OverflowActionMenu)(
  ({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  }),
);

interface Props<S> {
  anchorId?: string;
  stateSelector: RichTextSelector<S>;
  images: Image[];
  initialContent: HtmlContent | LexicalContent | null;
  overflowActions?: DropDownMenuItemElement[];
  editable: boolean;
}

export const RichTextField = <S extends object>({
  anchorId,
  overflowActions,
  stateSelector,
  initialContent,
  images,
  editable,
  ...props
}: Props<S>) => {
  const id = useId();
  const { database, taskQueue } = useServices();

  const [
    activeEditorId,
    setActiveEditorId,
    updateText,
    addImages,
    changeImageSize,
    sortImages,
    deleteImage,
  ] = useProtocolState((store: BaseStore<S>) => [
    store.activeEditorId,
    store.setActiveEditorId,
    store.updateText,
    store.addImages,
    store.changeImageSize,
    store.sortImages,
    store.deleteImage,
  ]);

  const saveImages = async (images: FileList) => {
    const dbImages = await Promise.all(
      Array.from(images).map(async (image) => {
        const id = createNanoId();
        return await database.saveImage({
          id,
          image: image,
        });
      }),
    );
    await taskQueue.addTask(UploadLocalImagesTask.create());
    addImages(stateSelector, dbImages);
  };

  const readonly = activeEditorId !== id;
  const showActionMenu = !!overflowActions && readonly;

  return (
    <>
      <div
        style={{
          position: 'relative',
        }}
        id={anchorId}
      >
        <TextEditor
          readonly={readonly}
          disabled={!editable}
          initialContent={initialContent}
          onActivate={() => setActiveEditorId(id)}
          onDeactivate={() => setActiveEditorId(null)}
          onBlur={(editorState) => updateText(stateSelector, editorState)}
          onImagesSelected={saveImages}
          {...props}
        />
        {showActionMenu && (
          <StyledOverflowActionMenuSmall
            disabled={!editable}
            size={'small'}
            color={'primary'}
            overflowActions={overflowActions}
          />
        )}
      </div>
      {images.length !== 0 && (
        <ImageRow
          disabled={!editable}
          images={images}
          onImagesSorted={(startIdx, targetIdx) =>
            sortImages(stateSelector, startIdx, targetIdx)
          }
          onImageSizeChanged={(id, size) =>
            changeImageSize(stateSelector, id, size)
          }
          onImageDelete={(id) => deleteImage(stateSelector, id)}
        />
      )}
    </>
  );
};
