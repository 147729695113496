import { Clear } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import type { Identifier, XYCoord } from 'dnd-core';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { Image, ImageSize } from '@/common/types/protocol';

import { SizeSelect } from './SizeSelect';
import { DragItem, ItemType } from './drag-item';

interface Props {
  className?: string;
  idx: number;
  image: Image;
  disabled?: boolean;

  moveImage: (startIdx: number, targetIdx: number) => void;
  onSizeChanged: (size: ImageSize) => void;
  onDelete: () => void;
}

const CoverImg = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

const Root = styled('div')<{ disabled?: boolean }>(({ disabled }) => ({
  position: 'relative',
  pointerEvents: disabled ? 'none' : 'auto',
}));

const StyledSizeSelect = styled(SizeSelect)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  left: theme.spacing(0.5),
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  right: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: '1rem',
  padding: theme.spacing(0.5),
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.palette.primary.dark,
  },

  '& .MuiSvgIcon-root': {
    fontSize: 'inherit',
  },
})) as typeof IconButton;

export const ProtocolImage = ({
  className,
  image,
  idx,
  moveImage,
  onSizeChanged,
  onDelete,
  disabled,
}: Props) => {
  const ref = React.useRef<HTMLImageElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemType.PROTOCOL_IMAGE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = idx;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the left
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // When dragging right, only move when the cursor is after 50%
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // When dragging left, only move when the cursor is at less than 50%
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      item.index = hoverIndex;
    },
  });

  const drag = useDrag({
    type: ItemType.PROTOCOL_IMAGE,
    item: () => {
      return { id: image.id, index: idx };
    },
    collect: () => ({}),
  })[1];

  drag(drop(ref));

  return (
    <Root disabled={disabled}>
      <CoverImg
        crossOrigin={'anonymous'} // this is extremely important, otherwise images can't be loaded
        ref={ref}
        className={className}
        src={image.url}
        alt={''}
        data-handler-id={handlerId}
      />
      <StyledSizeSelect
        value={image.size}
        onChange={onSizeChanged}
        disabled={disabled}
      />
      <DeleteButton disabled={disabled} size={'small'} onClick={onDelete}>
        <Clear />
      </DeleteButton>
    </Root>
  );
};
