import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { Logger, bugsnagOptions } from '@/services/logger';

import { App } from './app';

Bugsnag.start({
  ...bugsnagOptions,
  plugins: [new BugsnagPluginReact()],
});
Logger.instance.init(Bugsnag);

Logger.instance.log('Starting app');

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);
