import { createUseContextHook } from '@lumind-solutions/react-hooks/create-use-context-hook';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

interface ContextState {
  isVisibilityChangeListenerEnabled: () => boolean;
  disableVisibilityChangeListenerUntilWindowRefocused: () => void;
}

const VisibilityChangeContext = React.createContext<ContextState | null>(null);

/**
 * Context for accessing visibility change listener status and disabling it until window refocused.
 */
export const VisibilityChangeProvider = (props: Props) => {
  const listenerEnabledRef = React.useRef(true);

  // disable until window receives focus again
  const disableListener = () => {
    console.log('disable visibility-change again');
    listenerEnabledRef.current = false;
    const focusHandler = () => {
      console.log('enabled visibility-change again');
      listenerEnabledRef.current = true;
      window.removeEventListener('focus', focusHandler);
    };

    window.addEventListener('focus', focusHandler);
  };

  const contextValue = React.useMemo(
    () => ({
      isVisibilityChangeListenerEnabled: () => listenerEnabledRef.current,
      disableVisibilityChangeListenerUntilWindowRefocused: disableListener,
    }),
    [],
  );

  return (
    <VisibilityChangeContext.Provider value={contextValue}>
      {props.children}
    </VisibilityChangeContext.Provider>
  );
};

export const useVisibilityChangeContext = createUseContextHook(
  VisibilityChangeContext,
);
