import dayjs from 'dayjs';

import {
  Database,
  NetworkMode,
  UpdateTemplatesTask,
} from '@/services/database';
import { periodicBackgroundSync } from '@/services/periodic-background-sync';
import { TaskQueue } from '@/services/task-queue';

import { TemplateService } from './template-service';

export class UpdateTemplatesService {
  constructor(
    private readonly templateService: TemplateService,
    private readonly taskQueue: TaskQueue,
    private readonly database: Database,
  ) {}

  public async updateTemplates(networkMode: NetworkMode) {
    await this.taskQueue.addTask(UpdateTemplatesTask.create(networkMode), {
      awaitResult: true,
    });
  }

  public async updateTemplatesWhenNecessary(
    networkMode = NetworkMode.OFFLINE,
  ): Promise<void> {
    console.log('Checking for template updates');
    const lastUpdate = await this.database.getTemplateUpdateDate();
    if (
      lastUpdate === null ||
      dayjs(lastUpdate).isBefore(dayjs().subtract(24, 'hours'))
    ) {
      await this.updateTemplates(networkMode);
    } else {
      console.log('Templates were updated recently, skipping update');
    }
  }

  async enablePeriodicBackgroundUpdates() {
    return periodicBackgroundSync(
      'update-templates',
      24 * 60 * 60 * 1000, // 24 hours
    );
  }
}
