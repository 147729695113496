import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import { AppUpdateDialog } from '@/app/app-update-dialog';
import { InstallationProvider } from '@/common/providers/installation-provider';
import { ServiceProvider } from '@/common/providers/service-provider';
import { ThemeProvider } from '@/common/theme';
import { Snackbar, wrapSnackbarProvider } from '@/common/ui/snackbar';
import { AppRouter } from '@/routing';

import './i18n';
import { services } from './service-instances';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const App = wrapSnackbarProvider(() => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ServiceProvider services={services}>
          <InstallationProvider>
            <CssBaseline />
            <AppRouter />
            <Snackbar />
            <AppUpdateDialog />
          </InstallationProvider>
        </ServiceProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
});
