import { filterOpenDefects } from '@/common/types/protocol';

import { SigekoStore } from '../sigeko-store';

export const selectProtocolDefects = (s: SigekoStore) => {
  const showDone = s.showDone;
  const defects = s.protocol.content.defects;
  const protocolNumberOnOpen = s.protocolNumberOnOpen;
  if (showDone) {
    // show all defects, incl done defects, and older done defects
    return defects;
  } else {
    // show only not done defects and done defects with current protocol number
    return filterOpenDefects(defects, protocolNumberOnOpen);
  }
};
