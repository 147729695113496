import { ProtocolStatus } from '@ibag/common';

export enum NetworkMode {
  ONLINE = 'ONLINE', // Tasks are executed only when online, throws error when offline
  OFFLINE = 'OFFLINE', // Tasks are deferred until back online
}

export abstract class BaseTask {
  id: number | undefined;
  group!: string;
  networkMode!: NetworkMode;

  abstract clears(): Task['type'][];
}

export class SaveProtocolTask extends BaseTask {
  static readonly type = 'SAVE_PROTOCOL';
  readonly type = SaveProtocolTask.type;

  clears(): Task['type'][] {
    return [SaveProtocolTask.type];
  }

  protocolId!: string;

  static create(
    protocolId: string,
    networkMode: NetworkMode = NetworkMode.OFFLINE,
  ) {
    const task = new SaveProtocolTask();
    task.protocolId = protocolId;
    task.group = protocolId;
    task.networkMode = networkMode;
    return task;
  }
}

/**
 * Prepare protocol for editing: Fetch & auto-lock
 **/
export class PrepareProtocolTask extends BaseTask {
  static readonly type = 'PREPARE_PROTOCOL';
  readonly type = PrepareProtocolTask.type;

  clears(): Task['type'][] {
    return [
      PrepareProtocolTask.type,
      SaveProtocolTask.type,
      ClearProtocolTask.type,
      SyncProtocolTask.type,
    ];
  }

  protocolId!: string;

  static create(
    protocolId: string,
    networkMode: NetworkMode = NetworkMode.ONLINE,
  ) {
    const task = new PrepareProtocolTask();
    task.protocolId = protocolId;
    task.group = protocolId;
    task.networkMode = networkMode;
    return task;
  }
}

/**
 * Clean up protocol after editing: Save, Unlock, Delete
 */
export class ClearProtocolTask extends BaseTask {
  static readonly type = 'CLEAR_PROTOCOL';
  readonly type = ClearProtocolTask.type;

  clears(): Task['type'][] {
    return [
      ClearProtocolTask.type,
      SaveProtocolTask.type,
      PrepareProtocolTask.type,
      SyncProtocolTask.type,
    ];
  }

  protocolId!: string;

  static create(
    protocolId: string,
    networkMode: NetworkMode = NetworkMode.OFFLINE,
  ) {
    const task = new ClearProtocolTask();
    task.protocolId = protocolId;
    task.group = protocolId;
    task.networkMode = networkMode;
    return task;
  }
}

export class MakeProtocolOfflineAvailableTask extends BaseTask {
  static readonly type = 'MAKE_PROTOCOL_OFFLINE_AVAILABLE';
  readonly type = MakeProtocolOfflineAvailableTask.type;
  protocolId!: string;

  clears(): Task['type'][] {
    return [MakeProtocolOfflineAvailableTask.type];
  }

  static create(
    protocolId: string,
    networkMode: NetworkMode = NetworkMode.ONLINE,
  ) {
    const task = new MakeProtocolOfflineAvailableTask();
    task.protocolId = protocolId;
    task.group = protocolId;
    task.networkMode = networkMode;
    return task;
  }
}

export class LockProtocolTask extends BaseTask {
  static readonly type = 'LOCK_PROTOCOL';
  readonly type = LockProtocolTask.type;

  clears(): Task['type'][] {
    return [LockProtocolTask.type];
  }

  lock!: boolean;
  protocolId!: string;

  static create(
    protocolId: string,
    lock: boolean,
    networkMode: NetworkMode = NetworkMode.ONLINE,
  ) {
    const task = new LockProtocolTask();
    task.protocolId = protocolId;
    task.lock = lock;
    task.group = protocolId;
    task.networkMode = networkMode;
    return task;
  }
}

export class UploadLocalImagesTask extends BaseTask {
  static readonly type = 'UPLOAD_LOCAL_IMAGES';
  readonly type = UploadLocalImagesTask.type;

  clears(): Task['type'][] {
    return [UploadLocalImagesTask.type];
  }

  static create(networkMode: NetworkMode = NetworkMode.OFFLINE) {
    const task = new UploadLocalImagesTask();
    task.group = 'UPLOAD_LOCAL_IMAGES';
    task.networkMode = networkMode;
    return task;
  }
}

export class SyncProtocolTask extends BaseTask {
  static readonly type = 'SYNC_PROTOCOL';
  readonly type = SyncProtocolTask.type;
  protocolId!: string;

  clears(): Task['type'][] {
    return [SyncProtocolTask.type];
  }

  static create(
    protocolId: string,
    networkMode: NetworkMode = NetworkMode.OFFLINE,
  ) {
    const task = new SyncProtocolTask();
    task.protocolId = protocolId;
    task.group = protocolId;
    task.networkMode = networkMode;
    return task;
  }
}

export class UpdateTemplatesTask extends BaseTask {
  static readonly type = 'UPDATE_TEMPLATES';
  readonly type = UpdateTemplatesTask.type;

  clears(): Task['type'][] {
    return [UpdateTemplatesTask.type];
  }

  static create(networkMode: NetworkMode = NetworkMode.OFFLINE) {
    const task = new UpdateTemplatesTask();
    task.group = 'UPDATE_TEMPLATES';
    task.networkMode = networkMode;
    return task;
  }
}

export class DeleteProtocolTask extends BaseTask {
  static readonly type = 'DELETE_PROTOCOL';
  readonly type = DeleteProtocolTask.type;
  protocolId!: string;

  clears(): Task['type'][] {
    return [
      SaveProtocolTask.type,
      SyncProtocolTask.type,
      PrepareProtocolTask.type,
      LockProtocolTask.type,
      ClearProtocolTask.type,
      MakeProtocolOfflineAvailableTask.type,
      DeleteProtocolTask.type,
    ];
  }

  static create(
    protocolId: string,
    networkMode: NetworkMode = NetworkMode.ONLINE,
  ) {
    const task = new DeleteProtocolTask();
    task.protocolId = protocolId;
    task.group = protocolId;
    task.networkMode = networkMode;
    return task;
  }
}

export class ChangeStatusTask extends BaseTask {
  static readonly type = 'CHANGE_STATUS';
  readonly type = ChangeStatusTask.type;
  status!: ProtocolStatus;
  protocolId!: string;

  clears(): Task['type'][] {
    return [
      ChangeStatusTask.type,
      SyncProtocolTask.type,
      SaveProtocolTask.type,
      DeleteProtocolTask.type,
      ClearProtocolTask.type,
    ];
  }

  static create(
    protocolId: string,
    status: ProtocolStatus,
    networkMode = NetworkMode.ONLINE,
  ) {
    const task = new ChangeStatusTask();
    task.protocolId = protocolId;
    task.status = status;
    task.group = protocolId;
    task.networkMode = networkMode;
    return task;
  }
}

// New tasks must be added here
const constructorMap = new Map<string, new () => Task>([
  [SaveProtocolTask.type, SaveProtocolTask],
  [PrepareProtocolTask.type, PrepareProtocolTask],
  [ClearProtocolTask.type, ClearProtocolTask],
  [MakeProtocolOfflineAvailableTask.type, MakeProtocolOfflineAvailableTask],
  [LockProtocolTask.type, LockProtocolTask],
  [UploadLocalImagesTask.type, UploadLocalImagesTask],
  [SyncProtocolTask.type, SyncProtocolTask],
  [UpdateTemplatesTask.type, UpdateTemplatesTask],
  [DeleteProtocolTask.type, DeleteProtocolTask],
  [ChangeStatusTask.type, ChangeStatusTask],
]);

export function deserializeTask(task: any): Task {
  const constructor = constructorMap.get(task.type);
  if (!constructor) {
    throw new Error(`Unknown task type: ${task.type}`);
  }
  return Object.assign(new constructor(), task);
}

export type Task =
  | ChangeStatusTask
  | LockProtocolTask
  | SaveProtocolTask
  | SyncProtocolTask
  | PrepareProtocolTask
  | ClearProtocolTask
  | DeleteProtocolTask
  | UploadLocalImagesTask
  | MakeProtocolOfflineAvailableTask
  | UpdateTemplatesTask;
