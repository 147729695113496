import { styled } from '@mui/material';
import { GridColDef, gridClasses } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ListProtocolsParams,
  ListProtocolsSort,
  SortOrder,
} from '@ibag/common';

import { useNotUndefined } from '@/common/hooks/use-not-undefined';
import { useUser } from '@/common/hooks/use-user';
import { StyledDataGrid, WhiteTypography } from '@/common/styles';
import {
  SpaceBetweenRowContainer,
  TableRoundedContainer,
} from '@/common/ui/containers';
import { ErrorMessage } from '@/common/ui/error-message';
import { LockStatusIcon, createLockStatus } from '@/common/ui/lock-status-icon';
import { SearchBar } from '@/common/ui/search-bar';
import { convertDateToDateTimeString } from '@/common/utils/format-date';

import { EditProtocolButton } from './EditProtocolButton';
import { ProtocolTableRowOverflowMenu } from './ProtocolTableRowOverflowMenu';
import { Filter } from './filter/Filter';
import { ProtocolTableRow } from './types';
import { useFetchPageOfProtocols } from './useFetchPageOfProtocols';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(5),
}));

const Header = styled(SpaceBetweenRowContainer)(() => ({
  display: 'flex',
}));

const HeaderLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const ActionContainer = styled('div')(() => ({
  display: 'flex',
  gap: 10,
}));

export const ProtocolTable = () => {
  const { t } = useTranslation();
  const user = useUser();
  const [dataGridState, setDataGridState] = useState<ListProtocolsParams>({
    page: 0,
    pageSize: 50,
    sortBy: ListProtocolsSort.updatedAt,
    sortOrder: SortOrder.desc,
  });

  const { fetchedProtocols, isLoading, error } = useFetchPageOfProtocols(
    dataGridState.page,
    dataGridState.pageSize,
    dataGridState.sortBy,
    dataGridState.sortOrder,
    dataGridState.status,
    dataGridState.createdBy,
    dataGridState.q,
  );

  /*
   * Problem: During DataGrids loading-time the dataGridState becomes undefined vor a short time. In this time, rowCount would be undefined.
   * Solution: useNotUndefined turns the undefined to initialFallbackValue. In our case its 0, while loading.
   */
  const rowCount = useNotUndefined(fetchedProtocols?.totalItems, 0);

  const columns: GridColDef<ProtocolTableRow>[] = [
    {
      field: 'title',
      headerName: t('page.home.protocol_table.columns.title'),
      flex: 1,
      minWidth: 300,
    },

    {
      field: 'createdBy',
      sortable: false,
      headerName: t('page.home.protocol_table.columns.createdBy'),
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return convertToShortenedName(params.value.name);
      },
    },
    {
      field: 'writtenBy',
      sortable: false,
      headerName: t('page.home.protocol_table.columns.writtenBy'),
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        if (params.value !== null) {
          return convertToShortenedName(params.value.name);
        }
      },
    },
    {
      field: 'updatedAt',
      headerName: t('page.home.protocol_table.columns.updatedAt'),
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return convertDateToDateTimeString(params.value);
      },
    },
    {
      field: 'status',
      headerName: t('page.home.protocol_table.columns.status'),
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return t(`common.protocol_status.${params.value}`);
      },
    },
    {
      field: 'actions',
      sortable: false,
      headerName: t('page.home.protocol_table.columns.actions'),
      flex: 1,
      minWidth: 160,
      maxWidth: 160,
      align: 'right',
      renderCell: (params) => {
        const lockIconContent = createLockStatus(params.row.lockedBy, user);
        return (
          <ActionContainer>
            <LockStatusIcon
              tooltipDescription={lockIconContent?.description}
              icon={lockIconContent?.icon}
            />
            <EditProtocolButton id={params.row.id} />
            <ProtocolTableRowOverflowMenu
              protocolId={params.row.id}
              lockedBy={params.row.lockedBy}
            />
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <WhiteTypography style={{ whiteSpace: 'nowrap' }} variant={'h2'}>
            {t('page.home.protocol_table.title')}
          </WhiteTypography>
          <SearchBar
            onSearchProtocolChange={(value) => {
              setDataGridState((prev) => ({ ...prev, q: value || undefined }));
            }}
            value={dataGridState.q ?? ''}
          />
        </HeaderLeft>
        <ActionContainer>
          <Filter
            selectedFilterState={{
              createdBy: dataGridState.createdBy ?? null,
              status: dataGridState.status ?? null,
            }}
            onFilterChange={(newSelection) => {
              setDataGridState((prev) => ({
                ...prev,
                [newSelection.type]: newSelection.value ?? undefined,
              }));
            }}
          />
        </ActionContainer>
      </Header>

      <TableRoundedContainer>
        <StyledDataGrid
          paginationMode={'server'}
          sortingMode={'server'}
          pagination
          loading={isLoading}
          disableColumnFilter
          disableVirtualization
          getRowHeight={() => 'auto'}
          disableColumnMenu
          localeText={{
            noRowsLabel: t(`common.table.empty_table`),
            noResultsOverlayLabel: t(`common.table.empty_table`),
          }}
          rowCount={rowCount}
          page={dataGridState.page}
          rowsPerPageOptions={[]}
          pageSize={dataGridState.pageSize}
          onPageChange={(newPage) => {
            setDataGridState((prev) => ({ ...prev, page: newPage }));
          }}
          onPageSizeChange={(newSize) => {
            setDataGridState((prev) => ({ ...prev, pageSize: newSize }));
          }}
          onSortModelChange={([sortModel]) => {
            if (fetchedProtocols !== undefined && sortModel !== undefined) {
              let sortBy: ListProtocolsSort = ListProtocolsSort.title;
              if (
                sortModel.field !== undefined &&
                (Object.values(ListProtocolsSort) as string[]).includes(
                  sortModel.field,
                )
              ) {
                sortBy = sortModel.field as ListProtocolsSort;
              }

              setDataGridState((prev) => ({
                ...prev,
                sortOrder: SortOrder[sortModel.sort ?? SortOrder.asc],
                sortBy: sortBy,
              }));
            }
          }}
          autoHeight
          disableSelectionOnClick
          rows={fetchedProtocols?.items ?? []}
          columns={columns}
          sx={{
            [`& .${gridClasses.cell}`]: {
              // Problem: If you will show 2 lines of text, you need getRowHeight to return "auto".
              // This causes smaller padding, so we will need py:1, for the same look and feel as before.
              py: 1,
            },
          }}
        />
      </TableRoundedContainer>
      <ErrorMessage error={error} />
    </Container>
  );
};

const convertToShortenedName = (name: string): string => {
  const [firstName, lastName] = name.split(' ');
  return `${firstName.slice(0, 1)}. ${lastName}`;
};
