import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';

export const useDeleteTemplate = () => {
  const { templateService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: deleteTemplate, isLoading } = useMutation<
    void,
    AppError,
    number
  >((id) => templateService.deleteTemplate(id), {
    onError: (error) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, error),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['templates']);
    },
  });
  return { deleteTemplate, isLoading };
};
