export var AssigneePreset;
(function (AssigneePreset) {
    AssigneePreset["CLIENT"] = "CLIENT";
    AssigneePreset["CONSTRUCTION_MANAGEMENT"] = "CONSTRUCTION_MANAGEMENT";
    AssigneePreset["SPECIALIST_CONSTRUCTION_MANAGEMENT"] = "SPECIALIST_CONSTRUCTION_MANAGEMENT";
    AssigneePreset["ALL_CRAFTS"] = "ALL_CRAFTS";
    AssigneePreset["ALL_ATTENDEES"] = "ALL_ATTENDEES";
    AssigneePreset["NOTICE"] = "NOTICE";
    AssigneePreset["INFO"] = "INFO";
})(AssigneePreset || (AssigneePreset = {}));
