import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { useServices } from '@/common/providers/service-provider';
import { Logger } from '@/services/logger';

/**
 * Registers periodic background sync, when the app is installed as a PWA
 */
export function usePeriodicSync() {
  const { syncProtocolsService, updateTemplatesService } = useServices();

  const { mutate } = useMutation(
    async () => {
      await syncProtocolsService.enablePeriodicBackgroundUpdates();
      await updateTemplatesService.enablePeriodicBackgroundUpdates();
    },
    {
      networkMode: 'always',
    },
  );

  React.useEffect(() => {
    Logger.instance.log('usePeriodicSync...');
    mutate();
  }, [mutate]);
}
