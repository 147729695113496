import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { LoginPage } from '@/pages/auth';
import { ForgotPasswordPage } from '@/pages/auth/forgot-password';
import { RegisterPage } from '@/pages/auth/register';
import { EditProtocolPage } from '@/pages/edit-protocol';
import { ExportViewPage } from '@/pages/export';
import { HomePage } from '@/pages/home';
import { TemplatesPage } from '@/pages/manage-template';
import { ManageUserPage } from '@/pages/manage-user';
import { RequireAuth, routes } from '@/routing';

const appRoutes = [
  { path: routes.LOGIN, element: <LoginPage /> },
  { path: routes.REGISTER, element: <RegisterPage /> },
  { path: routes.FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
  {
    element: <RequireAuth />,
    children: [
      { path: routes.HOME, element: <HomePage /> },
      { path: routes.EDIT_PROTOCOL, element: <EditProtocolPage /> },
      { path: routes.MANAGE_USER, element: <ManageUserPage /> },
      { path: routes.TEMPLATES, element: <TemplatesPage /> },
      { path: routes.EXPORT, element: <ExportViewPage /> },
    ],
  },
];

export const AppRouter = () => {
  const router = createBrowserRouter(appRoutes);

  return <RouterProvider router={router} />;
};
