import { useBeforeInstallPrompt } from '@lumind-solutions/react-hooks/use-add-to-homescreen';
import React, { useContext } from 'react';

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  prompt(): Promise<void>;
}

const InstallationContext =
  React.createContext<BeforeInstallPromptEvent | null>(null);
InstallationContext.displayName = 'InstallationContext';

interface Props {
  children: React.ReactNode;
}

export const InstallationProvider = ({ children }: Props) => {
  const pwaInstallEvent = useBeforeInstallPrompt();

  return (
    <InstallationContext.Provider value={pwaInstallEvent}>
      {children}
    </InstallationContext.Provider>
  );
};

export const useInstallationPromptEvent = () => {
  return useContext(InstallationContext);
};
