import { $generateNodesFromDOM } from '@lexical/html';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { styled } from '@mui/material';
import { $getRoot, LexicalEditor } from 'lexical';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  EditorContentState,
  HtmlContent,
  LexicalContent,
} from '@/common/types/protocol';

import {
  AutoLinkPlugin,
  BlurPlugin,
  EditablePlugin,
  ImagePlugin,
  ToolbarPlugin,
} from '../plugins';
import { ActivatableContainer } from './ActivatableContainer';
import { nodes } from './node-list';
import './styles.css';
import { theme } from './theme';

const TextAreaContainer = styled('div')(() => ({
  position: 'relative',
}));

const Placeholder = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  color: theme.palette.text.secondary,
  userSelect: 'none',
  pointerEvents: 'none',
}));

const StyledContentEditable = styled(ContentEditable)((props) => ({
  minHeight: 100,
  padding: props.theme.spacing(1),
  backgroundColor: props.theme.palette.background.default,
  borderRadius: props.theme.shape.borderRadius,
  outline: 'none',
}));

interface Props {
  readonly?: boolean;
  onActivate?: () => void;
  onDeactivate?: () => void;
  onBlur?: (editorState: EditorContentState) => void;
  onImagesSelected: (images: FileList) => void;
  initialContent: HtmlContent | LexicalContent | null;
  disabled?: boolean;
}

export const TextEditor = ({
  initialContent,
  readonly,
  onActivate,
  onDeactivate,
  onBlur,
  onImagesSelected,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  function initialEditorState() {
    if (!initialContent) {
      return undefined;
    } else if (typeof initialContent === 'object' && 'html' in initialContent) {
      return (editor: LexicalEditor) => {
        editor.update(() => {
          const parser = new DOMParser();
          const dom = parser.parseFromString(
            initialContent.html as string,
            'text/html',
          );

          const nodes = $generateNodesFromDOM(editor, dom);
          nodes.forEach((n) => $getRoot().append(n));
        });
      };
    } else {
      return JSON.stringify(initialContent);
    }
  }

  const initialConfig: InitialConfigType = {
    theme,
    namespace: 'MyEditor',
    nodes: nodes,
    editable: !readonly,
    editorState: initialEditorState(),
    onError: (error) => console.log('lexical error', error),
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <ActivatableContainer
        disabled={disabled}
        readonly={readonly ?? false}
        onActivate={onActivate}
      >
        {!readonly && (
          <ToolbarPlugin
            onDeactivate={onDeactivate}
            onImagesSelected={onImagesSelected}
          />
        )}
        {!readonly && <HistoryPlugin />}
        {!readonly && <BlurPlugin onBlur={onBlur} />}
        <EditablePlugin readonly={readonly ?? false} />
        <TextAreaContainer>
          <RichTextPlugin
            contentEditable={
              <StyledContentEditable className={'text-editor'} />
            }
            placeholder={(isEditable) =>
              isEditable ? (
                <Placeholder>{t('common.text_editor.placeholder')}</Placeholder>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoLinkPlugin />
          <ImagePlugin />

          <TablePlugin />
          <ListPlugin />
        </TextAreaContainer>
      </ActivatableContainer>
    </LexicalComposer>
  );
};
