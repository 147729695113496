import { NativeSelect, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ImageSize } from '@/common/types/protocol';

interface Props {
  disabled?: boolean;
  value: ImageSize;
  onChange: (size: ImageSize) => void;
  className?: string;
}

const StyledNativeSelect = styled(NativeSelect)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: 'unset',
  borderRadius: 4,
  paddingBottom: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.50)',

  '&:before': {
    content: 'none',
  },
  '&:after': {
    content: 'none',
  },

  // increase specificity via multiple &
  '&&& .MuiNativeSelect-select': {
    padding: theme.spacing(0, 2, 0, 0.5),
    height: 'unset',
  },
  '& .MuiNativeSelect-icon': {
    fontSize: '1rem',
  },
}));

export const SizeSelect = ({ className, value, onChange, disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledNativeSelect
      disabled={disabled}
      className={className}
      value={value}
      onChange={(e) => onChange(e.target.value as ImageSize)}
      inputProps={{
        name: 'image-size',
      }}
    >
      {Object.values(ImageSize).map((size) => (
        <option key={size} value={size}>
          {t(`common.image_size.${size}`)}
        </option>
      ))}
    </StyledNativeSelect>
  );
};
