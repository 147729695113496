import ChatIcon from '@mui/icons-material/Chat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { alpha, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SigekoProtocolDefect as Defect,
  byId,
  formatDefectNumber,
} from '@/common/types/protocol';
import {
  OverflowActionMenu,
  PrimaryButton,
  SecondaryButton,
} from '@/common/ui/buttons';
import { SpaceBetweenRowContainer } from '@/common/ui/containers';
import {
  DropDownMenuItem,
  DropDownMenuItemElement,
} from '@/common/ui/dropdown';
import { convertDateToDateString } from '@/common/utils/format-date';

import {
  ProtocolItem,
  RichTextField,
  useEditable,
  useProtocolState,
} from '../common';
import { SigekoDefectUpdateItem } from './SigekoDefectUpdateItem';
import { AddAssigneesButton } from './actions/assignees';
import { SetDeadlineButton } from './actions/deadline';
import { SigekoProtocolState, SigekoStore } from './sigeko-store';

const ContentContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

interface Props {
  item: Defect;
}

export const SigekoProtocolDefect = ({ item: defect }: Props) => {
  const { t } = useTranslation();
  const editable = useEditable();
  const theme = useTheme();
  const isDefectMarkedAsDone = defect.done !== null;

  const [
    addDefectUpdate,
    deleteDefect,
    isCurrentProtocolNumber,
    isMarkedAsDoneDuringCurrentProtocolNumber,
    markDefectAsNotDone,
    markDefectAsDone,
    setActiveEditorId,
    dateOfInspection,
  ] = useProtocolState((s: SigekoStore) => [
    s.addDefectUpdate,
    s.deleteDefect,
    s.protocol.header.protocolNumber === defect.defectNumber.protocolNumber,
    s.protocol.header.protocolNumber === defect.done,
    s.markDefectAsNotDone,
    s.markDefectAsDone,
    s.setActiveEditorId,
    s.protocol.header.date,
  ]);

  const overflowActions: DropDownMenuItemElement[] = [
    <DropDownMenuItem
      disabled={!isCurrentProtocolNumber}
      confirmable={{
        onConfirm: () => {
          if (defect.id) {
            deleteDefect(defect.id);
          }
        },
        title: t(
          `page.edit_protocol.content.defects.defect_actions.delete.confirm.title`,
        ),
        text: t(
          `page.edit_protocol.content.defects.defect_actions.delete.confirm.description`,
        ),
        cancelButtonTitle: t(
          `page.edit_protocol.content.defects.defect_actions.delete.confirm.cancel_button`,
        ),
        confirmButtonTitle: t(
          `page.edit_protocol.content.defects.defect_actions.delete.confirm.confirm_button`,
        ),
      }}
      key={'delete'}
    >
      {t(`page.edit_protocol.content.defects.defect_actions.delete.label`)}
    </DropDownMenuItem>,
    <DropDownMenuItem
      disabled={!isMarkedAsDoneDuringCurrentProtocolNumber}
      key={'restore'}
      onClick={() => markDefectAsNotDone(defect.id)}
    >
      {t(
        `page.edit_protocol.content.defects.defect_actions.restore_defect.label_restore`,
      )}
    </DropDownMenuItem>,
  ];

  const activeDefectStyle =
    defect.done !== null
      ? { backgroundColor: alpha(theme.palette.tertiary.light, 0.3) }
      : undefined;

  return (
    <ProtocolItem
      sx={activeDefectStyle}
      title={t(`page.edit_protocol.content.defects.title`, {
        defectNumber: formatDefectNumber(defect.defectNumber),
      })}
      actions={[
        <SetDeadlineButton
          key={'set-deadline'}
          disabled={isDefectMarkedAsDone}
          defect={defect}
        />,
        <AddAssigneesButton
          key={'set-assignees'}
          disabled={isDefectMarkedAsDone}
          defect={defect}
        />,
      ]}
      overflowMenu={
        <OverflowActionMenu
          size={'small'}
          color={'primary'}
          overflowActions={overflowActions}
        />
      }
    >
      <ContentContainer>
        <RichTextField
          anchorId={defect.id}
          stateSelector={(s: SigekoProtocolState) =>
            s.content.defects.find(byId(defect.id))
          }
          initialContent={defect.text}
          images={defect.images}
          editable={editable && !isDefectMarkedAsDone}
        />
        {defect.updates.map((update) => (
          <SigekoDefectUpdateItem
            key={update.id}
            defectId={defect.id}
            defectUpdate={update}
            editable={editable && !isDefectMarkedAsDone}
          />
        ))}
      </ContentContainer>
      {!isDefectMarkedAsDone && (
        <SpaceBetweenRowContainer>
          <SecondaryButton
            disabled={!editable}
            size={'small'}
            startIcon={<ChatIcon />}
            onClick={() =>
              addDefectUpdate(
                defect.id,
                `${t(`page.edit_protocol.content.defects.update_default_text`, {
                  date: convertDateToDateString(dateOfInspection ?? new Date()),
                })}`,
              )
            }
          >
            {t(`page.edit_protocol.content.defects.add_update_button`)}
          </SecondaryButton>
          <PrimaryButton
            size={'small'}
            disabled={!editable}
            startIcon={<CheckCircleIcon />}
            onClick={() => {
              markDefectAsDone(defect.id);
              setActiveEditorId(null);
            }}
          >
            {t(`page.edit_protocol.content.defects.done_button`)}
          </PrimaryButton>
        </SpaceBetweenRowContainer>
      )}
    </ProtocolItem>
  );
};
