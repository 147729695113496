import { AddAPhoto } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';

import { useVisibilityChangeContext } from '@/common/providers/visibility-change-provider';

import { ToolbarIconButton } from '../ToolbarIconButton';

const HiddenInput = styled('input')({
  display: 'none',
});

interface Props {
  onImagesSelected: (files: FileList) => void;
}

export const ImageButton = ({ onImagesSelected }: Props) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const { disableVisibilityChangeListenerUntilWindowRefocused } =
    useVisibilityChangeContext();

  const handleClick = () => {
    if (fileInputRef.current) {
      disableVisibilityChangeListenerUntilWindowRefocused();
      // Forward click to hidden input
      fileInputRef.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      onImagesSelected(files);
    }
  };

  return (
    <>
      <HiddenInput
        type="file"
        multiple
        accept="image/*"
        ref={fileInputRef}
        onChange={handleChange}
      />
      <ToolbarIconButton onClick={handleClick}>
        <AddAPhoto />
      </ToolbarIconButton>
    </>
  );
};
