import { IconButton, Tooltip, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EditProtocolSvg } from '@/assets/svg';
import { routes } from '@/routing';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  '&.MuiIconButton-root': {
    backgroundColor: `${theme.palette.primary.main}10`,
  },
}));

interface Props {
  id: string;
}

export const EditProtocolButton = React.memo(({ id }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Tooltip
        title={t('page.home.protocol_table.edit_protocol_button.tooltip')}
      >
        <StyledIconButton
          onClick={() => navigate(routes.generateEditProtocolPath(id))}
        >
          <EditProtocolSvg
            fill={theme.palette.primary.main}
            height={'100%'}
            width={'100%'}
          />
        </StyledIconButton>
      </Tooltip>
    </>
  );
});
