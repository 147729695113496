import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProtocolState } from '../common';
import { SigekoProtocolDefaultTextField } from './SigekoProtocolDefaultTextField';
import { SigekoStore } from './sigeko-store';

export const SigekoProtocolStartText = () => {
  const { t } = useTranslation();

  const startText = useProtocolState(
    (s: SigekoStore) => s.protocol.content.startText,
  );

  return (
    <SigekoProtocolDefaultTextField
      label={t(`page.edit_protocol.content.start_text`)}
      item={startText}
      stateSelector={(s) => s.content.startText}
    />
  );
};
