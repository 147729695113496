import { Link as MuiLink, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from '@/routing';
import { ProtocolClonedMessage } from '@/services/database';

import { BaseMessageCard } from './BaseMessageCard';

interface Props {
  message: ProtocolClonedMessage;
}

export const ProtocolClonedMessageCard = ({ message }: Props) => {
  const { t } = useTranslation();

  return (
    <BaseMessageCard
      id={message.id!}
      title={t('common.message_cards.protocol_cloned.title')}
    >
      <Typography paragraph variant={'body2'}>
        {t(`common.errors.${message.causeErrorType}`)}
      </Typography>
      <Typography variant={'body2'}>
        {t('common.message_cards.protocol_cloned.text_1')}
        <MuiLink
          component={Link}
          to={routes.generateEditProtocolPath(message.clonedProtocolId)}
        >
          {t('common.message_cards.protocol_cloned.text_2', {
            title: message.protocolTitle,
          })}
        </MuiLink>
        {t('common.message_cards.protocol_cloned.text_3')}
      </Typography>
    </BaseMessageCard>
  );
};
