import { Typography, lighten, styled } from '@mui/material';

export const RoundedCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: 333,
  height: 200,
  cursor: 'pointer',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.rounded,
  boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(1),
}));

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const HeadlineContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1.2),
}));

export const Title = styled(Typography)({
  width: 200,
  fontSize: 18,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}) as typeof Typography;

export const SmallText = styled(Typography)({
  fontSize: 14,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const TransparentSmallText = styled(SmallText)({
  width: 200,
  opacity: 0.6,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledSvgContainer = styled('div')(({ theme, color }) => ({
  padding: theme.spacing(0.7),
  display: 'flex',
  fill: color,
  backgroundColor: lighten(color ? color : '#000', 0.8),
  borderRadius: theme.shape.borderRadius,
  width: 40,
  height: 40,
}));

export const DetailContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(2),
}));

export const Detail = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '30%',
});
export const DetailStatusContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const TitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});
