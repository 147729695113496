import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { UploadErrorDto } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';

export const useUploadTemplate = () => {
  const { templateService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate: uploadTemplates,
    isLoading,
    data: errors,
  } = useMutation<UploadErrorDto[], AppError, File[]>(
    (templateFiles) => templateService.uploadTemplates(templateFiles),
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['templates']);
        return templateService.updateTemplates();
      },
    },
  );

  // convert UploadErrorDtos to AppErrors
  const uploadFailures =
    errors?.map((it) => new AppError(it.errorType, it.filename)) ?? [];

  return { uploadTemplates, isLoading, uploadFailures };
};
