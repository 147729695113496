import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';

interface Props {
  readonly: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onActivate?: () => void;
}

export const ActivatableContainer = ({
  readonly,
  children,
  onActivate,
  disabled,
}: Props) => {
  const [editor] = useLexicalComposerContext();

  return (
    <div
      onClick={() => {
        if (readonly && !disabled) {
          editor.setEditable(true);
          editor.focus();
          onActivate?.();
        }
      }}
    >
      {children}
    </div>
  );
};
