import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';

export class ProtocolClonedError extends AppError {
  constructor(
    public readonly cause: AppError,
    public readonly originalProtocolId: string,
    public readonly clonedProtocolId: string,
  ) {
    super(
      ErrorType.PROTOCOL_CLONED,
      'Protocol could not be saved and was cloned.',
    );
  }
}
