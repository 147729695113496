import { ErrorType } from '@ibag/common';

export class AppError extends Error {
  public errorType: ErrorType;
  public message: string;

  constructor(type: ErrorType, message = '') {
    super(message);
    Object.setPrototypeOf(this, AppError.prototype);
    this.errorType = type;
    this.message = message;
  }

  toPlainObject() {
    return {
      errorType: this.errorType,
      message: this.message,
    };
  }
}
