import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';

export const useClearOfflineAvailable = (
  protocolId: string,
  options: {
    showErrorSnackbar?: boolean;
    onSuccess?: () => void;
    onError?: (err: AppError) => void;
  },
) => {
  const { protocolService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { isLoading, mutate } = useMutation<void, AppError, void>(
    async () => protocolService.clearOfflineAvailable(protocolId),
    {
      networkMode: 'always', // also execute when offline
      onSuccess: () => {
        options.onSuccess?.();
      },
      onError: (err) => {
        if (options.showErrorSnackbar) {
          showSnackbar({
            variant: 'error',
            content: createErrorMessage(t, err),
          });
        }
        options.onError?.(err);
      },
    },
  );

  return {
    isLoading,
    clearOfflineAvailable: mutate,
  };
};
