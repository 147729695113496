import { lighten, styled } from '@mui/material/styles';

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  fontWeight: 700,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));
