import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SigekoDefectUpdate, byId } from '@/common/types/protocol';
import {
  DropDownMenuItem,
  DropDownMenuItemElement,
} from '@/common/ui/dropdown';

import { RichTextField, useProtocolState } from '../common';
import { SigekoProtocolState, SigekoStore } from './sigeko-store';

const Container = styled('div')(({ theme }) => ({
  width: '80%',
  marginTop: theme.spacing(3),
}));

interface Props {
  defectId: string;
  defectUpdate: SigekoDefectUpdate;
  editable: boolean;
}

export const SigekoDefectUpdateItem = ({
  defectId,
  defectUpdate,
  editable,
}: Props) => {
  const { t } = useTranslation();
  const [deleteDefectUpdate] = useProtocolState((s: SigekoStore) => [
    s.deleteDefectUpdate,
  ]);

  const actions: DropDownMenuItemElement[] = [
    <DropDownMenuItem
      confirmable={{
        onConfirm: () => deleteDefectUpdate(defectId, defectUpdate.id),
        title: t(
          `page.edit_protocol.content.defects.update_actions.delete.confirm.title`,
        ),
        text: t(
          `page.edit_protocol.content.defects.update_actions.delete.confirm.description`,
        ),
        cancelButtonTitle: t(
          `page.edit_protocol.content.defects.update_actions.delete.confirm.cancel_button`,
        ),
        confirmButtonTitle: t(
          `page.edit_protocol.content.defects.update_actions.delete.confirm.confirm_button`,
        ),
      }}
      key={'delete'}
    >
      {t(`page.edit_protocol.content.defects.update_actions.delete.title`)}
    </DropDownMenuItem>,
  ];
  return (
    <Container>
      <RichTextField
        anchorId={defectUpdate.id}
        overflowActions={actions}
        stateSelector={(s: SigekoProtocolState) =>
          s.content.defects
            .find(byId(defectId))
            ?.updates.find(byId(defectUpdate.id))
        }
        initialContent={defectUpdate.text}
        images={defectUpdate.images}
        editable={editable}
      />
    </Container>
  );
};
