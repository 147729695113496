import { useServices } from '@/common/providers/service-provider';

import { useProtocol } from './ProtocolProvider';

/**
 * Returns true if the protocol can be edited by the current user.
 */
export function useEditable() {
  const { authService } = useServices();
  const protocol = useProtocol();

  return !protocol.lockedBy || protocol.lockedBy.id === authService.user?.id;
}
