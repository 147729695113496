import { DefectNumber } from '@/common/types/protocol';

import { SigekoProtocolState } from '../sigeko-store';

export function nextDefectNumber(protocol: SigekoProtocolState): DefectNumber {
  const currentVersionItems = protocol.content.defects.filter(
    (item) =>
      item.defectNumber.protocolNumber === protocol.header.protocolNumber,
  );

  return {
    protocolNumber: protocol.header.protocolNumber,
    itemNumber: currentVersionItems.length + 1,
  };
}
