import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { styled } from '@mui/material';
import React from 'react';

import { LexicalContent } from '@/common/types/protocol';

import { nodes } from './node-list';
import './styles.css';
import { theme } from './theme';

interface Props {
  initialContent: LexicalContent | null;
}

const StyledContentEditable = styled(ContentEditable)(({ theme }) => ({
  /*
   * Allows the element to behave as if its children were direct children of its parent,
   * effectively hiding the element itself from the document tree.
   * This is necessary to for proper page breaks when rendering with puppeteer.
   */
  display: 'contents',

  // Element itself cannot have margin/padding, so direct children get margin instead
  '& > *': {
    marginLeft: theme.spacing(1),
  },
}));

export const PrintEditor = ({ initialContent }: Props) => {
  const initialConfig: InitialConfigType = {
    theme,
    namespace: 'MyEditor',
    nodes: nodes,
    editable: false,
    editorState: JSON.stringify(initialContent),
    onError: (error) => console.log('lexical error', error),
  };

  return initialContent ? (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        placeholder={<span />}
        contentEditable={<StyledContentEditable />}
        ErrorBoundary={LexicalErrorBoundary}
      />
    </LexicalComposer>
  ) : null;
};
