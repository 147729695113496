import SearchIcon from '@mui/icons-material/Search';
import { InputBase, alpha, styled } from '@mui/material';
import React from 'react';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  color: theme.palette.common.white,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.common.white,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100px',
    },
  },
}));

interface Props {
  value: string | undefined;
  onSearchProtocolChange: (value: string | undefined) => void;
}

export const SearchBar = ({
  onSearchProtocolChange,
  value,
  ...props
}: Props) => {
  return (
    <Search {...props}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        value={value}
        onChange={(e) => {
          if (e.currentTarget.value.length === 0) {
            onSearchProtocolChange(undefined);
          } else {
            onSearchProtocolChange(e.currentTarget.value);
          }
        }}
      />
    </Search>
  );
};
