import { useLiveQuery } from 'dexie-react-hooks';

import { useServices } from '@/common/providers/service-provider';
import { Protocol } from '@/common/types/protocol';

/**
 * Returns the local protocol with the given id, updated whenever it changes in db.
 * @param protocolId
 * @returns undefined -> not yet loaded, null -> not found, Protocol -> found
 */
export function useLiveProtocol(
  protocolId: string,
): Protocol | null | undefined {
  const { protocolService } = useServices();

  return useLiveQuery(
    () => protocolService.fetchLocalProtocol(protocolId),
    [protocolId],
  );
}
