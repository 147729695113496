import { messageService } from '@/app/service-instances/message-service-instance';
import { ProtocolService } from '@/services/protocol';

import { authorizedHttpClient } from './authenticated-http-client-instance';
import { database } from './database-instance';
import { protocolImageService } from './protocol-image-service-instance';

export const protocolService = new ProtocolService(
  authorizedHttpClient,
  database,
  protocolImageService,
  messageService,
);
