import {
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
} from '@mui/icons-material';
import { ToggleButtonGroup } from '@mui/material';
import { $isRangeSelection, FORMAT_TEXT_COMMAND } from 'lexical';
import _ from 'lodash';
import React from 'react';

import { useToolbarApi } from '../../context';
import { useToolbarUpdate } from '../../useToolbarUpdate';
import { ToolbarToggleButton } from '../ToolbarToggleButton';

enum TextStyle {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  STRIKETHROUGH = 'strikethrough',
}

export const TextStylesButtonGroup = () => {
  const [activeStyles, setActiveStyles] = React.useState<TextStyle[]>([]);
  const { editor } = useToolbarApi();

  useToolbarUpdate((editor, { selection }) => {
    if ($isRangeSelection(selection)) {
      const styles = Object.values(TextStyle).filter((style) =>
        selection.hasFormat(style),
      );
      setActiveStyles(styles);
    }
  });

  // adds or removes a text style
  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    activeFormats: TextStyle[],
  ) => {
    //get the new selected format by removing the old styles from the selected newFormats
    const newFormat = _.difference(activeFormats, activeStyles);

    //get the removed format by removing the new removed format from the selected newFormats
    const removeFormat = _.difference(activeStyles, activeFormats);

    // format is always the selected or removed single style
    const format = [...newFormat, ...removeFormat];

    format.forEach((format) => {
      if (Object.values(TextStyle).includes(format)) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
      } else {
        console.log('Textstyle not exist!');
      }
    });
  };

  return (
    <>
      <ToggleButtonGroup
        size="small"
        value={activeStyles}
        onChange={handleFormat}
      >
        <ToolbarToggleButton value={TextStyle.BOLD}>
          <FormatBold />
        </ToolbarToggleButton>
        <ToolbarToggleButton value={TextStyle.ITALIC}>
          <FormatItalic />
        </ToolbarToggleButton>
        <ToolbarToggleButton value={TextStyle.UNDERLINE}>
          <FormatUnderlined />
        </ToolbarToggleButton>
        <ToolbarToggleButton value={TextStyle.STRIKETHROUGH}>
          <FormatStrikethrough />
        </ToolbarToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
