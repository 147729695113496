import { Typography, styled } from '@mui/material';
import React from 'react';

import { LogoSvg } from '@/assets/svg';
import { SpaceBetweenRowContainer } from '@/common/ui/containers';

export const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
export const StyledLogoSvg = styled(LogoSvg)(() => ({
  height: 60,
}));

export const Address = styled('div')(() => ({
  textAlign: 'right',
}));

export const PDFHeader = () => {
  return (
    <Header>
      <SpaceBetweenRowContainer>
        <StyledLogoSvg />
        <Address>
          <Typography variant={'body2'}>
            Ingenieurbüro für Arbeitssicherheit und Gesundheitsschutz
            <br />
            Karsten Klinker
            <br />
            Berliner Straße 25a
            <br />
            23823 Seedorf
            <br />
            Fon: 04555/1314
            <br />
            info@ibag-seedorf.de
            <br />
          </Typography>
        </Address>
      </SpaceBetweenRowContainer>
    </Header>
  );
};
