import { useQuery } from '@tanstack/react-query';

import { ProtocolUserDto } from '@ibag/common';

import { useUser } from '@/common/hooks/use-user';
import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';

export function useFetchProtocolUsers() {
  const user = useUser();
  const { userService } = useServices();

  const { isFetching, data, error } = useQuery<ProtocolUserDto[], AppError>(
    ['protocol-users'],
    async () => {
      const users = await userService.fetchUsers();
      return users.map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
      }));
    },
  );

  const fallbackUsers = user
    ? [{ id: user.id, name: user.displayName ?? '?' }]
    : [];

  return {
    isFetching,
    // when offline, we still want to show the current user, so the use call self-assign the protocol
    users: data ?? fallbackUsers,
    error,
  } as const;
}
