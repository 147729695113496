import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { StoreApi } from 'zustand';

import { ErrorType } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { Protocol } from '@/common/types/protocol';
import { ProtocolStoreFactory } from '@/pages/edit-protocol/common';
import { ClearProtocolTask, PrepareProtocolTask } from '@/services/database';
import { Logger } from '@/services/logger';

import { usePageLifecycle } from './usePageLifecycle';

export function useEditProtocol(protocolId: string) {
  const { taskQueue, protocolService, database } = useServices();
  const queryClient = useQueryClient();

  function createStore(protocol: Protocol) {
    return ProtocolStoreFactory.createProtocolStore(protocol, database);
  }

  const {
    mutate: prepare,
    data: store,
    isLoading,
    error,
  } = useMutation<StoreApi<any>, AppError>(
    async () => {
      try {
        Logger.instance.log(`Preparing protocol ${protocolId}`);
        await taskQueue.addTask(PrepareProtocolTask.create(protocolId), {
          awaitResult: true,
        });
        return createStore(
          (await protocolService.fetchLocalProtocol(protocolId))!,
        );
      } catch (e) {
        const protocol = await protocolService.fetchLocalProtocol(protocolId);
        // fallback to local protocol when offline and offline available protocol exists
        if (
          protocol?.offlineAvailable === true &&
          e instanceof AppError &&
          e.errorType === ErrorType.NETWORK
        ) {
          Logger.instance.log(
            `Couldn't fetch protocol ${protocolId}, fall back to local version`,
          );
          return createStore(protocol);
        } else {
          Logger.instance.logError(
            `Couldn't prepare protocol ${protocolId}`,
            e as Error,
          );
          throw e;
        }
      }
    },
    {
      networkMode: 'always',
      retry: false,
    },
  );

  // there is no big benefit in using useMutation here other than the execution
  // order when mounting and unmounting the component. useMutation seems to defer
  // the execution of the callback a little.
  const { mutate: clear } = useMutation(
    async () => {
      await taskQueue.addTask(ClearProtocolTask.create(protocolId), {
        awaitResult: true,
      });
    },
    {
      networkMode: 'always',
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['protocol-list']);
      },
    },
  );

  React.useEffect(() => {
    prepare();

    return () => {
      clear();
    };
  }, [protocolId]);

  usePageLifecycle(
    () => prepare(),
    () => {
      taskQueue.addTaskViaServiceWorker(ClearProtocolTask.create(protocolId));
    },
  );

  return {
    isLoading,
    store,
    error,
    prepareProtocol: prepare,
  } as const;
}
