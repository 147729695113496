import { Menu } from '@mui/material';
import React from 'react';

import { DropDownMenuItemElement } from './types';

interface Props {
  actions: DropDownMenuItemElement[];
  onClose: () => void;
  anchorEl: null | HTMLElement;
}

export const Dropdown = ({ onClose, anchorEl, actions }: Props) => {
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {actions.map((action) => {
        return React.cloneElement(action, {
          onClick: (e: React.MouseEvent<HTMLLIElement>) => {
            action.props.onClick?.(e);
            onClose();
          },
          close: onClose,
        });
      })}
    </Menu>
  );
};
