import { styled } from '@mui/material/styles';

import { LogoSvg } from '@/assets/svg';

export const ScreenContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  width: '100%',
}));

export const PageContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  minWidth: 600,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  padding: theme.spacing(4, 2),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4, 4),
  },
}));

export const StyledLogo = styled(LogoSvg)(({ theme }) => ({
  width: '100%',
  height: 100,
  padding: theme.spacing(2),
}));

export const MenuContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(3, 0),
  paddingLeft: 0,
}));

export const SideMenu = styled('div')(({ theme }) => ({
  width: 105,
  minWidth: 105,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { width: 60, minWidth: 60 },
}));

export const FooterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingBottom: theme.spacing(3),
}));
