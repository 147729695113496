import { styled } from '@mui/material/styles';

import { Dialog } from '@/common/ui/dialog';

export const WideDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    '.MuiPaper-root': {
      minWidth: '600px',
    },
  },
}));
