import LockPersonIcon from '@mui/icons-material/LockPerson';
import { Icon } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

import { ProtocolUserDto } from '@ibag/common';

import { LockedSvg } from '@/assets/svg/protocol-detail-status';
import { AuthUser } from '@/common/types/auth';

import { IconDescription } from './icon-description';

export const createLockStatus = (
  lockedBy: ProtocolUserDto | null,
  currentUser: AuthUser | null,
): IconDescription | null => {
  if (lockedBy === null) {
    return null;
  }

  const icon =
    currentUser?.id === lockedBy.id ? (
      <LockPersonIcon style={{ fill: grey[500] }} />
    ) : (
      <LockedSvg style={{ fill: grey[500] }} />
    );

  return {
    icon: <Icon>{icon}</Icon>,
    description: lockedBy.name,
  };
};
