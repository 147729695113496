import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';

import { SpaceBetweenRowContainer } from '@/common/ui/containers';
import { useEditable } from '@/pages/edit-protocol/common';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.rounded,
  boxShadow: 'none',
  overflow: 'unset',
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.background.secondaryPaper,
}));

const StyledCardHeader = styled(CardHeader)(() => ({
  paddingBottom: 0,
}));

const ActionRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactElement<{ disabled?: boolean }>[];
  overflowMenu?: React.ReactElement<{ disabled?: boolean }>;
}

export const ProtocolItem = ({
  title,
  children,
  actions,
  overflowMenu,
  ...props
}: Props & CardProps) => {
  const editable = useEditable();

  return (
    <StyledCard {...props}>
      <StyledCardHeader
        disableTypography={true}
        title={
          <ActionRow>
            <Typography style={{ whiteSpace: 'nowrap' }} variant={'subtitle1'}>
              {title}
            </Typography>
            <SpaceBetweenRowContainer>
              <div>
                {actions?.map((action) =>
                  React.cloneElement(action, {
                    disabled: !editable || action.props.disabled,
                  }),
                )}
              </div>
            </SpaceBetweenRowContainer>
          </ActionRow>
        }
        action={
          overflowMenu &&
          React.cloneElement(overflowMenu, {
            disabled: !editable || overflowMenu.props.disabled,
          })
        }
      />
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};
