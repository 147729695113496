import { createUseContextHook } from '@lumind-solutions/react-hooks/create-use-context-hook';
import { BaseSelection, LexicalEditor } from 'lexical';
import { createContext } from 'react';

export type Unsubscribe = () => void;

export type ToolbarUpdateListener = (
  editor: LexicalEditor,
  details: { selection: BaseSelection | null },
) => void;

export interface ToolbarContextApi {
  editor: LexicalEditor;
  subscribeUpdate: (listener: ToolbarUpdateListener) => Unsubscribe;
}

export const ToolbarContext = createContext<ToolbarContextApi | null>(null);

export const useToolbarApi = createUseContextHook(ToolbarContext);
