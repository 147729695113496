import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '@/common/ui/buttons';

import AddUserDialog from './AddUserDialog';

export const AddUserButton = ({
  ...props
}: React.ComponentProps<typeof SecondaryButton>) => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useDialog<void, void>();
  return (
    <>
      <SecondaryButton
        startIcon={<AddCircleIcon />}
        size={'medium'}
        onClick={() => open()}
        {...props}
      >
        {t('page.add_user.add_button')}
      </SecondaryButton>
      <AddUserDialog isDialogOpen={isOpen} onClose={close} />
    </>
  );
};
