import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, Menu, styled } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolStatus, nextStatus } from '@ibag/common';

import { BaseProtocol } from '@/common/types/protocol';
import { Confirm } from '@/common/ui/confirm-dialog';
import {
  BaseStore,
  useEditable,
  useProtocolState,
} from '@/pages/edit-protocol/common';
import { useChangeProtocolStatus } from '@/pages/edit-protocol/common/change-status-button/useChangeProtocolStatus';

import { ChangeStatusDropdownItem } from './ChangeStatusDropdownItem';

interface Props {
  className?: string;
}

const StyledMainButton = styled(Button)(() => ({
  fontSize: '0.8rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  minWidth: 0,
  flexGrow: 1,
}));

const StyledDropdownButton = styled(Button)(() => ({
  padding: '4px 6px',
  minWidth: 24,
}));

export const ChangeStatusButton = ({ className }: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const editable = useEditable();

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const proposedStatus = useProtocolState((s: BaseStore<BaseProtocol>) =>
    nextStatus(s.protocol.status),
  );

  const { changeStatus, isLoading, disabled } =
    useChangeProtocolStatus(proposedStatus);

  return (
    <React.Fragment>
      <ButtonGroup
        disabled={disabled || isLoading || !editable}
        className={className}
        color={'primary'}
        size={'small'}
        variant={'contained'}
        ref={setAnchorEl}
      >
        <Confirm
          onConfirm={changeStatus}
          title={t(
            `page.edit_protocol.actions.change_status.${proposedStatus.toLowerCase()}.confirm_title`,
          )}
          text={t(
            `page.edit_protocol.actions.change_status.${proposedStatus.toLowerCase()}.confirm_text`,
          )}
        >
          {(onPress) => (
            <StyledMainButton size={'small'} onClick={onPress}>
              {t(
                `page.edit_protocol.actions.change_status.${proposedStatus.toLowerCase()}.label`,
              )}
            </StyledMainButton>
          )}
        </Confirm>

        <StyledDropdownButton size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </StyledDropdownButton>
      </ButtonGroup>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleToggle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.values(ProtocolStatus).map((status) => (
          <ChangeStatusDropdownItem
            key={status}
            closeMenu={handleToggle}
            status={status}
          />
        ))}
      </Menu>
    </React.Fragment>
  );
};
