import { useEvent } from '@lumind-solutions/react-hooks/use-event';
import React from 'react';

import { useVisibilityChangeContext } from '@/common/providers/visibility-change-provider';

/**
 * More info on page lifecycle events: https://developer.chrome.com/blog/page-lifecycle-api
 * @param onVisible Called, when document becomes visible
 * @param onHidden Called, when document becomes hidden
 */
export function usePageLifecycle(onVisible: () => void, onHidden: () => void) {
  const stableOnVisible = useEvent(onVisible);
  const stableOnHidden = useEvent(onHidden);

  const { isVisibilityChangeListenerEnabled } = useVisibilityChangeContext();

  React.useEffect(() => {
    const listener = () => {
      if (!isVisibilityChangeListenerEnabled()) {
        return;
      }

      if (document.visibilityState === 'hidden') {
        stableOnHidden();
      } else if (document.visibilityState === 'visible') {
        stableOnVisible();
      }
    };
    document.addEventListener('visibilitychange', listener);

    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, [stableOnHidden, stableOnHidden]);
}
