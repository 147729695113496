import {
  Alert,
  Dialog,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  styled,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TemplateType } from '@ibag/common';

import { BaseProtocol } from '@/common/types/protocol';
import { Template } from '@/common/types/template';
import { OverflowActionMenu, PrimaryButton } from '@/common/ui/buttons';
import { UpdateTemplatesMenuItem } from '@/common/ui/text-editor/plugins/toolbar/buttons/insert-template/UpdateTemplatesMenuItem';
import { createErrorMessage } from '@/common/utils/errors';
import { BaseStore, useProtocolState } from '@/pages/edit-protocol/common';

import { useSearchTemplates } from './useSearchTemplates';

interface Props {
  open: boolean;
  onClose: (result: Template | undefined) => void;
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    height: '75vh',
    maxHeight: 1500,
    overflow: 'hidden',
  },
}));
const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  lineHeight: 'inherit',
  color: theme.palette.primary.main,
  fontWeight: 700,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

const TextBlocks = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

export const SmallFilterButton = styled(PrimaryButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 1.5),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  '& .MuiListItemText-secondary': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(2),
  },
}));

const StyledList = styled(List)(() => ({
  overflowY: 'auto',
}));

export const SearchDialog = ({ open, onClose }: Props) => {
  const [search, setSearch] = React.useState<string>('');

  const [templateFilter, setTemplateFilter] = useProtocolState(
    (s: BaseStore<BaseProtocol>) => [
      s.templateTypeFilter,
      s.setTemplateTypeFilter,
    ],
  );
  const { templates, error } = useSearchTemplates(search, templateFilter);
  const { t } = useTranslation();

  return (
    <StyledDialog fullWidth open={open} onClose={() => onClose(undefined)}>
      <StyledTextField
        variant={'standard'}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={t('editor.insert_template.dialog.search_placeholder')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <OverflowActionMenu
                overflowActions={[
                  <UpdateTemplatesMenuItem key={'update-templates'} />,
                ]}
              />
            </InputAdornment>
          ),
        }}
      />
      <TextBlocks>
        {[null, ...Object.values(TemplateType)].map((type) => {
          return (
            <SmallFilterButton
              size={'small'}
              key={type}
              color={type === templateFilter ? 'primary' : 'secondary'}
              onClick={() => {
                setTemplateFilter(type);
              }}
            >
              {type === null
                ? t('editor.insert_template.dialog.label_no_filter')
                : t(`common.template_type.${type}`)}
            </SmallFilterButton>
          );
        })}
      </TextBlocks>

      {templates?.length === 0 ? (
        <Alert color={'info'}>{t('editor.insert_template.no_results')}</Alert>
      ) : error ? (
        <Alert color={'error'}>{createErrorMessage(t, error)}</Alert>
      ) : (
        <StyledList>
          {templates !== undefined &&
            Object.keys(TemplateType).map((type, index) => {
              const subListItems = templates.filter(
                (template) => template.type === type,
              );
              return (
                <div key={index}>
                  {subListItems.length !== 0 && (
                    <>
                      <StyledListSubheader>
                        {t(`common.template_type.${type}`)}
                      </StyledListSubheader>
                      {subListItems.map((item) => {
                        return (
                          <StyledListItem
                            key={item.id}
                            onClick={() => onClose(item)}
                          >
                            <ListItemText
                              primary={item.title}
                              secondary={item.rawText}
                            />
                          </StyledListItem>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
        </StyledList>
      )}
    </StyledDialog>
  );
};
