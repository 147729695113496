import { Logger } from '@/services/logger';

export async function periodicBackgroundSync(tag: string, minInterval: number) {
  const registration: any = await navigator.serviceWorker.ready;

  Logger.instance.log('Check periodic sync', {
    supported: 'periodicSync' in registration,
  });
  if ('periodicSync' in registration) {
    // Request permission
    Logger.instance.log(
      'Requesting permission for periodic background sync...',
    );
    const status = await navigator.permissions.query({
      name: 'periodic-background-sync' as any,
    });
    Logger.instance.log(
      `Permission for periodic background sync ${status.state}`,
    );
    if (status.state === 'granted') {
      try {
        await registration.periodicSync.register(tag, {
          minInterval,
        });
        Logger.instance.log('Periodic background sync registered!');
      } catch (e) {
        Logger.instance.logError('Periodic background sync failed', e as Error);
      }
    }
  } else {
    Logger.instance.logWarning('Periodic background sync not supported');
  }
}
