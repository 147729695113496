import {
  ProtocolListDto,
  ProtocolListItemDto,
  ProtocolStatus,
  SortOrder,
} from '@ibag/common';

import { ProtocolList, ProtocolListItem } from '@/common/types/protocol';
import { HttpClient } from '@/services/http-client';

export class ListProtocolsService {
  constructor(private readonly authorizedHttpClient: HttpClient) {}

  async fetchProtocolListFromServer(
    page: number,
    pageSize: number,
    sortBy: 'id' | 'title' | 'status' | 'updatedAt' | 'revision',
    sortOrder: SortOrder,
    status?: ProtocolStatus,
    createdBy?: string,
    q?: string,
  ): Promise<ProtocolList> {
    const fixQueryParams = {
      page: page.toString(),
      pageSize: pageSize.toString(),
      sortBy: sortBy,
      sortOrder: sortOrder.toString(),
    };
    const dto = await this.authorizedHttpClient.jsonRequest<ProtocolListDto>(
      'GET',
      `/api/protocols`,
      {
        ...fixQueryParams,
        ...(status !== undefined && { status: status.toString() }),
        ...(createdBy !== undefined && { createdBy: createdBy }),
        ...(q !== undefined && { q: q }),
      },
    );
    return convertToProtocolList(dto);
  }
}

function convertToProtocolList(dto: ProtocolListDto): ProtocolList {
  return { ...dto, items: convertToProtocolListItems(dto.items) };
}

function convertToProtocolListItems(
  items: ProtocolListItemDto[],
): ProtocolListItem[] {
  return items.map((item) => ({
    id: item.id,
    title: item.title,
    status: ProtocolStatus[item.status],
    createdBy: item.createdBy,
    updatedAt: item.updatedAt,
    writtenBy: item.writtenBy,
    lockedBy: item.lockedBy,
  }));
}
