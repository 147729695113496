import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolStatus } from '@ibag/common';

import { PrimaryButton, SecondaryButton } from '@/common/ui/buttons';
import { useEditable, useProtocol } from '@/pages/edit-protocol/common';

import { useProposeEditProtocolDialog } from './useProposeEditProtocolDialog';

const StyledList = styled('ul')(({ theme }) => ({
  listStylePosition: 'inside',
  paddingLeft: theme.spacing(1),
}));

/**
 * Dialog proposing the user to perform recommended actions before editing the protocol.
 * - Change status to "In progress"
 * - Increase protocol number
 * - Lock protocol
 * - Make protocol offline available
 */
export const ProposeEditProtocolDialog = () => {
  const { t } = useTranslation();

  const protocol = useProtocol();

  const editable = useEditable();

  const shouldShowDialog =
    editable &&
    protocol.status === ProtocolStatus.READY_FOR_EDITING &&
    navigator.onLine;

  const [isOpen, setIsOpen] = React.useState(shouldShowDialog);
  const close = () => setIsOpen(false);
  const { applyActions, isLoading } = useProposeEditProtocolDialog(
    protocol,
    close,
  );

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>
        {t('page.edit_protocol.edit_protocol_dialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('page.edit_protocol.edit_protocol_dialog.text')}
        </DialogContentText>
        <StyledList>
          <li>{t('page.edit_protocol.edit_protocol_dialog.option_status')}</li>
          <li>
            {t(
              'page.edit_protocol.edit_protocol_dialog.option_protocol_number',
            )}
          </li>
          {protocol.lockedBy === null && (
            <li>{t('page.edit_protocol.edit_protocol_dialog.option_lock')}</li>
          )}
          {!protocol.offlineAvailable && (
            <li>
              {t('page.edit_protocol.edit_protocol_dialog.option_offline')}
            </li>
          )}
        </StyledList>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={close}>
          {t('page.edit_protocol.edit_protocol_dialog.cancel_btn')}
        </SecondaryButton>
        <PrimaryButton loading={isLoading} onClick={() => applyActions()}>
          {t('page.edit_protocol.edit_protocol_dialog.confirm_btn')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
