import { ProtocolHolder } from '../common';
import { Image } from '../common/image';
import { SigekoProtocol } from './sigeko-protocol';

export class SigekoProtocolHolder extends ProtocolHolder<SigekoProtocol> {
  constructor(protocol: SigekoProtocol) {
    super(protocol);
  }

  getImages(): Image[] {
    return this.protocol.content.defects.flatMap((defect) => defect.images);
  }
}
