import { styled } from '@mui/material';
import React from 'react';

interface Props<T extends { id: string }> {
  items: T[];
  ItemComponent: React.ComponentType<{ item: T }>;
}

const List = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
}));

export const ProtocolItemList = <T extends { id: string }>({
  items,
  ItemComponent,
}: Props<T>) => {
  return (
    <List>
      {items.map((item) => (
        <ItemComponent item={item} key={item.id} />
      ))}
    </List>
  );
};
