import { Alert } from '@mui/material';
import { AlertProps } from '@mui/material/Alert';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppError } from '@/common/types/errors';

interface Props {
  error: AppError | null;
}

export const ErrorMessage = ({
  error,
  className,
  ...alertProps
}: Props & AlertProps) => {
  const { t } = useTranslation();

  return error ? (
    <div className={className}>
      <Alert severity="error" {...alertProps}>
        {
          // 1. look for translation key
          // 2. fallback to error message when no translation found
          // 3. pass message as parameter to translation (1)
          t(`common.errors.${error.errorType}`, error.message, {
            message: error.message,
          })
        }
      </Alert>
    </div>
  ) : null;
};
