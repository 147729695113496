import { Contact } from '@/common/types/protocol';
import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

export const useAddContact = () => {
  const [contacts, updateHeaderField] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.contacts,
    s.updateHeaderField,
  ]);

  const addContact = (newContact: Contact) => {
    updateHeaderField('contacts', [...contacts, newContact]);
  };

  return { addContact };
};
