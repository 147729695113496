import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/common/providers/service-provider';

export function useUpdateTemplates() {
  const { updateTemplatesService } = useServices();

  return useQuery(
    ['update-template'],
    async () => {
      await updateTemplatesService.updateTemplatesWhenNecessary();
      return true; // we need to return something
    },
    {
      networkMode: 'always',
      staleTime: Infinity,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      cacheTime: Infinity,
    },
  );
}
