import {
  ErrorType,
  JSONObject,
  ProtocolDto,
  ProtocolMetadataDto,
  ProtocolStatus,
  ProtocolUserDto,
  SaveProtocolPayload,
} from '@ibag/common';

import { AppError } from '@/common/types/errors';
import { Protocol, createProtocolHolder } from '@/common/types/protocol';
import { convertDateToDateTimeString } from '@/common/utils/format-date';
import { createNanoId } from '@/common/utils/nanoid';
import {
  ClearProtocolTask,
  Database,
  MakeProtocolOfflineAvailableTask,
  PrepareProtocolTask,
  SaveProtocolTask,
  SyncProtocolTask,
} from '@/services/database';
import { HttpClient } from '@/services/http-client';
import { Logger } from '@/services/logger';
import { MessageService } from '@/services/messages';
import { ProtocolImageService } from '@/services/protocol';

import { ProtocolClonedError } from './protocol-cloned-error';

export class ProtocolService {
  constructor(
    private readonly authorizedHttpClient: HttpClient,
    private readonly database: Database,
    private readonly protocolImageService: ProtocolImageService,
    private readonly messageService: MessageService,
  ) {}

  /**
   * Syncs the protocol with the server, saving it if it has unsaved changes
   * or fetching it from the server if it is out of date.
   */
  async syncProtocol(protocolOrId: Protocol | string): Promise<Protocol> {
    Logger.instance.log(`Sync protocol ${protocolOrId}`);

    const protocol =
      typeof protocolOrId === 'string'
        ? await this.database.findProtocol(protocolOrId)
        : protocolOrId;

    if (!protocol)
      throw new AppError(
        ErrorType.NOT_FOUND,
        `Protocol ${protocolOrId} not found`,
      );

    // first, save the protocol if it has unsaved changes
    const savedProtocol = await this.saveProtocolIfChanged(protocol);
    if (savedProtocol) {
      return savedProtocol;
    }

    // Check for updates when protocol is not new
    if (protocol.revision !== 0) {
      try {
        const metadata = await this.fetchMetadataFromServer(protocol.id);
        if (metadata) {
          if (metadata.revision > protocol.revision) {
            return await this.fetchUpdatedProtocol(protocol);
          } else {
            // update lockedBy because this can change without the revision changing
            protocol.lockedBy = metadata.lockedBy;
            await this.database.saveProtocol(protocol);
          }
        }
      } catch (e) {
        if (
          e instanceof AppError &&
          e.errorType === ErrorType.PROTOCOL_DELETED
        ) {
          await this.database.deleteProtocol(protocol.id);
        }
        throw e;
      }
    }

    return protocol;
  }

  private async fetchUpdatedProtocol(protocol: Protocol) {
    const newerVersion = await this.fetchProtocolFromServer(protocol.id);
    newerVersion.offlineAvailable = protocol.offlineAvailable;
    await this.database.saveProtocol(newerVersion);
    if (protocol.offlineAvailable) {
      await this.cacheImages(newerVersion);
    }

    return newerVersion;
  }

  /**
   * Fetches the protocol metadata like revision, lock-status etc. from the server.
   * @param id
   */
  async fetchMetadataFromServer(
    id: string,
  ): Promise<ProtocolMetadataDto | null> {
    try {
      return this.authorizedHttpClient.jsonRequest<ProtocolMetadataDto>(
        'GET',
        `/api/protocols/${id}/meta`,
      );
    } catch (error: unknown) {
      if (
        error instanceof AppError &&
        error.errorType === ErrorType.NOT_FOUND
      ) {
        return null;
      }
      throw error;
    }
  }

  /**
   * Fetches the protocol from the server.
   * @param id
   */
  async fetchProtocolFromServer(id: string): Promise<Protocol> {
    const dto = await this.authorizedHttpClient.jsonRequest<ProtocolDto>(
      'GET',
      `/api/protocols/${id}`,
    );

    return convertToProtocol(dto);
  }

  /**
   * Fetches the protocol from the local database.
   * @param protocolId
   */
  fetchLocalProtocol(protocolId: string): Promise<Protocol | null> {
    return this.database.findProtocol(protocolId);
  }

  /**
   * Fetches the protocol from the server if it is not available locally.
   * Mark the protocol as offline available and caches all protocol images locally.
   * @param protocolId
   */
  async makeOfflineAvailable(protocolId: string): Promise<Protocol> {
    const protocol =
      (await this.fetchLocalProtocol(protocolId)) ??
      (await this.fetchProtocolFromServer(protocolId));

    if (protocol.offlineAvailable) {
      return protocol;
    }

    await this.cacheImages(protocol);

    protocol.offlineAvailable = true;
    await this.database.saveProtocol(protocol);
    return protocol;
  }

  private async cacheImages(protocol: Protocol): Promise<void> {
    const imageUrls = createProtocolHolder(protocol)
      .getImages()
      .map((image) => image.url);
    await this.protocolImageService.makeOfflineAvailable(imageUrls);
  }

  private async removeImagesFromCache(protocol: Protocol): Promise<void> {
    const imageUrls = createProtocolHolder(protocol)
      .getImages()
      .map((image) => image.url);
    await this.protocolImageService.clearOfflineAvailable(imageUrls);
  }

  /**
   * Fetches the protocol from the server and saves it locally.
   * Note that the protocol is not marked as offline available.
   * @param protocolId
   */
  async fetchAndSaveProtocol(protocolId: string): Promise<Protocol> {
    const protocol = await this.fetchProtocolFromServer(protocolId);
    await this.database.saveProtocol(protocol);
    return protocol;
  }

  /**
   * Saves the protocol to the server if it has unsaved changes.
   * @param protocolOrId
   */
  async saveProtocolIfChanged(
    protocolOrId: string | Protocol,
  ): Promise<Protocol | null> {
    const protocol =
      typeof protocolOrId === 'string'
        ? await this.fetchLocalProtocol(protocolOrId)
        : protocolOrId;

    if (!protocol) {
      throw new AppError(
        ErrorType.NOT_FOUND,
        `Protocol ${protocolOrId} not found`,
      );
    }

    // no changes to save
    if (protocol.localVersion === null) {
      return null;
    }

    try {
      return await this.saveAtServer(protocol);
    } catch (error: unknown) {
      if (
        error instanceof AppError &&
        error.errorType === ErrorType.PROTOCOL_REVISION_CONFLICT
      ) {
        // duplicate on conflict, so that user can decide which change should be kept
        const cloned = await this.cloneProtocol(protocol);
        await this.database.deleteProtocol(protocol.id);
        await this.messageService.addMessage({
          type: 'PROTOCOL_DUPLICATED',
          causeErrorType: error.errorType,
          clonedProtocolId: cloned.id,
          protocolTitle: protocol.title,
        });
        throw new ProtocolClonedError(error, protocol.id, cloned.id);
      } else if (
        error instanceof AppError &&
        error.errorType === ErrorType.PROTOCOL_LOCKED
      ) {
        // make offline available if protocol is locked and notify user
        if (!protocol.offlineAvailable) {
          protocol.offlineAvailable = true;
          await this.database.saveProtocol(protocol);
        }

        await this.messageService.addMessage({
          type: 'PROTOCOL_LOCKED',
          causeErrorType: error.errorType,
          protocolTitle: protocol.title,
        });
        throw error;
      } else if (
        error instanceof AppError &&
        error.errorType === ErrorType.PROTOCOL_DELETED
      ) {
        await this.database.deleteProtocol(protocol.id);
        await this.messageService.addMessage({
          type: 'PROTOCOL_DELETED',
          protocolTitle: protocol.title,
        });
        throw error;
      } else if (
        error instanceof AppError &&
        error.errorType !== ErrorType.NETWORK
      ) {
        // make offline available if other error occurs (except for network error)

        if (!protocol.offlineAvailable) {
          protocol.offlineAvailable = true;
          await this.database.saveProtocol(protocol);
        }

        throw error;
      }
      throw error;
    }
  }

  /**
   * Helper function that saves the protocol at the server and updates the
   * local protocol with new metadata.
   * Note: No error handling is done here.
   */
  private async saveAtServer(protocol: Protocol): Promise<Protocol> {
    Logger.instance.log(`Save protocol ${protocol.id} at server`);
    const payload: SaveProtocolPayload = {
      id: protocol.id,
      type: protocol.type,
      title: protocol.title,
      status: protocol.status,
      revision: protocol.revision,
      writtenBy: protocol.writtenBy?.id ?? null,
      header: protocol.header as unknown as JSONObject,
      content: protocol.content as unknown as JSONObject,
    };

    const serverProtocol = convertToProtocol(
      await this.authorizedHttpClient.jsonRequest<ProtocolDto>(
        'PUT',
        `/api/protocols/${protocol.id}`,
        undefined,
        payload,
      ),
    );

    return this.database.transaction(
      'rw?',
      this.database.protocols,
      async () => {
        const localProtocol = await this.fetchLocalProtocol(serverProtocol.id);
        if (!localProtocol) {
          throw new AppError(
            ErrorType.NOT_FOUND,
            `Protocol ${serverProtocol.id} not found`,
          );
        }

        localProtocol.revision = serverProtocol.revision;
        localProtocol.updatedAt = serverProtocol.updatedAt;
        localProtocol.status = serverProtocol.status;
        localProtocol.lockedBy = serverProtocol.lockedBy;
        localProtocol.localVersion =
          protocol.localVersion === localProtocol.localVersion
            ? null // reset local version if it was not changed during the save
            : localProtocol.localVersion; // keep local version to indicate that there are unsaved changes

        await this.database.saveProtocol(localProtocol);
        Logger.instance.log('updated protocol with server data', {
          localProtocol,
        });
        return localProtocol;
      },
    );
  }

  private async cloneProtocol(protocol: Protocol): Promise<Protocol> {
    const clone = {
      ...protocol,
      id: createNanoId(),
      lockedBy: null,
      offlineAvailable: true,
      localVersion: 1,
      revision: 0,
      title: `️⚠️ ${
        protocol.title
      } (Konfliktduplikat vom ${convertDateToDateTimeString()})`,
    };

    await this.database.saveProtocol(clone);
    return clone;
  }

  /**
   * Adds a temporary lock while a user is editing the protocol
   * @param protocolId
   * @param lock true to lock, false to unlock
   */
  async autoLockProtocol(protocolId: string, lock: boolean): Promise<void> {
    const protocol = await this.fetchLocalProtocol(protocolId);
    if (protocol && protocol.revision > 0 && protocol.lockedBy === null) {
      await this.authorizedHttpClient.jsonRequest(
        'PUT',
        `/api/protocols/${protocolId}/auto-lock`,
        undefined,
        { lock },
      );
    }
  }

  async lockExplicitly(protocolId: string, lock: boolean): Promise<void> {
    const protocolUserDto =
      await this.authorizedHttpClient.jsonRequest<ProtocolUserDto>(
        'PUT',
        `/api/protocols/${protocolId}/lock`,
        undefined,
        { lock },
      );

    const protocol = await this.fetchLocalProtocol(protocolId);
    if (protocol) {
      protocol.lockedBy = lock ? protocolUserDto : null;
      await this.database.saveProtocol(protocol);
    }
  }

  /**
   * Fetches and prepares a protocol for editing.
   * - Syncs the protocol with the server
   * - Auto-locks, when not explicitly locked.
   * @param protocolId
   */
  async prepareProtocol(protocolId: string): Promise<Protocol> {
    let protocol = await this.fetchLocalProtocol(protocolId);
    if (protocol && protocol.revision > 0) {
      protocol = await this.syncProtocol(protocol);
    } else if (protocol === null) {
      protocol = await this.fetchAndSaveProtocol(protocolId);
    }

    await this.autoLockProtocol(protocolId, true);
    return protocol;
  }

  /**
   * Cleans up the protocol after editing.
   * - Saves the protocol if it has unsaved changes.
   * - (Auto)-Unlocks the protocol if not explicitly locked.
   * - Deletes the local protocol, if not offline-available.
   * @param protocolId
   */
  async clearProtocol(protocolId: string): Promise<void> {
    await this.saveProtocolIfChanged(protocolId);
    const protocol = await this.fetchLocalProtocol(protocolId);

    await this.autoLockProtocol(protocolId, false);

    if (protocol?.offlineAvailable === false) {
      await this.database.deleteProtocol(protocolId);
      Logger.instance.log(`deleted local protocol ${protocolId}`);
    }
  }

  /**
   * Removes an offline-available protocol.
   * - Clears pending tasks
   * - Clear image cache
   * - Deletes the protocol
   * @param protocolId
   */
  async clearOfflineAvailable(protocolId: string) {
    Logger.instance.log(`clear offline available protocol ${protocolId}`);
    const protocol = await this.fetchLocalProtocol(protocolId);
    if (!protocol || !protocol.offlineAvailable) {
      return;
    }

    // clear tasks that would fail when protocol is not locally available
    await this.database.deleteTasksByGroupAndTypes(protocolId, [
      SaveProtocolTask.type,
      PrepareProtocolTask.type,
      ClearProtocolTask.type,
      MakeProtocolOfflineAvailableTask.type,
      SyncProtocolTask.type,
    ]);

    await this.removeImagesFromCache(protocol);
    await this.database.deleteProtocol(protocolId);
  }

  async deleteProtocol(protocolId: string): Promise<void> {
    await this.authorizedHttpClient.jsonRequest(
      'DELETE',
      `/api/protocols/${protocolId}`,
    );
    await this.database.deleteProtocol(protocolId);
  }

  /**
   * Change the status of a protocol and save it.
   */
  async changeStatus(
    protocolId: string,
    status: ProtocolStatus,
  ): Promise<Protocol> {
    try {
      const protocol = await this.fetchLocalProtocol(protocolId);
      if (!protocol) {
        // noinspection ExceptionCaughtLocallyJS
        throw new AppError(
          ErrorType.NOT_FOUND,
          `Protocol ${protocolId} not found`,
        );
      }

      protocol.status = status;
      if (status === ProtocolStatus.ARCHIVED) {
        protocol.offlineAvailable = false;
        await this.database.saveProtocol(protocol);
      }

      const savedProtocol = await this.saveAtServer(protocol);
      Logger.instance.log(
        `Change status of protocol ${protocolId} to ${status}`,
      );
      return savedProtocol;
    } catch (error) {
      Logger.instance.logError(
        `Error changing status of protocol ${protocolId} to ${status}`,
        error as Error,
      );
      throw error;
    }
  }
}

function convertToProtocol(dto: ProtocolDto): Protocol {
  return {
    ...dto,
    header: dto.header as unknown as Protocol['header'],
    content: dto.content as unknown as Protocol['content'],
    offlineAvailable: false,
    localVersion: null,
  };
}
