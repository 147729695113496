import '@fontsource/lato/latin-400-italic.css';
import '@fontsource/lato/latin-400.css';
import '@fontsource/lato/latin-700-italic.css';
import '@fontsource/lato/latin-700.css';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const breakpoint = 600; //Tablet 1.920 x 1.200 (WUXGA) https://www.samsung.com/de/tablets/others/galaxy-tab-active4-pro-5g-black-128gb-sm-t636bzkeeeb/
export const typography: TypographyOptions = {
  fontFamily: ['Lato', 'sans-serif'].join(','),
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  h1: {
    fontSize: '2.5rem',
    fontWeight: 700,
    [`@media (max-width:${breakpoint}px)`]: {
      fontSize: '1.5rem',
    },
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 500,
    [`@media (max-width:${breakpoint}px)`]: {
      fontSize: '1rem',
    },
  },
  h5: {
    fontSize: '1.1rem',
    fontWeight: 700,
    [`@media (max-width:${breakpoint}px)`]: {
      fontSize: '1rem',
    },
  },
  h6: {
    fontSize: '0.9rem',
    [`@media (max-width:${breakpoint}px)`]: {
      fontSize: '0.5rem',
    },
  },
};
