import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface Props {
  tooltipDescription: string | undefined;
  icon: React.ReactElement | undefined;
}

export const LockStatusIcon = ({ icon, tooltipDescription }: Props) => {
  return (
    <>
      {icon && (
        <Tooltip
          title={tooltipDescription ?? ''}
          enterTouchDelay={0}
          onClick={(e) => e.stopPropagation()}
        >
          <IconButton>{icon}</IconButton>
        </Tooltip>
      )}
    </>
  );
};
