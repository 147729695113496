import { createUseContextHook } from '@lumind-solutions/react-hooks/create-use-context-hook';
import React from 'react';

import { AuthService } from '@/services/auth';
import { Database } from '@/services/database';
import { HttpClient } from '@/services/http-client';
import { MessageService } from '@/services/messages';
import {
  PrintProtocolService,
  ProtocolImageService,
  ProtocolService,
  SyncProtocolsService,
} from '@/services/protocol';
import { ListProtocolsService } from '@/services/protocol/list-protocols';
import { ServiceWorkerRegistrationService } from '@/services/service-worker-registration';
import { TaskQueue } from '@/services/task-queue';
import { TemplateService, UpdateTemplatesService } from '@/services/template';
import { UserService } from '@/services/user';

export interface Services {
  authService: AuthService;
  authorizedHttpClient: HttpClient;
  userService: UserService;
  database: Database;
  protocolService: ProtocolService;
  protocolImageService: ProtocolImageService;
  printProtocolService: PrintProtocolService;
  taskQueue: TaskQueue;
  listProtocolService: ListProtocolsService;
  syncProtocolsService: SyncProtocolsService;
  templateService: TemplateService;
  updateTemplatesService: UpdateTemplatesService;
  messageService: MessageService;
  serviceWorkerRegistrationService: ServiceWorkerRegistrationService;
}

interface Props {
  children: React.ReactNode;
  services: Services;
}

const ServiceContext = React.createContext<Services | null>(null);
ServiceContext.displayName = 'ServiceContext';

export const ServiceProvider = ({ children, services }: Props) => {
  return (
    <ServiceContext.Provider value={services}>
      {children}
    </ServiceContext.Provider>
  );
};

export const useServices = createUseContextHook(ServiceContext);
