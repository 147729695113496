import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WideDialog } from '@/common/styles';
import { Contact } from '@/common/types/protocol';
import { PrimaryButton, SecondaryButton } from '@/common/ui/buttons';
import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

import { UpsertContactForm } from '../UpsertContactForm';
import { useEditContact } from './useEditContact';

const DialogContent = styled('div')(({ theme }) => ({
  margin: theme.spacing(5, 0),
  display: 'flex',
  flexDirection: 'column',
}));

interface Props {
  isDialogOpen: boolean;
  onClose: () => void;
  contactToEdit: Contact;
}

export const EditContactDialog = ({
  contactToEdit,
  isDialogOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const { editContact } = useEditContact(contactToEdit.id);

  const [variant] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.variant,
  ]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm<Contact>({});

  //get all data to edit
  useEffect(() => {
    reset({ ...contactToEdit });
  }, [reset, contactToEdit]);

  // add a new Contact
  const onSubmit: SubmitHandler<Contact> = (changedContact) => {
    editContact(changedContact);
    reset();
    onClose();
  };

  return (
    <WideDialog
      isOpen={isDialogOpen}
      onClose={onClose}
      title={t(
        `page.edit_protocol.${variant}.header.inputs.edit_contact_dialog.title`,
      )}
      contentPadding={3}
      actions={() => {
        return (
          <>
            <SecondaryButton onClick={onClose}>
              {t(
                `page.edit_protocol.${variant}.header.inputs.edit_contact_dialog.cancel_button`,
              )}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmit(onSubmit)}>
              {t(
                `page.edit_protocol.${variant}.header.inputs.edit_contact_dialog.submit_button`,
              )}
            </PrimaryButton>
          </>
        );
      }}
    >
      <DialogContent>
        <UpsertContactForm control={control} formErrors={formErrors} />
      </DialogContent>
    </WideDialog>
  );
};
