import { Alert, AlertTitle, Button, Slide, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';

interface Props {
  id: number;
  title: React.ReactNode;
  children: React.ReactNode;
}

const ActionRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

export const BaseMessageCard = ({ title, id, children }: Props) => {
  const { t } = useTranslation();
  const { messageService } = useServices();

  const [visible, setVisible] = React.useState<boolean>(true);

  const dismiss = () => {
    messageService.deleteMessage(id);
  };

  return (
    <Slide
      direction={'left'}
      in={visible}
      mountOnEnter
      unmountOnExit
      onExited={dismiss}
    >
      <Alert severity="error">
        <AlertTitle>{title}</AlertTitle>
        {children}
        <ActionRow>
          <Button
            color="inherit"
            size="small"
            onClick={() => setVisible(false)}
          >
            {t('common.actions.ok')}
          </Button>
        </ActionRow>
      </Alert>
    </Slide>
  );
};
