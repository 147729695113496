import { ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { BaseButton } from './BaseButton';

interface Props {
  startIcon: React.ReactElement;
  label?: string;
}

const StyledBaseButton = styled(BaseButton)(({ theme }) => ({
  fontWeight: 700,
  textTransform: 'none',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export const ActionButton = ({
  startIcon,
  label,
  ...props
}: Props & ButtonProps) => {
  return (
    <>
      <StyledBaseButton
        size={'small'}
        startIcon={startIcon}
        variant={'text'}
        {...props}
      >
        {label}
      </StyledBaseButton>
    </>
  );
};
