import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { PrimaryButton, SecondaryButton } from '@/common/ui/buttons';
import { DropDownMenuItemElement, Dropdown } from '@/common/ui/dropdown';

const StyledSecondaryButton = styled(SecondaryButton)(() => ({
  '&.MuiButton-root': {
    justifyContent: 'space-between',
  },
}));

interface Props {
  name: string;
  actions: DropDownMenuItemElement[];
}

export const BaseSelect = ({
  name,
  actions,
  ...props
}: Props & React.ComponentProps<typeof PrimaryButton>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <StyledSecondaryButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        {...props}
        endIcon={<ArrowDropDownIcon />}
      >
        {name}
      </StyledSecondaryButton>

      <Dropdown onClose={handleClose} anchorEl={anchorEl} actions={actions} />
    </>
  );
};
