import { AppBar, Toolbar } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const ActionBar = (props: Props) => {
  return (
    <AppBar position={'static'} elevation={0}>
      <Toolbar>{props.children}</Toolbar>
    </AppBar>
  );
};
