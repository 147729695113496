import { styled } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import React from 'react';

import { ProtocolType } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { SigekoProtocol } from '@/common/types/protocol';

import { ProtocolCard, SigekoCardContent } from './cards';

const Cards = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  maxHeight: 450,
  flexWrap: 'wrap',
  overflow: 'auto',
}));

const Container = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}));

const FadeOut = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 200,
  height: '100%',
  background:
    'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,118,188,0.4374343487394958) 57%, rgba(0,118,188,1) 100%)',
}));

export const OfflineAvailableProtocolCards = () => {
  const { database } = useServices();

  const offlineProtocols = useLiveQuery(() => database.fetchOfflineProtocols());
  return (
    <Container>
      {offlineProtocols && (
        <Cards>
          {offlineProtocols?.map((cardContent, index) => {
            if (cardContent.type === ProtocolType.SIGEKO) {
              return (
                <ProtocolCard
                  protocol={convertSigekoProtocolToSigekoCardContent(
                    cardContent,
                  )}
                  key={index}
                />
              );
            }
            console.log('DEVELOPER ERROR: UNKNOWN_PROTOCOL_TYPE', cardContent);
            return null;
          })}
        </Cards>
      )}
      <FadeOut />
    </Container>
  );
};

const convertSigekoProtocolToSigekoCardContent = (
  protocol: SigekoProtocol,
): SigekoCardContent => ({
  id: protocol.id,
  type: ProtocolType.SIGEKO,
  date: protocol.updatedAt,
  location: protocol.header.location ?? '',
  title: protocol.title,
  protocolNumber: protocol.header.protocolNumber,
  writtenBy: protocol.createdBy.name,
  status: protocol.status,
  isSynced: protocol.localVersion === null,
  lockedBy: protocol.lockedBy ?? null,
});
