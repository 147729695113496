import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ProtocolStatus } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { BaseProtocol } from '@/common/types/protocol';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';
import { BaseStore, useProtocolState } from '@/pages/edit-protocol/common';
import { ChangeStatusTask } from '@/services/database';

export function useChangeProtocolStatus(
  status: ProtocolStatus,
  onCompleted?: () => void,
) {
  const { t } = useTranslation();
  const replaceProtocol = useProtocolState(
    (s: BaseStore<BaseProtocol>) => s.replaceProtocol,
  );

  const [currentStatus, protocolId] = useProtocolState(
    (s: BaseStore<BaseProtocol>) => [s.protocol.status, s.protocol.id],
  );

  const { showSnackbar } = useSnackbar();
  const { taskQueue, protocolService } = useServices();

  const { mutate, isLoading } = useMutation<void, AppError, void>(
    async () => {
      await taskQueue.addTask(ChangeStatusTask.create(protocolId, status), {
        awaitResult: true,
      });
    },
    {
      onSuccess: async () => {
        const protocol = await protocolService.fetchLocalProtocol(protocolId);
        if (protocol) {
          replaceProtocol(protocol);
          showSnackbar({
            variant: 'success',
            content: t(
              'page.edit_protocol.actions.change_status.success_message',
              {
                status: t(`common.protocol_status.${protocol.status}`),
              },
            ),
          });
          onCompleted?.();
        }
      },
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
        onCompleted?.();
      },
    },
  );

  return {
    changeStatus: mutate,
    isLoading,
    disabled: currentStatus === status,
  } as const;
}
