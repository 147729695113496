import { SyncProtocolsService } from '@/services/protocol';

import { database } from './database-instance';
import { protocolService } from './protocol-service-instance';
import { taskQueue } from './task-queue-instance';

export const syncProtocolsService = new SyncProtocolsService(
  protocolService,
  taskQueue,
  database,
);
