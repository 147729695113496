import { CheckCircle } from '@mui/icons-material';
import React from 'react';

import { ToolbarIconButton } from '../ToolbarIconButton';

interface Props {
  onComplete: () => void;
}

export const CompleteButton = ({ onComplete }: Props) => {
  return (
    <ToolbarIconButton onClick={onComplete}>
      <CheckCircle />
    </ToolbarIconButton>
  );
};
