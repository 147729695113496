import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';
import {
  Database,
  DatabaseImage,
  generateServerImageUrl,
  isServerImageUrl,
} from '@/services/database';
import { HttpClient } from '@/services/http-client';
import { UploadImageResult } from '@/services/protocol/upload-image-result';

export class ProtocolImageService {
  private _imageCache: Promise<Cache> | undefined;

  constructor(
    private readonly authorizedHttpClient: HttpClient,
    private readonly database: Database,
  ) {}

  /**
   * Uploads all images from the local database to the server.
   */
  async uploadLocalImages() {
    const images = await this.database.fetchImages();
    const pending = images.map((image) => this.tryUploadImage(image));
    return Promise.all(pending);
  }

  /**
   * Uploads an image from the local database to the server.
   * On success the image is added to service worker cache (so it is still offline available)
   * and removed from the local database.
   * @param image
   * @private
   */
  private async tryUploadImage(
    image: DatabaseImage,
  ): Promise<UploadImageResult> {
    try {
      console.log('upload image', image.id);
      await this.saveOnServer(image);
      await this.cacheFile(image);
      // image is on the server and in the cache, so we can remove it from the local database
      await this.database.deleteImage(image.id);

      return {
        imageId: image.id,
        success: true,
      };
    } catch (error: unknown) {
      console.error(`error uploading image ${image.id}`, error);
      return {
        imageId: image.id,
        success: false,
      };
    }
  }

  /*
   * Adds images it to the service worker cache, if they are not already cached.
   */
  public async makeOfflineAvailable(images: string[]) {
    try {
      const cache = await this.openCache();
      const pending = images.map(async (url) => {
        const match = await cache.match(url);
        if (!match) {
          const request: RequestInfo = isServerImageUrl(url)
            ? new Request(url, {
                method: 'GET',
                headers: await this.authorizedHttpClient.authorizeRequest(),
              })
            : url;

          await cache.add(request);
        }
        return undefined;
      });
      await Promise.all(pending);
    } catch (error: unknown) {
      console.error('error making images offline available', error);
      throw new AppError(
        ErrorType.FAILED_IMAGE_CACHING,
        'Failed to cache images',
      );
    }
  }

  public async clearOfflineAvailable(images: string[]) {
    const cache = await this.openCache();
    for (const url of images) {
      try {
        await cache.delete(url);
      } catch (e) {
        console.warn('error deleting image from cache', e);
      }
    }
  }

  private async saveOnServer(image: DatabaseImage) {
    const formData = new FormData();
    formData.set('id', image.id);
    formData.append('file', image.image, image.image.name);

    await this.authorizedHttpClient.formRequest(
      'POST',
      '/api/images',
      undefined,
      formData,
    );
  }

  private async cacheFile(image: DatabaseImage) {
    const cache = await this.openCache();

    await cache.put(
      generateServerImageUrl(image),
      new Response(image.image, {
        headers: { 'Content-Type': image.image.type },
      }),
    );
  }

  private openCache() {
    if (!this._imageCache) {
      this._imageCache = caches.open('images');
    }
    return this._imageCache;
  }
}
