import { compact } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppError } from '@/common/types/errors';
import { Dropzone } from '@/common/ui/dropzone';
import { ErrorMessage } from '@/common/ui/error-message';

import { useUploadTemplate } from './useUploadTemplate';

export const DocxDropzone = ({ ...props }) => {
  const { t } = useTranslation();
  const [uploadErrors, setUploadErrors] = useState<AppError[]>([]);
  const { uploadTemplates, isLoading, uploadFailures } = useUploadTemplate();
  return (
    <>
      <Dropzone
        isLoading={isLoading}
        dragActiveText={t('page.template.drop_zone_upload.drag_active_text')}
        dragNotActiveText={t(
          'page.template.drop_zone_upload.drag_not_active_text',
        )}
        buttonText={t('page.template.drop_zone_upload.button')}
        onError={setUploadErrors}
        accepted={{
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            ['.docx'],
        }}
        onDrop={(blobFiles) => {
          setUploadErrors([]);
          uploadTemplates(blobFiles);
        }}
        {...props}
      />
      {compact([...uploadErrors, ...uploadFailures]).map((error) => (
        <ErrorMessage key={error.message} error={error} />
      ))}
    </>
  );
};
