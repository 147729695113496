import {
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  SvgIconProps,
  styled,
} from '@mui/material';
import React from 'react';

import { LoadingIcon } from '@/common/ui/dropdown';

interface Props {
  loading?: boolean;
  icon: React.ReactElement<SvgIconProps>;
  children: React.ReactNode;
  disabled?: boolean;
}

const StyledListItem = styled(ListItemButton)(() => ({
  whiteSpace: 'normal',
}));

const StyledListItemText = styled(ListItemText)(() => ({
  '& > span': { fontSize: '0.8rem' },
}));

const StyledIcon = styled(LoadingIcon)(({ theme }) => ({
  '&&.MuiListItemIcon-root': {
    minWidth: 24,
  },
  marginRight: theme.spacing(0.5),
}));

export const SideMenuButton = ({
  icon,
  children,
  loading,
  ...props
}: Props & ListItemButtonProps) => {
  return (
    <StyledListItem divider {...props}>
      <StyledIcon isLoading={loading}>{icon}</StyledIcon>
      <StyledListItemText>{children}</StyledListItemText>
    </StyledListItem>
  );
};
