import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateUserPayload, UserRole } from '@ibag/common';

import { AppError } from '@/common/types/errors';
import { FormContainer } from '@/common/ui/containers';
import { ErrorMessage } from '@/common/ui/error-message';
import { regex } from '@/common/utils/regex';

interface Props {
  control: Control<CreateUserPayload>;
  formErrors: FieldErrors<CreateUserPayload>;
  addUserError: AppError | null;
}

export const UpsertUserForm = ({
  control,
  formErrors,
  addUserError,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormContainer>
      <FormControl>
        <FormLabel>{t('page.add_user.form.role_label')}</FormLabel>
        <Controller
          control={control}
          name={'role'}
          rules={{
            required: {
              value: true,
              message: t('common.validation.required'),
            },
          }}
          render={({ field }) => (
            <RadioGroup row {...field}>
              {Object.values(UserRole)
                .reverse()
                .map((role) => {
                  return (
                    <FormControlLabel
                      key={role}
                      value={role}
                      control={<Radio />}
                      label={t(`page.add_user.form.roles.${role}`)}
                    />
                  );
                })}
            </RadioGroup>
          )}
        />
      </FormControl>

      <Controller
        control={control}
        name={'firstName'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            autoCapitalize={'words'}
            type={'text'}
            label={t('page.add_user.form.first_name_label')}
            autoComplete={'name'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={'lastName'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            autoCapitalize={'words'}
            type={'text'}
            label={t('page.add_user.form.last_name_label')}
            autoComplete={'family-name'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={'email'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
          pattern: {
            value: regex.email,
            message: t('common.validation.email'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            autoCapitalize={'none'}
            type={'email'}
            label={t('page.add_user.form.email_label')}
            autoComplete={'email'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={!!formErrors.email}
            helperText={formErrors.email?.message}
          />
        )}
      />

      <ErrorMessage error={addUserError} />
    </FormContainer>
  );
};
