import { Link, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SigekoProtocolDefect,
  formatDefectNumber,
} from '@/common/types/protocol';
import { useProtocolState } from '@/pages/edit-protocol/common';

import { SigekoStore } from '../sigeko-store';

const DefectContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(theme.spacing(0.5), 0),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  fontSize: '0.8em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(0.3, 0),
}));

const DefektLink = styled(StyledLink, {
  shouldForwardProp: (prop) => prop !== 'isDone',
})<{ isDone: boolean }>(({ theme, isDone }) => ({
  display: 'block',
  color: `${isDone ? theme.palette.tertiary.main : grey[800]}`,
}));

const UpdateLink = styled(StyledLink)(({ theme }) => ({
  display: 'block',
  color: grey[600],
  textDecoration: 'none',
  marginLeft: theme.spacing(1),
}));

interface Props {
  defects: SigekoProtocolDefect[];
}

export const TableOfContent = ({ defects }: Props) => {
  const { t } = useTranslation();
  const [variant] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.variant,
  ]);

  function formatDefectName(defect: SigekoProtocolDefect) {
    const defectNumber = formatDefectNumber(defect.defectNumber);

    return (
      defectNumber +
      ': ' +
      (defect.title?.trim() ||
        t('page.edit_protocol.side_menu.table_of_content.no_title'))
    );
  }

  return (
    <>
      <StyledLink href={'#protocol-header'} variant={'h6'}>
        {t(`page.edit_protocol.${variant}.header.title`)}
      </StyledLink>
      <StyledLink
        href={`#${t(`page.edit_protocol.content.start_text`)}`}
        variant={'h6'}
      >
        {t(`page.edit_protocol.content.start_text`)}
      </StyledLink>
      {defects.length > 0 && (
        <DefectContainer>
          {defects.map((defect) => {
            return (
              <React.Fragment key={defect.id}>
                <DefektLink
                  isDone={defect.done !== null}
                  href={`#${defect.id}`}
                  variant={'h6'}
                >
                  {formatDefectName(defect)}
                </DefektLink>
                {defect.updates.map((update) => {
                  return (
                    <UpdateLink
                      key={update.id}
                      href={`#${update.id}`}
                      color="inherit"
                      variant={'h6'}
                    >
                      {update.title}
                    </UpdateLink>
                  );
                })}
              </React.Fragment>
            );
          })}
        </DefectContainer>
      )}
      <StyledLink
        href={`#${t(`page.edit_protocol.content.end_text`)}`}
        variant={'h6'}
      >
        {t(`page.edit_protocol.content.end_text`)}
      </StyledLink>
    </>
  );
};
