import { LexicalContent } from '@/common/types/protocol';

export const convertTextToLexicalContent = (text: string): LexicalContent => {
  return {
    root: {
      type: 'root',
      format: '',
      indent: 0,
      version: 1,
      children: [
        {
          type: 'heading',
          tag: 'h3',
          format: '',
          indent: 0,
          version: 1,
          children: [
            {
              mode: 'normal',
              text: `${text}`,
              type: 'text',
              style: '',
              detail: 0,
              format: 0,
              version: 1,
            },
          ],
          direction: 'ltr',
        },
      ],
      direction: 'ltr',
    },
  };
};
