import { Icon, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

import { NotSyncedSvg, SyncedSvg } from '@/assets/svg/protocol-detail-status';

interface Props {
  status: boolean;
}

export const SyncStatusIcon = ({ status }: Props) => {
  const theme = useTheme();
  return (
    <Icon style={{ fill: status ? grey[500] : theme.palette.error.main }}>
      {status ? <SyncedSvg /> : <NotSyncedSvg />}
    </Icon>
  );
};
