import { Image } from '@/common/types/protocol';

export type HtmlContent = { html: string };
export type LexicalContent = { root: any };

export type EditorContentState = {
  lexicalContent: LexicalContent | null;
  title: string;
};

// Data structure holding content of a RichTextField-Component
export interface RichText {
  // Serialized Lexical Content
  text: LexicalContent | null;

  // first line of editor Text
  title: string | null;

  // Images related to the text
  images: Image[];
}

/**
 * RichTexts can occur deeply nested in the state tree.
 * Using a selector function you can provide the 'path' to the rich-text in the tree
 *
 * Example:
 * (s: SigekoProtocolState) => s.content.defects.find(byId('abc123'));
 */
export type RichTextSelector<S> = (state: S) => RichText | undefined;

/*
 Helper function to select an object from an array by id

 Example:
 const items = [{id: 88}, {id: 99}];
 items.find(byId(99));
 */
export function byId<I, T extends { id: I }>(id: I) {
  return (item: T) => item.id === id;
}
