export interface Image {
  id: string;
  size: ImageSize;
  url: string;
}

export enum ImageSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
