import React from 'react';

import { RichText } from '@/common/types/protocol';

import { ProtocolItem, RichTextField, useEditable } from '../common';
import { SigekoRichTextSelector } from './sigeko-store';

interface Props {
  label: string;
  item: RichText;
  stateSelector: SigekoRichTextSelector;
}

export const SigekoProtocolDefaultTextField = ({
  label,
  stateSelector,
  item: { text, images },
}: Props) => {
  const editable = useEditable();
  return (
    <ProtocolItem title={label}>
      <RichTextField
        anchorId={label}
        stateSelector={stateSelector}
        initialContent={text}
        images={images}
        editable={editable}
      />
    </ProtocolItem>
  );
};
