import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  COMMAND_PRIORITY_CRITICAL,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';

import { ToolbarDivider } from './ToolbarDivider';
import {
  BlockTypesMenu,
  CompleteButton,
  HistoryButtons,
  ImageButton,
  InsertTemplateButton,
  TextStylesButtonGroup,
} from './buttons';
import { ToolbarContext, ToolbarUpdateListener } from './context';
import { Spacer, StyledToolbar, StyledToolbarPaper } from './styles';

interface Props {
  onDeactivate?: () => void;
  onImagesSelected: (images: FileList) => void;
}

export const ToolbarPlugin = ({ onDeactivate, onImagesSelected }: Props) => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);

  const listeners = React.useRef<ToolbarUpdateListener[]>([]);

  const updateToolbar = useCallback((editor: LexicalEditor) => {
    const selection = $getSelection();

    listeners.current.forEach((listener) => listener(editor, { selection }));
  }, []);

  const subscribeUpdate = React.useCallback(
    (listener: ToolbarUpdateListener) => {
      listeners.current.push(listener);

      return () => {
        const idx = listeners.current.indexOf(listener);
        if (idx >= 0) {
          listeners.current.splice(idx, 1);
        }
      };
    },
    [],
  );

  /* Registering a command to the editor. */
  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar(newEditor);
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  /* Registering an update listener to the editor. */
  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar(activeEditor);
        });
      }),
    );
  }, [activeEditor, updateToolbar]);

  return (
    <ToolbarContext.Provider value={{ editor, subscribeUpdate }}>
      <StyledToolbar>
        <StyledToolbarPaper elevation={0}>
          <HistoryButtons />
          <ToolbarDivider />
          <BlockTypesMenu />
          <ToolbarDivider />
          <TextStylesButtonGroup />
          <ToolbarDivider />
          <InsertTemplateButton />
          <ImageButton onImagesSelected={onImagesSelected} />
          <Spacer />
          {onDeactivate && <CompleteButton onComplete={onDeactivate} />}
        </StyledToolbarPaper>
      </StyledToolbar>
    </ToolbarContext.Provider>
  );
};
