import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolUserDto } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';
import { LockProtocolTask } from '@/services/database';

export function useLockProtocol(
  protocolId: string,
  lockedBy: ProtocolUserDto | null,
  onSuccess?: () => void,
  onError?: () => void,
) {
  const { taskQueue, authService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const locked = !!lockedBy;
  const canLock = lockedBy === null;
  const canUnlock = lockedBy?.id === authService.user?.id;

  const { mutate, isLoading } = useMutation<boolean, AppError, boolean>(
    async (lock) => {
      await taskQueue.addTask(LockProtocolTask.create(protocolId, lock), {
        awaitResult: true,
      });
      return lock;
    },
    {
      networkMode: 'always',
      onSuccess: (locked) => {
        showSnackbar({
          variant: 'success',
          content: t(
            locked
              ? 'common.messages.protocol_locked'
              : 'common.messages.protocol_unlocked',
          ),
        });
        onSuccess?.();
      },
      onError: (err) => {
        showSnackbar({ variant: 'error', content: createErrorMessage(t, err) });
        onError?.();
      },
    },
  );

  const lock = React.useCallback(() => mutate(true), [mutate]);
  const unlock = React.useCallback(() => mutate(false), [mutate]);

  return { locked, canLock, canUnlock, lock, unlock, isLoading } as const;
}
