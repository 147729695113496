import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';
import { Database, NetworkMode, SyncProtocolTask } from '@/services/database';
import { periodicBackgroundSync } from '@/services/periodic-background-sync';
import { ProtocolService } from '@/services/protocol';
import { TaskQueue } from '@/services/task-queue';

export class SyncProtocolsService {
  constructor(
    private readonly protocolService: ProtocolService,
    private readonly taskQueue: TaskQueue,
    private readonly database: Database,
  ) {}

  /**
   * Adds sync tasks to queue for every offline available or unsynced protocol
   */
  async syncProtocols() {
    const protocols = await this.database.fetchProtocolsToSync();

    const promises = protocols.map(async (protocol) => {
      try {
        await this.taskQueue.addTask(
          SyncProtocolTask.create(protocol.id, NetworkMode.ONLINE),
          { awaitResult: true },
        );
      } catch (e) {
        // deleted protocols should not cause error
        if (
          e instanceof AppError &&
          e.errorType !== ErrorType.PROTOCOL_DELETED
        ) {
          throw e;
        }
      }
    });

    await Promise.all(promises);
  }

  /**
   * Enables background sync for protocols, so that they get synced regularly
   * also when the app is not running
   * Note: This is only supported for an installed PWA
   */
  async enablePeriodicBackgroundUpdates() {
    return periodicBackgroundSync(
      'sync-protocols',
      6 * 60 * 60 * 1000, // 6 hours
    );
  }
}
