import { TextField, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  WrittenBySelect,
  useProtocolState,
} from '@/pages/edit-protocol/common';
import { NumberUpdater } from '@/pages/edit-protocol/common/number-updater';

import { SigekoStore } from '../sigeko-store';
import { HeaderDatePicker, HeaderTextField } from './HeaderTextField';
import { MailingList } from './mailing-list';

const FormColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
}));

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(5),
  marginTop: theme.spacing(5),
}));

const DATE_PICKER_PLACEHOLDER = 'TT.MM.JJJJ';

interface Props {
  className?: string;
}

export const ProtocolHeader = ({ className }: Props) => {
  const { t } = useTranslation();

  const [
    header,
    writtenBy,
    updateHeaderField,
    setWrittenBy,
    variant,
    protocolNumberOnOpen,
    changeProtocolNumber,
  ] = useProtocolState((s: SigekoStore) => [
    s.protocol.header,
    s.protocol.writtenBy,
    s.updateHeaderField,
    s.setWrittenBy,
    s.protocol.header.variant,
    s.protocolNumberOnOpen,
    s.changeProtocolNumber,
  ]);
  return (
    <>
      <LocalizationProvider adapterLocale={'de'} dateAdapter={AdapterDayjs}>
        <FormContainer id={'protocol-header'} className={className}>
          <FormColumn>
            {/*Protocol Date*/}
            <HeaderDatePicker
              label={t(
                `page.edit_protocol.${variant}.header.inputs.protocol_date`,
              )}
              value={header.protocolDate && dayjs(header.protocolDate)}
              onChange={(newValue) => {
                newValue &&
                  updateHeaderField('protocolDate', newValue.toDate());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: DATE_PICKER_PLACEHOLDER,
                  }}
                />
              )}
            />

            {/*Inspector Name*/}
            <WrittenBySelect
              value={writtenBy}
              onChange={setWrittenBy}
              label={t(
                `page.edit_protocol.${variant}.header.inputs.written_by`,
              )}
            />

            {/*Location*/}
            <HeaderTextField
              autoCapitalize={'true'}
              defaultValue={header.location}
              label={t(`page.edit_protocol.${variant}.header.inputs.location`)}
              onBlur={(e) =>
                updateHeaderField('location', e.currentTarget.value)
              }
            />
            {/*Client*/}
            <HeaderTextField
              autoCapitalize={'true'}
              defaultValue={header.client}
              label={t(`page.edit_protocol.${variant}.header.inputs.client`)}
              onBlur={(e) => updateHeaderField('client', e.currentTarget.value)}
            />
            {/*Client Address*/}
            <HeaderTextField
              autoCapitalize={'true'}
              defaultValue={header.clientAddress}
              label={t(
                `page.edit_protocol.${variant}.header.inputs.client_address`,
              )}
              onBlur={(e) =>
                updateHeaderField('clientAddress', e.currentTarget.value)
              }
            />
          </FormColumn>
          <FormColumn>
            {/*Occasion*/}
            <HeaderTextField
              autoCapitalize={'true'}
              defaultValue={header.occasion}
              label={t(`page.edit_protocol.${variant}.header.inputs.occasion`)}
              onBlur={(e) =>
                updateHeaderField('occasion', e.currentTarget.value)
              }
            />
            {/*Project Name*/}
            <HeaderTextField
              autoCapitalize={'true'}
              defaultValue={header.projectName}
              label={t(
                `page.edit_protocol.${variant}.header.inputs.project_name`,
              )}
              onBlur={(e) =>
                updateHeaderField('projectName', e.currentTarget.value)
              }
            />
            {/*Project Number*/}
            <HeaderTextField
              autoCapitalize={'true'}
              defaultValue={header.projectNumber}
              label={t(
                `page.edit_protocol.${variant}.header.inputs.project_number`,
              )}
              onBlur={(e) =>
                updateHeaderField('projectNumber', e.currentTarget.value)
              }
            />
            {/*Inspection Date*/}
            <HeaderDatePicker
              label={t(`page.edit_protocol.${variant}.header.inputs.date`)}
              value={header.date && dayjs(header.date)}
              onChange={(newValue) => {
                newValue && updateHeaderField('date', newValue.toDate());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: DATE_PICKER_PLACEHOLDER,
                  }}
                />
              )}
            />
            {/*Protocol Number*/}
            <HeaderTextField
              autoCapitalize={'true'}
              value={header.protocolNumber}
              onChange={undefined}
              InputProps={{
                endAdornment: (
                  <NumberUpdater
                    onDecrease={() => {
                      console.log('decrease', header.protocolNumber);
                      changeProtocolNumber(header.protocolNumber - 1);
                    }}
                    onIncrease={() => {
                      console.log(
                        'increase',
                        header.protocolNumber,
                        protocolNumberOnOpen,
                      );
                      if (!protocolNumberOnOpen === null) return;
                      if (header.protocolNumber <= protocolNumberOnOpen) {
                        changeProtocolNumber(header.protocolNumber + 1);
                      }
                    }}
                  />
                ),
              }}
              label={t(
                `page.edit_protocol.${variant}.header.inputs.protocol_number`,
              )}
            />
          </FormColumn>
        </FormContainer>
      </LocalizationProvider>

      <MailingList
        contacts={header.contacts}
        onContactsChanged={(newContactList) => {
          newContactList && updateHeaderField('contacts', newContactList);
        }}
      />
    </>
  );
};
