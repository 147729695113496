import { grey } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    active?: Partial<CommonColors>;
    background?: Partial<TypeBackground>;
    gray?: PaletteOptions['primary'];
  }

  interface Palette {
    gray: Palette['primary'];
    tertiary: Palette['primary'];
  }

  interface CommonColors {
    active?: string;
  }

  interface TypeBackground {
    main?: string;
    secondaryPaper?: string;
  }
}

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    gray: true;
    tertiary: true;
  }
}

export const colors = {
  primary: {
    main: '#0076BC',
    light: '#58a4ef',
    dark: '#004b8b',
    contrastText: '#fff',
  },
  secondary: {
    main: '#F8FAFC',
    light: '#ffffff',
    dark: '#d3d8dc',
    contrastText: '#0076BC',
  },
  tertiary: {
    main: '#34C759',
    light: '#B9E9C5',
    dark: '#149936',
    contrastText: '#fff',
  },
  gray: {
    main: '#252326',
    light: '#4d4b4e',
    dark: '#000000',
  },
  common: {
    black: '#000',
    white: '#fff',
    active: '#E72730',
  },
  background: {
    paper: '#fff',
    secondaryPaper: grey[100],
    default: '#fff',
    main: '#0076BC',
  },
};
