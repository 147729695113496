import { IconButton, IconButtonProps, styled } from '@mui/material';
import React from 'react';
import { NavLink, To } from 'react-router-dom';

import { ActiveBadgeSvg } from '@/assets/svg';

const MenuButtonContainer = styled('div')(() => ({
  position: 'relative',
}));

const StyledActiveBadgeSvg = styled(ActiveBadgeSvg)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: -1, //icon is slightly rounded (to hide=-1)
  height: '100%',
  fill: theme.palette.common.active,
}));

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>((props) => ({
  position: 'relative',
  borderRadius: 0,
  padding: 10,
  height: 50,
  width: '100%',
  fill: `${props.isActive ? props.theme.palette.primary.main : '#757897'}`,
}));

interface Props {
  to?: To;
  icon: React.ReactNode;
}

export const MenuButton = ({ icon, to, ...props }: Props & IconButtonProps) => {
  const createMenuButton = (isActive: boolean) => (
    <MenuButtonContainer>
      <StyledIconButton isActive={isActive} {...props}>
        {icon}
      </StyledIconButton>
      {isActive && <StyledActiveBadgeSvg />}
    </MenuButtonContainer>
  );

  return to ? (
    <NavLink to={to} style={{ textDecoration: 'none' }}>
      {({ isActive }) => createMenuButton(isActive)}
    </NavLink>
  ) : (
    createMenuButton(false)
  );
};
