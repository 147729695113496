import { registerSW } from 'virtual:pwa-register';

import {
  ServiceWorkerWrapper,
  ServiceWorkerWrapperImpl,
} from './service-worker-wrapper';

export type UpdateListener = (
  serviceWorkerWrapper: ServiceWorkerWrapper,
) => void;

/**
 * Registers the service worker and informs about available updates.
 *
 */
export class ServiceWorkerRegistrationService {
  private updateListener: UpdateListener | null = null;
  private needRefreshTriggered = false;
  private readonly updateSW: () => Promise<void>;

  constructor() {
    this.updateSW = registerSW({
      onNeedRefresh: () => {
        if (this.updateListener) {
          this.updateListener?.(new ServiceWorkerWrapperImpl(this.updateSW));
        } else {
          this.needRefreshTriggered = true;
        }
      },
    });
  }

  public setUpdateListener(listener: UpdateListener | null): void {
    this.updateListener = listener;
    if (this.updateListener && this.needRefreshTriggered) {
      this.updateListener(new ServiceWorkerWrapperImpl(this.updateSW));
    }
  }
}
