export function setCaretToEnd(element: HTMLElement) {
  const range = document.createRange();
  const lastChild = element.lastChild;

  if (lastChild && lastChild.textContent !== null) {
    range.setStart(lastChild, lastChild.textContent.length);
    range.setEnd(lastChild, lastChild.textContent.length);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
}
