import { Save } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';
import { useEditable } from '@/pages/edit-protocol/common';
import {
  NetworkMode,
  SaveProtocolTask,
  UploadLocalImagesTask,
} from '@/services/database';

import { SideMenuButton } from '../SideMenuButton';

interface Props {
  protocolId: string;
}

export const SaveProtocolButton = ({ protocolId }: Props) => {
  const { t } = useTranslation();
  const { taskQueue } = useServices();
  const { showSnackbar } = useSnackbar();
  const editable = useEditable();

  const { mutate, isLoading } = useMutation<void, AppError>(
    async () => {
      await Promise.all([
        taskQueue.addTask(
          SaveProtocolTask.create(protocolId, NetworkMode.ONLINE),
          {
            awaitResult: true,
          },
        ),
        taskQueue.addTask(UploadLocalImagesTask.create(NetworkMode.ONLINE), {
          awaitResult: true,
        }),
      ]);
    },
    {
      networkMode: 'always',
      onSuccess: () => {
        showSnackbar({
          variant: 'success',
          content: t('page.edit_protocol.side_menu.actions.save_success'),
        });
      },
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );

  return (
    <SideMenuButton
      disabled={!editable}
      loading={isLoading}
      onClick={() => mutate()}
      icon={<Save />}
    >
      {t('page.edit_protocol.side_menu.actions.save_btn')}
    </SideMenuButton>
  );
};
