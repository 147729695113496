import { Divider, Typography, styled } from '@mui/material';
import React from 'react';

import { HeaderExportItem } from '@/pages/export/types';

const StyledHeaderItem = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  width: '100%',
}));

const StyledLabel = styled(Typography)(() => ({
  fontWeight: 700,
  width: 300,
}));

const StyledValue = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  width: '100%',
}));

export const HeaderItem = ({ label, value }: HeaderExportItem) => {
  return (
    <div>
      <StyledHeaderItem>
        <StyledLabel variant={'body2'}>{label}</StyledLabel>
        <StyledValue variant={'body2'}>{value}</StyledValue>
      </StyledHeaderItem>
      <Divider />
    </div>
  );
};
