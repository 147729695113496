import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SigekoProtocolDefect as Defect } from '@/common/types/protocol';
import { ActionButton } from '@/common/ui/buttons';

import { SetAssigneesDialog } from './SetAssigneesDialog';

interface Props {
  defect: Defect;
  disabled?: boolean;
}

export const AddAssigneesButton = ({ defect, disabled }: Props) => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useDialog<void, void>();

  return (
    <>
      <ActionButton
        key={'assignees'}
        disabled={disabled}
        startIcon={<AccountBoxIcon />}
        onClick={() => open()}
        label={convertAssigneesToFormattedString(defect.assignees, t)}
      />
      <SetAssigneesDialog
        defectId={defect.id}
        isDialogOpen={isOpen}
        onClose={close}
        assignees={defect.assignees}
      />
    </>
  );
};

const convertAssigneesToFormattedString = (
  assignees: string[] | null,
  t: TFunction<'translation', undefined, 'translation'>,
): string => {
  if (assignees === null) {
    return t('page.edit_protocol.actions.add_assignee.button_title');
  }
  if (assignees.length === 1) {
    return assignees[0];
  } else if (assignees.length === 2) {
    return assignees.join(', ');
  } else if (assignees.length > 2) {
    const firstName = assignees[0];
    const remainingCount = assignees.length - 1;
    return `${firstName} ${t(
      'page.edit_protocol.actions.add_assignee.dialog.show_more_assignees',
    )} ${remainingCount}`;
  } else {
    return '';
  }
};
