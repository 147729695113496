import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUser } from '@/common/hooks/use-user';
import { useServices } from '@/common/providers/service-provider';
import {
  SigekoVariant,
  createEmptySigekoProtocol,
} from '@/common/types/protocol';
import { DropDownMenuItem } from '@/common/ui/dropdown';
import { BaseSelect } from '@/common/ui/select';
import { routes } from '@/routing';

export const CreateProtocolButton = () => {
  const user = useUser();
  const { t } = useTranslation();
  const { database } = useServices();

  const navigate = useNavigate();

  const createAndNavigateToProtocol = async (variant: SigekoVariant) => {
    if (user) {
      const protocol = createEmptySigekoProtocol(user, variant);
      await database.saveProtocol(protocol);
      navigate(routes.generateEditProtocolPath(protocol.id));
    }
  };

  const actions = [
    <DropDownMenuItem
      key={'create-sigeko'}
      onClick={() => createAndNavigateToProtocol('sigeko')}
    >
      {'+ ' + t('page.home.create_protocol_button.protocol-types.sigeko')}
    </DropDownMenuItem>,
    <DropDownMenuItem
      key={'create-blanko'}
      onClick={() => createAndNavigateToProtocol('blanko')}
    >
      {'+ ' + t('page.home.create_protocol_button.protocol-types.blanko')}
    </DropDownMenuItem>,
  ];

  return (
    <>
      <BaseSelect
        name={t('page.home.create_protocol_button.title')}
        actions={[...actions]}
      />
    </>
  );
};
