import { BaseProtocol } from './base-protocol';
import { Image } from './image';

/**
 * The protocols are plain typescript objects. This is advantageous because
 * of simple serialization compatibility with zustand and react.
 * Problem is that we can't add behavior to them. This is why we introduce
 * holders. They are just a wrapper around the protocol that adds behavior.
 * They can be used to add common behaviour across different protocol types (polymorphism).
 */
export abstract class ProtocolHolder<T extends BaseProtocol> {
  protected constructor(public readonly protocol: T) {}

  abstract getImages(): Image[];
}
