import { Download } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useVisibilityChangeContext } from '@/common/providers/visibility-change-provider';
import { SideMenuButton } from '@/pages/edit-protocol/common';
import { generateExportPath } from '@/routing/routes';

interface Props {
  protocolId: string;
}

export const ExportViewButton = ({ protocolId }: Props) => {
  const { t } = useTranslation();
  const { disableVisibilityChangeListenerUntilWindowRefocused } =
    useVisibilityChangeContext();

  return (
    <SideMenuButton
      onClick={() => {
        disableVisibilityChangeListenerUntilWindowRefocused();
        window.open(generateExportPath(protocolId), '_blank');
      }}
      icon={<Download />}
    >
      {t('page.edit_protocol.side_menu.actions.export_view_btn')}
    </SideMenuButton>
  );
};
