import { styled } from '@mui/material';
import _, { compact } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';
import { Contact } from '@/common/types/protocol';
import { Dropzone } from '@/common/ui/dropzone';
import { ErrorMessage } from '@/common/ui/error-message';
import { readFile } from '@/common/utils/file-reader';
import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

import { parseVcf } from './parse-vcf';

export const Container = styled('div')(() => ({
  display: 'flex',
}));

interface Props {
  onChangeContacts: (newContactList: Contact[]) => void;
  contacts: Contact[];
}

export const VcfDropzone = ({
  onChangeContacts,
  contacts,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [uploadErrors, setUploadErrors] = useState<AppError[]>([]);
  const [variant] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.variant,
  ]);
  const onDrop = async (acceptedFiles: File[]) => {
    setUploadErrors([]);

    const filePromiseArray = acceptedFiles.map(async (dropzoneFile) => {
      let file: string | ArrayBuffer | null = null;
      try {
        file = await readFile(dropzoneFile, {
          acceptedExtension: '.vcf',
          encoding: 'ISO-8859-1',
        });

        if (file === null) {
          // noinspection ExceptionCaughtLocallyJS
          throw new AppError(ErrorType.FILE_IMPORT_FAILED, dropzoneFile.name);
        }

        return parseVcf(dropzoneFile.name, file.toString());
      } catch (err) {
        if (err instanceof AppError) {
          setUploadErrors((prev) => [...prev, err as AppError]);
        } else if (err instanceof Error) {
          console.error('Unexpected Error when parsing vcf file', err);
        }
        return null;
      }
    });

    const newContacts = await Promise.all(filePromiseArray);
    onChangeContacts(
      _(contacts)
        .concat(...compact(newContacts))
        .uniqBy((it) => `${it.name}:${it.organisation}`)
        .value(),
    );
  };

  return (
    <>
      <Dropzone
        buttonText={t(
          `page.edit_protocol.${variant}.header.inputs.mailing_list.drop_zone_upload.button`,
        )}
        dragNotActiveText={t(
          `page.edit_protocol.${variant}.header.inputs.mailing_list.drop_zone_upload.text`,
        )}
        dragActiveText={t(
          `page.edit_protocol.${variant}.header.inputs.mailing_list.drop_zone_upload.is_drag_active_text`,
        )}
        onError={(errors) => setUploadErrors((prev) => [...prev, ...errors])}
        accepted={{
          'text/vcard': ['.vcf'],
        }}
        onDrop={onDrop}
        {...props}
      />
      {uploadErrors.map((uploadError) => (
        <ErrorMessage key={uploadError.message} error={uploadError} />
      ))}
    </>
  );
};
