import { Stack, styled } from '@mui/material';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Image, ImageSize } from '@/common/types/protocol';

import { ProtocolImage } from './ProtocolImage';

interface Props {
  images: Image[];
  onImagesSorted: (startIdx: number, targetIdx: number) => void;
  onImageSizeChanged: (id: string, size: ImageSize) => void;
  onImageDelete: (id: string) => void;
  disabled?: boolean;
}

const StyledStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(1),
  overflowX: 'auto',
}));

const StyledImage = styled(ProtocolImage)({
  width: 150,
  height: 150,
}) as typeof ProtocolImage;

export const ImageRow = ({
  images,
  onImagesSorted,
  onImageSizeChanged,
  onImageDelete,
  disabled,
}: Props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <StyledStack direction="row" spacing={1}>
        {images.map((image, idx) => (
          <StyledImage
            key={image.id}
            image={image}
            idx={idx}
            moveImage={onImagesSorted}
            onSizeChanged={(size) => onImageSizeChanged(image.id, size)}
            onDelete={() => onImageDelete(image.id)}
            disabled={disabled}
          />
        ))}
      </StyledStack>
    </DndProvider>
  );
};
