import { useEvent } from '@lumind-solutions/react-hooks/use-event';
import React from 'react';

import { ToolbarUpdateListener, useToolbarApi } from './context';

export function useToolbarUpdate(onUpdate: ToolbarUpdateListener) {
  const { subscribeUpdate } = useToolbarApi();
  const listener = useEvent(onUpdate); // create stable, always up-to-date reference

  React.useEffect(() => {
    return subscribeUpdate(listener);
  }, [subscribeUpdate, listener]);
}
