import { PersistStorage } from 'zustand/middleware';

import { Database } from '@/services/database';

type StorageValue<S> = {
  state: S;
  version?: number;
};

export class DatabaseStorage<S extends { protocol: { id: string } }>
  implements PersistStorage<S>
{
  constructor(private readonly database: Database) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getItem(name: string): null {
    // We do not load from storage. This is already done before
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeItem(name: string): void {
    // Will not be called
  }

  async setItem(name: string, value: StorageValue<S>): Promise<void> {
    const protocol = value.state.protocol;
    await this.database.updateProtocol(protocol.id, protocol);
  }
}
