import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AuthenticatedLayout } from '@/common/layout';
import { useServices } from '@/common/providers/service-provider';

import * as routes from './routes';

export function RequireAuth() {
  const { authService } = useServices();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    return authService.onUserChanged((user) => {
      setIsLoggedIn(!!user);
    });
  }, [authService]);

  if (isLoggedIn === null) {
    return null;
  } else if (isLoggedIn) {
    return (
      <AuthenticatedLayout>
        <Outlet />
      </AuthenticatedLayout>
    );
  } else {
    return <Navigate to={routes.LOGIN} replace />;
  }
}
