import { Database, Message } from '@/services/database';

export class MessageService {
  constructor(private readonly database: Database) {}

  addMessage(message: Message) {
    return this.database.saveMessageDistinct(message);
  }

  deleteMessage(id: number) {
    return this.database.deleteMessage(id);
  }

  fetchMessages(): Promise<Message[]> {
    return this.database.fetchMessages();
  }
}
