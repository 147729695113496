import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, BLUR_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';
import { useEffect } from 'react';

import { EditorContentState } from '@/common/types/protocol';

interface Props {
  onBlur?: (editorState: EditorContentState) => void;
}

/**
 * Subscribes to the Lexical editor's blur events
 */
export const BlurPlugin = ({ onBlur }: Props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          const editorState = editor.getEditorState();
          onBlur?.(
            editorState.read(() => {
              const rawText = $getRoot().getTextContent();
              const rawTextLength = rawText.length;
              const title = getFirstLine(rawText);
              return {
                lexicalContent: rawTextLength > 0 ? editorState.toJSON() : null,
                title: title,
              };
            }),
          );
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [],
  );

  return null;
};

function getFirstLine(rawText: string) {
  return rawText.split('\n')[0];
}
