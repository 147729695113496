import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { compact } from 'lodash';
import React from 'react';

import { Contact } from '@/common/types/protocol';

interface Props {
  contacts: Contact[];
  title: string;
}

const Category = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(1),
  fontWeight: 700,
}));

const ContactTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const ContactList = ({ contacts, title }: Props) => {
  return contacts.length > 0 ? (
    <>
      <Category>{title}</Category>

      {contacts
        .map((it) => compact([it.organisation, it.name]).join(', '))
        .sort()
        .map((contact) => (
          <ContactTypography variant={'body2'} key={contact}>
            {contact}
          </ContactTypography>
        ))}
    </>
  ) : null;
};
