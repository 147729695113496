import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ButtonGroup } from '@mui/material';
import React from 'react';

import { PrimaryButton } from '@/common/ui/buttons';
import { useEditable } from '@/pages/edit-protocol/common';

interface Props {
  onIncrease: () => void;
  onDecrease: () => void;
}

export const NumberUpdater = ({ onIncrease, onDecrease }: Props) => {
  const editable = useEditable();
  return (
    <ButtonGroup>
      <PrimaryButton
        disabled={!editable}
        size={'small'}
        style={{ padding: '5px 0px' }}
        onClick={onDecrease}
      >
        <RemoveIcon fontSize="small" />
      </PrimaryButton>
      <PrimaryButton
        disabled={!editable}
        size={'small'}
        style={{ padding: 0 }}
        onClick={onIncrease}
      >
        <AddIcon fontSize="small" />
      </PrimaryButton>
    </ButtonGroup>
  );
};
