import { styled } from '@mui/material';
import React from 'react';

import { ActionBar } from './ActionBar';

interface Props {
  sidebar: React.ReactNode;
  children: React.ReactNode;
}

const Sidebar = styled('aside')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: 165,
  flexShrink: 0,
  backgroundColor: theme.palette.background.secondaryPaper,
  padding: theme.spacing(2),
}));

const MainColumn = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  flex: 1,
  backgroundColor: theme.palette.background.default,
}));

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(0, 2),
  scrollBehavior: 'smooth', // enable animated scrolling when navigating to anchor
}));

const Root = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
}));

export const Layout = (props: Props) => {
  return (
    <Root>
      {props.sidebar}
      <MainColumn>{props.children}</MainColumn>
    </Root>
  );
};

Layout.Sidebar = Sidebar;
Layout.ActionBar = ActionBar;
Layout.Content = Content;
