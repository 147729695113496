import { DeadlinePreset, ProtocolStatus, ProtocolType } from '@ibag/common';

import { AuthUser } from '@/common/types/auth';
import { RichText } from '@/common/types/protocol';
import { createNanoId } from '@/common/utils/nanoid';

import { BaseProtocol } from '../common/base-protocol';
import { DefectNumber } from './defect-number';

export interface SigekoProtocolDefect extends RichText {
  id: string;
  deadline: Deadline | null;
  assignees: string[] | null;
  defectNumber: DefectNumber;
  updates: SigekoDefectUpdate[];
  done: number | null;
}

export interface SigekoDefectUpdate extends RichText {
  id: string;
}

export type Deadline = Date | DeadlinePreset;

export enum ContactCategory {
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  OTHER = 'OTHER',
}

export interface Contact {
  id: string;
  name: string;
  organisation: string;
  category: ContactCategory;
}

export type SigekoVariant = 'sigeko' | 'blanko';

export interface SigekoProtocolHeader {
  protocolDate: Date | null; // date when protocol is exported
  projectNumber: string | null;
  projectName: string | null;
  protocolNumber: number;
  date: Date | null; // date of inspection
  occasion: string | null;
  client: string | null;
  clientAddress: string | null;
  location: string | null;
  contacts: Contact[];
  variant: SigekoVariant;
}

export type KeyOfSigekoProtocolHeader = keyof SigekoProtocolHeader;

export interface SigekoProtocol extends BaseProtocol {
  type: ProtocolType.SIGEKO;
  header: SigekoProtocolHeader;
  content: {
    defects: SigekoProtocolDefect[];
    startText: RichText;
    endText: RichText;
  };
}

export function createEmptySigekoProtocol(
  user: AuthUser,
  variant: SigekoVariant,
): SigekoProtocol {
  return {
    id: createNanoId(),
    revision: 0,
    lockedBy: null,
    status: ProtocolStatus.NEW,
    title: 'Ohne Titel',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: { id: user.id, name: user.displayName ?? '' },
    writtenBy: null,
    type: ProtocolType.SIGEKO,

    header: {
      protocolNumber: 0,
      protocolDate: null,
      projectNumber: null,
      projectName: null,
      date: null,
      occasion: null,
      client: null,
      clientAddress: null,
      location: null,
      contacts: [],
      variant: variant,
    },
    content: {
      defects: [],
      startText: {
        text: null,
        title: null,
        images: [],
      },
      endText: {
        text: null,
        title: null,
        images: [],
      },
    },
    localVersion: null,
    offlineAvailable: false,
  };
}
