import { CircularProgress, ListItemIcon } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolStatus } from '@ibag/common';

import { DropDownMenuItem } from '@/common/ui/dropdown';
import { useChangeProtocolStatus } from '@/pages/edit-protocol/common/change-status-button/useChangeProtocolStatus';

interface Props {
  status: ProtocolStatus;
  closeMenu: () => void;
}

export const ChangeStatusDropdownItem = ({ status, closeMenu }: Props) => {
  const { t } = useTranslation();

  const { disabled, changeStatus, isLoading } = useChangeProtocolStatus(
    status,
    closeMenu,
  );

  return (
    <DropDownMenuItem
      disabled={disabled}
      confirmable={{
        title: t(
          `page.edit_protocol.actions.change_status.${status.toLowerCase()}.confirm_title`,
        ),
        text: t(
          `page.edit_protocol.actions.change_status.${status.toLowerCase()}.confirm_text`,
        ),
        confirmButtonTitle: t(
          'page.edit_protocol.actions.change_status.confirm_btn',
        ),
        onConfirm: () => changeStatus(),
      }}
    >
      {isLoading && (
        <ListItemIcon>
          <CircularProgress size={24} />
        </ListItemIcon>
      )}
      {t(
        `page.edit_protocol.actions.change_status.${status.toLowerCase()}.label`,
      )}
    </DropDownMenuItem>
  );
};
