import EditIcon from '@mui/icons-material/Edit';
import { Icon, Typography, alpha, styled } from '@mui/material';
import React, { useRef, useState } from 'react';

import {
  BaseStore,
  useEditable,
  useProtocolState,
} from '@/pages/edit-protocol/common';

import { setCaretToEnd } from './set-caret-to-end';

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  color: 'white',
  borderRadius: theme.shape.borderRadius,
  fontSize: '1.2em',
  padding: theme.spacing(0, theme.spacing(1)),
  outline: 'none',
  backgroundColor: `${
    isActive ? alpha(theme.palette.common.white, 0.3) : undefined
  }`,
}));

const IconWrapper = styled(Icon)(() => ({
  position: 'relative',
  top: -2,
  alignItems: 'center',
  justifyContent: 'center',
}));

const Root = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ProtocolTitle = () => {
  const editable = useEditable();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [title, setTitle] = useProtocolState((s: BaseStore<any>) => [
    s.title,
    s.setTitle,
  ]);
  const ref = useRef<HTMLParagraphElement | null>(null);

  const edit = () => {
    editable && setIsEditMode(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  React.useEffect(() => {
    if (isEditMode && ref.current) {
      ref.current.focus();
      setCaretToEnd(ref.current);
    }
  }, [isEditMode]);

  return (
    <Root>
      <StyledTypography
        id={'title'}
        ref={ref}
        isActive={isEditMode}
        suppressContentEditableWarning={true}
        contentEditable={isEditMode}
        onClick={edit}
        onMouseDown={edit}
        onBlur={() => {
          setIsEditMode(false);
          setTitle(ref.current?.innerText ?? '');
        }}
        onKeyDown={handleKeyDown}
      >
        {title}
      </StyledTypography>
      {editable && !isEditMode ? (
        <IconWrapper onClick={edit}>
          <EditIcon />
        </IconWrapper>
      ) : undefined}
    </Root>
  );
};
