import {
  Pagination,
  TemplateDto,
  TemplateType,
  TemplateUpdatesDto,
  UploadErrorDto,
} from '@ibag/common';

import { Template } from '@/common/types/template';
import { Database } from '@/services/database';
import { HttpClient } from '@/services/http-client';

export class TemplateService {
  constructor(
    private readonly authorizedHttpClient: HttpClient,
    private readonly database: Database,
  ) {}

  async uploadTemplates(templateFiles: File[]) {
    const formData = new FormData();
    templateFiles.forEach((templateFile) =>
      formData.append(`files`, templateFile),
    );
    return this.authorizedHttpClient.formRequest<UploadErrorDto[]>(
      'POST',
      '/api/templates',
      undefined,
      formData,
    );
  }

  async updateTemplateType(id: number, type: TemplateType) {
    return this.authorizedHttpClient.jsonRequest<TemplateDto>(
      'PUT',
      `/api/templates/${id}`,
      undefined,
      { type },
    );
  }

  async updateTemplate(templateFiles: File[]) {
    const formData = new FormData();
    templateFiles.forEach((templateFile) =>
      formData.append(`files`, templateFile),
    );
    return this.authorizedHttpClient.formRequest<UploadErrorDto[]>(
      'POST',
      '/api/templates',
      undefined,
      formData,
    );
  }

  async deleteTemplate(id: number) {
    await this.authorizedHttpClient.jsonRequest(
      'DELETE',
      `/api/templates/${id}`,
    );
  }

  async fetchTemplates(
    page: number,
    pageSize: number,
  ): Promise<Pagination<TemplateDto>> {
    return this.authorizedHttpClient.jsonRequest<Pagination<TemplateDto>>(
      'GET',
      '/api/templates/',
      {
        page: page.toString(),
        pageSize: pageSize.toString(),
      },
    );
  }

  async updateTemplates(): Promise<void> {
    console.log('Updating templates...');
    const lastUpdateAt = await this.database.getTemplateUpdateDate();
    const requestDate = new Date();
    const templateUpdatesDto =
      await this.authorizedHttpClient.jsonRequest<TemplateUpdatesDto>(
        'GET',
        '/api/templates/updates',
        lastUpdateAt
          ? {
              updatedAfter: lastUpdateAt.toISOString(),
            }
          : undefined,
      );

    await this.database.updateTemplates(
      templateUpdatesDto.updatedTemplates.map((it) => ({
        ...it,
        updatedAt: requestDate,
      })),
      requestDate,
    );
    await this.database.deleteTemplates(templateUpdatesDto.deletedTemplates);
  }

  search(search: string, type: TemplateType | null): Promise<Template[]> {
    return this.database.searchTemplates(search, type);
  }
}
