import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSendInvitation } from './useSendInvitation';

interface Props {
  id: string;
}

export const SendInvitationButton = ({ id }: Props) => {
  const { t } = useTranslation();
  const { mutate: sendInvitation, isLoading } = useSendInvitation();
  return (
    <>
      {!isLoading ? (
        <Tooltip title={t('page.add_user.send_invitation.tooltip')}>
          <IconButton onClick={() => sendInvitation(id)} disabled={isLoading}>
            <ForwardToInboxOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <CircularProgress
          size={15}
          sx={(theme) => ({ color: theme.palette.grey.A700 })}
        />
      )}
    </>
  );
};
