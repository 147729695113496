import { $generateNodesFromDOM } from '@lexical/html';
import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import { SvgIcon } from '@mui/material';
import { $getRoot } from 'lexical';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TemplatesSvg } from '@/assets/svg';
import { Template } from '@/common/types/template';

import { useToolbarApi } from '../../context';
import { ToolbarIconButton } from '../ToolbarIconButton';
import { SearchDialog } from './SearchDialog';

export const InsertTemplateButton = () => {
  const { editor } = useToolbarApi();
  const { t } = useTranslation();

  const { isOpen, open, close } = useDialog<void, Template | undefined>();

  const onClose = (template?: Template) => {
    close(template);
    if (template) {
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(template.html, 'text/html');

        const nodes = $generateNodesFromDOM(editor, dom);

        const root = $getRoot();

        root.append(...nodes);
      });
    }
  };

  return (
    <>
      <ToolbarIconButton
        title={t('editor.insert_template.title')}
        onClick={() => open()}
      >
        <SvgIcon>
          <TemplatesSvg fill={'currentColor'} />
        </SvgIcon>
      </ToolbarIconButton>
      <SearchDialog open={isOpen} onClose={onClose} />
    </>
  );
};
