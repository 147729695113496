import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { toolbarButtonSize } from './styles';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 0,
  color: theme.palette.common.white,
  '&.MuiIconButton-sizeSmall': {
    padding: toolbarButtonSize,
  },
  '&.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.33)',
  },
}));

export const ToolbarIconButton = (props: IconButtonProps) => {
  return <StyledIconButton size={'small'} {...props} />;
};
