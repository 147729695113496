import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SigekoProtocolDefect,
  formatDefectNumber,
} from '@/common/types/protocol';
import { SpaceBetweenRowContainer } from '@/common/ui/containers';
import { PrintEditor } from '@/common/ui/text-editor/editor';
import { convertDateToDateString } from '@/common/utils/format-date';
import { Label } from '@/pages/export/styles';

import { Headline } from '../Headline';
import { Assignees } from './Assignees';
import { DefectDone } from './DefectDone';
import { Update } from './Update';
import { ImagesPrintView } from './images';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

interface Props {
  defects: SigekoProtocolDefect[];
}

export const DefectsPrintView = ({ defects }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {defects.map((defect) => {
        const deadline =
          defect.deadline instanceof Date
            ? convertDateToDateString(defect.deadline)
            : defect.deadline
            ? t(`common.deadline_presets.${defect.deadline}`)
            : '---';

        return (
          <React.Fragment key={defect.id}>
            <SpaceBetweenRowContainer>
              <Headline
                right={
                  <Container>
                    <div>
                      <Label>
                        {t(
                          'page.edit_protocol.actions.add_deadline.button_title',
                        ) + ': '}
                        <span style={{ fontWeight: 'bold' }}>{deadline}</span>
                      </Label>
                    </div>
                    {defect.done && <DefectDone />}
                  </Container>
                }
              >
                {t('page.edit_protocol.content.defects.title', {
                  defectNumber: formatDefectNumber(defect.defectNumber),
                })}
              </Headline>
            </SpaceBetweenRowContainer>
            <Assignees assignees={defect.assignees} />
            <PrintEditor initialContent={defect.text} />
            <ImagesPrintView images={defect.images} />

            {defect.updates.map((update, index) => {
              return (
                <Update
                  key={update.id}
                  update={update}
                  activeStyle={
                    defect.updates.length > 1
                      ? index === defect.updates.length - 1
                      : false
                  }
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};
