import { CssBaseline } from '@mui/material';
import { deDE } from '@mui/material/locale';
import { ThemeProvider as Provider, createTheme } from '@mui/material/styles';
import React from 'react';

import { colors } from './colors';
import { typography } from './typography';

interface Props {
  children: React.ReactNode;
}

//add Shape
declare module '@mui/system/createTheme/shape' {
  interface Shape {
    rounded: number;
  }
}

export const baseTheme = createTheme(
  {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          // customize scrollbar appearance
          '&::-webkit-scrollbar': {
            width: 4, // vertical scrollbar
            height: 4, // horizontal scrollbar
            backgroundColor: '#E6E6E6',
          },
          '&::-webkit-scrollbar-thumb': {
            margin: 2,
            borderRadius: 10,
            backgroundColor: '#A1A1A1',
          },
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
          },
          '#root': {
            height: '100%',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'filled',
        },
        styleOverrides: {
          root: {
            ['& .MuiFilledInput-root']: {
              background: '#F8FAFC',
            },
            ['& .MuiFilledInput-root:hover']: {
              backgroundColor: '#d3d8dc',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
          },
        },
      },
    },
    typography,
    palette: colors,
    shape: { borderRadius: 7, rounded: 20 },
  },
  deDE,
);

export const ThemeProvider = ({ children }: Props) => {
  return (
    <Provider theme={baseTheme}>
      <CssBaseline />
      {children}
    </Provider>
  );
};
