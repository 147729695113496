import { Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChangeStatusButton,
  DeleteProtocolButton,
  ExportViewButton,
  LockProtocolButton,
  MakeOfflineAvailableButton,
  SaveProtocolButton,
  SideMenu,
  useProtocolState,
} from '@/pages/edit-protocol/common';

import { selectProtocolDefects } from '../selectors';
import { SigekoStore } from '../sigeko-store';
import { TableOfContent } from './TableOfContent';
import { ToggleDoneDefectsButton } from './ToggleDoneDefectsButton';

const TemplateTypography = styled(Typography)(() => ({
  fontSize: '0.9em',
  fontWeight: 'bold',
}));

const StyledChangeStatusButton = styled(ChangeStatusButton)(({ theme }) => ({
  alignSelf: 'center',
  width: `calc(100% - ${theme.spacing(2)})`,
  margin: theme.spacing(2, 0, 0, 1),
}));

export const SigekoSideMenu = () => {
  const { t } = useTranslation();

  const [protocolId, defects, variant] = useProtocolState((s: SigekoStore) => [
    s.protocol.id,
    selectProtocolDefects(s),
    s.protocol.header.variant,
  ]);

  return (
    <SideMenu
      tableOfContent={
        <>
          <TemplateTypography>
            {t('page.edit_protocol.side_menu.protocol_template', {
              template: variant.toUpperCase(),
            })}
          </TemplateTypography>

          <Typography
            sx={(theme) => ({
              paddingBottom: theme.spacing(2),
              fontSize: '0.8em',
            })}
            variant={'subtitle2'}
          >
            {t('page.edit_protocol.side_menu.table_of_content.title')}
          </Typography>
          <TableOfContent defects={defects} />
        </>
      }
      actions={
        <>
          <SaveProtocolButton protocolId={protocolId} />
          <ToggleDoneDefectsButton />
          <MakeOfflineAvailableButton protocolId={protocolId} />
          <LockProtocolButton protocolId={protocolId} />
          <ExportViewButton protocolId={protocolId} />
          <DeleteProtocolButton protocolId={protocolId} />
          <StyledChangeStatusButton />
        </>
      }
    />
  );
};
