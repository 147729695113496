import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.tertiary.main,
}));

const BoldTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
}));

export const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'inherit',
  width: 20,
  height: 20,
  marginRight: theme.spacing(0.5),
}));

export const DefectDone = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledCheckCircleIcon />
      <BoldTypography variant={'body1'}>
        {t(
          'page.edit_protocol.content.defects.defect_actions.restore_defect.label_mark_as_done',
        )}
      </BoldTypography>
    </Container>
  );
};
