import { BugsnagStatic } from '@bugsnag/core';

import { ENV } from '@/common/config';

/**
 * Logger service
 * Because we want to log from service worker and from the app, we need to init
 * the logger with the matching Bugsnag instance, before first use.
 */
export class Logger {
  // implement singleton pattern
  private static _instance: Logger;
  private bugsnag: BugsnagStatic | null = null;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  /**
   * Init the logger with the Bugsnag instance
   * Call this method from sw and app before first use
   */
  init(bugsnag: BugsnagStatic) {
    this.bugsnag = bugsnag;
  }

  static get instance() {
    if (!Logger._instance) {
      Logger._instance = new Logger();
    }
    return Logger._instance;
  }

  setUser(user: { id: string; email?: string } | null) {
    if (user) {
      this.bugsnag?.setUser(user?.id, user?.email);
    } else {
      this.bugsnag?.setUser(undefined, undefined, undefined);
    }
  }

  log(message: string, extras?: Record<string, any>) {
    console.log(message, extras);
    if (ENV === 'production') {
      this.bugsnag?.leaveBreadcrumb(message, extras, 'log');
    }
  }

  logError(message: string, error: Error) {
    console.error(message, error);
    if (ENV === 'production') {
      this.bugsnag?.notify(error, (evt) => {
        evt.context = message;
        evt.severity = 'error';
      });
    }
  }

  logWarning(message: string, extras?: Record<string, any>) {
    if (ENV === 'production') {
      this.bugsnag?.leaveBreadcrumb(message, extras, 'error');
    }
  }
}
