import { FormControlLabel, Switch, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { wrapMenuLayout } from '@/common/layout';
import { SpaceBetweenRowContainer } from '@/common/ui/containers';

import { DocxDropzone } from './DocxDropzone';
import { TemplateTable } from './TemplateTable';

const StyledDocxDropzone = styled(DocxDropzone)(({ theme }) => ({
  backgroundColor: 'white',
  flexShrink: 1,
  borderRadius: theme.shape.borderRadius,
}));

export const StyledSwitch = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.common.white,
  alignSelf: 'end',
  width: '100%',
  margin: 0,
  marginTop: theme.spacing(3),
}));
export const TemplatesPage = wrapMenuLayout(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [previewMode, setPreviewMode] = useState<boolean>(false);

  return (
    <>
      <SpaceBetweenRowContainer>
        <Typography
          style={{ flexGrow: 1 }}
          color={theme.palette.common.white}
          variant={'h1'}
        >
          {t('page.template.title')}
        </Typography>
        <StyledDocxDropzone />
      </SpaceBetweenRowContainer>
      <StyledSwitch
        labelPlacement="start"
        control={
          <Switch
            color="secondary"
            checked={previewMode}
            onChange={(e) => setPreviewMode(e.target.checked)}
          />
        }
        label={t('page.template.switch_label')}
      />

      <TemplateTable previewMode={previewMode} />
    </>
  );
});
