import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@/common/ui/buttons';
import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

import { AddContactDialog } from './AddContactDialog';

export const AddContactButton = (
  props: React.ComponentProps<typeof PrimaryButton>,
) => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useDialog<void, void>();
  const [variant] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.variant,
  ]);
  return (
    <>
      <PrimaryButton
        size={'small'}
        startIcon={<AddCircleIcon />}
        onClick={() => open()}
        {...props}
      >
        {t(
          `page.edit_protocol.${variant}.header.inputs.mailing_list.add_button`,
        )}
      </PrimaryButton>
      <AddContactDialog isDialogOpen={isOpen} onClose={close} />
    </>
  );
};
