import React from 'react';
import { useTranslation } from 'react-i18next';

import { Contact, ContactCategory } from '@/common/types/protocol';
import { Headline } from '@/pages/export/ui/Headline';

import { ContactList } from './ContactList';

interface Props {
  contacts: Contact[];
  variant: string;
}

export const MailingListPrintView = ({ contacts, variant }: Props) => {
  const { t } = useTranslation();
  return contacts.length > 0 ? (
    <>
      <Headline>
        {t(`page.edit_protocol.${variant}.header.inputs.mailing_list.label`)}
      </Headline>

      {Object.values(ContactCategory).map((category) => (
        <ContactList
          key={category}
          title={t(
            `page.edit_protocol.${variant}.header.inputs.mailing_list.contact_dialog_inputs.category.${category}`,
          )}
          contacts={contacts.filter((contact) => contact.category === category)}
        />
      ))}
    </>
  ) : null;
};
