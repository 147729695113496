import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { TemplateDto } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { UpdateTemplateTypePayload } from '@/common/types/template';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';

export const useUpdateTemplateType = () => {
  const { templateService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, variables, isLoading } = useMutation<
    TemplateDto,
    AppError,
    UpdateTemplateTypePayload
  >(
    async ({ id, type }) => {
      return await templateService.updateTemplateType(id, type);
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['templates']);
      },
    },
  );

  return {
    updateTemplateType: mutate,
    isLoadingId: isLoading ? variables?.id ?? null : null,
  };
};
