import { ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { toolbarButtonSize } from './styles';

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: 0,
  color: theme.palette.common.white,
  '&.MuiToggleButton-sizeSmall': {
    padding: toolbarButtonSize,
  },
})) as typeof ToggleButton;

interface Props {
  value: string;
  children: React.ReactNode;
  onChange?: () => void;
}

export const ToolbarToggleButton = ({
  value,
  children,
  onChange,
  ...props
}: Props) => {
  return (
    <StyledToggleButton
      size={'small'}
      value={value}
      onChange={onChange}
      {...props}
    >
      {children}
    </StyledToggleButton>
  );
};
