import { createUseContextHook } from '@lumind-solutions/react-hooks/create-use-context-hook';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

interface ShowSnackbarCommand {
  content: React.ReactNode;
  duration?: number;
  variant: 'warning' | 'success' | 'error';
}

interface SnackbarAPI {
  snackbarContent: ShowSnackbarCommand | null;
  showSnackbar: (command: ShowSnackbarCommand) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = React.createContext<SnackbarAPI | null>(null);
SnackbarContext.displayName = 'SnackbarContext';

export const SnackbarProvider = ({ children }: Props) => {
  const [content, setContent] = React.useState<ShowSnackbarCommand | null>(
    null,
  );

  const api: SnackbarAPI = {
    showSnackbar: (command: ShowSnackbarCommand) => {
      setContent(command);
    },
    hideSnackbar: () => setContent(null),
    snackbarContent: content,
  };

  return (
    <SnackbarContext.Provider value={api}>{children}</SnackbarContext.Provider>
  );
};

export const wrapSnackbarProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <SnackbarProvider>
        <Component {...props} />
      </SnackbarProvider>
    );

export const useSnackbar = createUseContextHook(SnackbarContext);
