import {
  Box,
  CssBaseline,
  ThemeProvider,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { printTheme } from '@/common/theme/print-theme';
import { PrimaryButton } from '@/common/ui/buttons';
import { SpaceBetweenRowContainer } from '@/common/ui/containers';
import { SigekoProtocolState } from '@/pages/edit-protocol/sigeko/sigeko-store';
import { PrintPayload } from '@/services/protocol';

import { DateAndWriterTable } from './DateAndWriterTable';
import { convertSigekoHeaderToExportHeader } from './common/converter';
import { PDFHeader, footerTemplate } from './pdf-layout';
import {
  DefectsPrintView,
  ImagesPrintView,
  MailingListPrintView,
  ProtocolHeaderPrintView,
  TextEditorPrintView,
} from './ui';
import { useExportPdf } from './useExportPdf';

const ProtocolTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0),
}));

const StyledSpaceBetweenRowContainer = styled(SpaceBetweenRowContainer)(
  ({ theme }) => ({
    margin: theme.spacing(3, 0),
    flexWrap: 'nowrap',
  }),
);

const PreventPageBreakContainer = styled('div')(() => ({
  pageBreakInside: 'avoid',
}));

const PrintButton = styled(PrimaryButton)(({ theme }) => ({
  margin: theme.spacing(3),
  '@media print': {
    display: 'none',
  },
}));

const StyledDefectHeadlineContainer = styled('div')(() => ({
  breakBefore: 'page',
  width: '100%',
  textAlign: 'center',
}));

interface Props {
  protocol: SigekoProtocolState;
}

export const ExportView = ({ protocol }: Props) => {
  const { t } = useTranslation();
  const { mutate: exportPdf, isLoading } = useExportPdf();

  return (
    <div>
      {/*Export PDF Button*/}
      <Box textAlign="center">
        <PrintButton
          id={'printButton'}
          loading={isLoading}
          onClick={() => {
            // prepare for cloud function
            const printPayload: PrintPayload = {
              htmlToPrint: getHtmlOfThisPageWithoutPrintButton(),
              footerTemplate: footerTemplate,
            };

            exportPdf({ fileName: protocol.title, printPayload });
          }}
        >
          {t('page.export.create_pdf')}
        </PrintButton>
      </Box>
      <ThemeProvider theme={printTheme}>
        <CssBaseline />
        <PDFHeader />

        {/*Protocol title and Infos*/}
        <StyledSpaceBetweenRowContainer>
          <ProtocolTitle textAlign={'left'} variant={'h1'}>
            {protocol.title}
          </ProtocolTitle>
          <DateAndWriterTable
            writtenBy={protocol.writtenBy}
            date={protocol.header.protocolDate ?? new Date()}
          />
        </StyledSpaceBetweenRowContainer>

        {/*Protocol Header*/}
        <PreventPageBreakContainer>
          <ProtocolHeaderPrintView
            header={convertSigekoHeaderToExportHeader(t, protocol.header, [
              'date',
              'location',
              'occasion',
              'projectName',
              'client',
              'clientAddress',
              'projectNumber',
              'protocolNumber',
            ])}
          />
        </PreventPageBreakContainer>

        {/*Protocol Contacts*/}
        <MailingListPrintView
          contacts={protocol.header.contacts}
          variant={protocol.header.variant}
        />

        {/*Protocol Start Text*/}
        {protocol.content.startText.text && (
          <>
            <TextEditorPrintView
              editorText={protocol.content.startText.text}
              title={t(`page.edit_protocol.content.start_text`)}
            />
            {protocol.content.startText.images.length > 0 && (
              <ImagesPrintView images={protocol.content.startText.images} />
            )}
          </>
        )}

        {/*Headline for Defects*/}
        <StyledDefectHeadlineContainer>
          <Typography variant={'h1'}>
            {t('page.export.headline_protocol_number', {
              variant:
                protocol.header.variant === 'blanko'
                  ? ''
                  : protocol.header.variant.toUpperCase() + ' -',
              protocolNumber: protocol.header.protocolNumber,
            })}
          </Typography>
        </StyledDefectHeadlineContainer>

        {/*Protocol Defects*/}
        <DefectsPrintView defects={protocol.content.defects} />

        {/*Protocol End Text*/}
        {protocol.content.endText.text && (
          <>
            <TextEditorPrintView
              editorText={protocol.content.endText.text}
              title={t(`page.edit_protocol.content.end_text`)}
            />
            {protocol.content.endText.images.length > 0 && (
              <ImagesPrintView images={protocol.content.endText.images} />
            )}
          </>
        )}
      </ThemeProvider>
    </div>
  );
};

export const getHtmlOfThisPageWithoutPrintButton = (): string => {
  // Clone the entire document
  const tempElement = document.documentElement.cloneNode(true) as Element;

  // create style tag with css, because emotion css uses insertAPI instead of text nodes
  // https://github.com/emotion-js/emotion/issues/1248
  const emotionCss = [...document.querySelectorAll('[data-emotion]')]
    .flatMap((element: any) =>
      [...element.sheet.cssRules].map((rules) => rules.cssText),
    )
    .join('\n');

  const style = document.createElement('style');
  style.setAttribute('type', 'text/css');
  style.appendChild(document.createTextNode(emotionCss));
  const head = tempElement.querySelector('head');
  head?.appendChild(style);

  // Get the element you want to remove
  const elementToRemove = tempElement.querySelector('#printButton');

  // Remove the element from the DOM tree
  if (elementToRemove && elementToRemove.parentNode) {
    elementToRemove.parentNode.removeChild(elementToRemove);
  }

  return tempElement.outerHTML;
};
