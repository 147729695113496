import CachedIcon from '@mui/icons-material/Cached';
import { CircularProgress, IconButton, IconButtonProps } from '@mui/material';
import React from 'react';

interface Props {
  loading: boolean;
}

export const RefreshButton = ({
  loading,
  ...props
}: Props & IconButtonProps) => {
  return (
    <IconButton {...props}>
      {loading ? (
        <CircularProgress size={24} color={'inherit'} />
      ) : (
        <CachedIcon />
      )}
    </IconButton>
  );
};
