import { generatePath } from 'react-router-dom';

export const HOME = '/';
export const REGISTER = '/register';
export const FORGOT_PASSWORD = '/forgot-password';
export const MANAGE_USER = '/users';
export const LOGIN = '/login';
export const TEMPLATES = '/templates';

export const EDIT_PROTOCOL = '/protocols/:id';

export function generateEditProtocolPath(id: string) {
  return generatePath(EDIT_PROTOCOL, { id });
}

export const EXPORT = '/export/:id';

export function generateExportPath(id: string) {
  return generatePath(EXPORT, { id });
}
