import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolStatus } from '@ibag/common';

import { StatusFilter } from './StatusFilter';
import { UserFilter } from './UserFilter';

const enum FilterTypes {
  STATUS = 'status',
  CREATED_BY = 'createdBy',
}

type Filter =
  | { type: FilterTypes.STATUS; value: ProtocolStatus | null }
  | { type: FilterTypes.CREATED_BY; value: string | null };

interface FilterState {
  status: ProtocolStatus | null;
  createdBy: string | null;
}

interface Props {
  selectedFilterState: FilterState;
  onFilterChange: (filter: Filter) => void;
}

export const Filter = ({ selectedFilterState, onFilterChange }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <UserFilter
        style={{ minWidth: 200 }}
        label={t('page.home.protocol_table.filter.user.title')}
        selectedFilter={selectedFilterState.createdBy ?? null}
        onFilterChange={(selectedUser) => {
          onFilterChange({ type: FilterTypes.CREATED_BY, value: selectedUser });
        }}
      />
      <StatusFilter
        style={{ minWidth: 200 }}
        label={t('page.home.protocol_table.filter.status.label')}
        selectedFilter={selectedFilterState.status ?? null}
        onFilterChange={(selectedStatus) => {
          onFilterChange({ type: FilterTypes.STATUS, value: selectedStatus });
        }}
      />
    </>
  );
};
