import WestIcon from '@mui/icons-material/West';
import {
  Divider,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '@/common/ui/buttons';
import { FormContainer } from '@/common/ui/containers';
import { ErrorMessage } from '@/common/ui/error-message';
import { regex } from '@/common/utils/regex';
import { CenteredPage, RoundedAuthContainer } from '@/pages/auth/common';
import { routes } from '@/routing';

import { useSendResetEmail } from './useSendResetEmail';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(3),
  },
}));

interface FormValues {
  email: string;
}

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  });

  const {
    mutate: sendResetEmail,
    isLoading,
    error,
  } = useSendResetEmail(() => {
    navigate(routes.LOGIN, { replace: true });
  });

  const onSubmit: SubmitHandler<FormValues> = (formValues) => {
    sendResetEmail(formValues.email);
  };

  return (
    <CenteredPage>
      <RoundedAuthContainer>
        <div>
          <StyledContainer>
            <IconButton color="primary" onClick={() => navigate(-1)}>
              <WestIcon />
            </IconButton>

            <Typography variant={'h2'}>
              {t('page.reset.header_text')}
            </Typography>
          </StyledContainer>

          <Divider />
          <Typography>{t('page.reset.description')}</Typography>
          <form>
            <FormContainer>
              <Controller
                control={control}
                name={'email'}
                rules={{
                  required: {
                    value: true,
                    message: t('common.validation.required'),
                  },
                  pattern: {
                    value: regex.email,
                    message: t('common.validation.email'),
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    ref={ref}
                    autoCapitalize={'none'}
                    type={'email'}
                    label={t('page.reset.email_label')}
                    autoComplete={'email'}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />

              <ErrorMessage error={error} />

              <PrimaryButton
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('page.reset.submit_button')}
              </PrimaryButton>
            </FormContainer>
          </form>
        </div>
      </RoundedAuthContainer>
    </CenteredPage>
  );
};
