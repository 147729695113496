import React from 'react';
import { useParams } from 'react-router-dom';

import { ProtocolType } from '@ibag/common';

import { VisibilityChangeProvider } from '@/common/providers/visibility-change-provider';

import { ProtocolProvider, ProtocolStoreFactory } from './common';
import { SigekoProtocol, createSigekoProtocolStore } from './sigeko';

// Register the store factory
ProtocolStoreFactory.register(ProtocolType.SIGEKO, createSigekoProtocolStore);

export const EditProtocolPage = () => {
  const { id } = useParams();

  return id ? (
    <VisibilityChangeProvider>
      <ProtocolProvider protocolId={id}>
        <SigekoProtocol />
      </ProtocolProvider>
    </VisibilityChangeProvider>
  ) : null;
};
