import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProtocolState } from '../common';
import { SigekoProtocolDefaultTextField } from './SigekoProtocolDefaultTextField';
import { SigekoStore } from './sigeko-store';

export const SigekoProtocolEndText = () => {
  const { t } = useTranslation();
  const [endText] = useProtocolState((s: SigekoStore) => [
    s.protocol.content.endText,
  ]);

  return (
    <SigekoProtocolDefaultTextField
      label={t(`page.edit_protocol.content.end_text`)}
      item={endText}
      stateSelector={(s) => s.content.endText}
    />
  );
};
