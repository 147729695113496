import React from 'react';

import { usePeriodicSync } from '@/common/hooks/use-periodic-sync';
import { useSyncTemplates } from '@/common/hooks/use-sync-protocols';
import { useUpdateTemplates } from '@/common/hooks/use-update-templates';
import { Messages } from '@/common/ui/messages';

interface Props {
  children: React.ReactNode;
}

/**
 * Wrapper component for the application, that is applied when the user has successfully authenticated.
 * @param props
 * @constructor
 */
export const AuthenticatedLayout = ({ children }: Props) => {
  usePeriodicSync();
  useUpdateTemplates();
  useSyncTemplates();

  return (
    <>
      {children}
      <Messages />
    </>
  );
};
