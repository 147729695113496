import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)(() => ({
  '.MuiDataGrid-columnHeaderTitle': {
    opacity: 0.5,
  },
  '&.MuiDataGrid-root': {
    border: 'none',
    fontWeight: 400,
  },
})) as typeof DataGrid;
