import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import React from 'react';

import { useEditable } from '@/pages/edit-protocol/common';

export const HeaderTextField = (props: TextFieldProps) => {
  const editable = useEditable();

  return <TextField disabled={!editable ? true : props.disabled} {...props} />;
};

export const HeaderDatePicker = <TInputDate, TDate = TInputDate>(
  props: DatePickerProps<TInputDate, TDate>,
) => {
  const editable = useEditable();

  return <DatePicker disabled={!editable ? true : props.disabled} {...props} />;
};
