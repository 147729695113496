import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolUserDto } from '@ibag/common';

import { convertDateToDateString } from '@/common/utils/format-date';

interface Props {
  writtenBy: ProtocolUserDto | null;
  date: Date;
}

const StyledTable = styled('table')(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'bold',
  '& tr > td:first-child': {
    paddingRight: theme.spacing(0.5),
  },
}));

export function DateAndWriterTable(props: Props) {
  const { t } = useTranslation();
  return (
    <StyledTable>
      <tbody>
        {props.writtenBy?.name && (
          <tr>
            <td>{t('page.export.protocol_title.written_by')}</td>
            <td>{props.writtenBy?.name}</td>
          </tr>
        )}
        <tr>
          <td>{t('page.export.protocol_title.print_date')}</td>
          <td>{convertDateToDateString(props.date)}</td>
        </tr>
      </tbody>
    </StyledTable>
  );
}
