import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import { Divider, SvgIcon, Typography } from '@mui/material';
import React from 'react';

import { DashboardSvg, LogoutSvg, TemplatesSvg, UsersSvg } from '@/assets/svg';
import { useLogout } from '@/common/hooks/use-logout';
import { MenuButton } from '@/common/layout/MenuButton';
import { HOME, MANAGE_USER, TEMPLATES } from '@/routing/routes';

import PackageJson from '../../../package.json';
import { useInstallationPromptEvent } from '../providers/installation-provider';
import {
  FooterContainer,
  MenuContainer,
  PageContainer,
  ScreenContainer,
  SideMenu,
  StyledLogo,
} from './styles';

interface Props {
  children: React.ReactNode;
}

export const MenuLayout = ({ children }: Props) => {
  const { mutate: logout } = useLogout();
  const pwaInstallEvent = useInstallationPromptEvent();
  return (
    <ScreenContainer>
      <SideMenu>
        <StyledLogo height={'100%'} width={'100%'} />
        <MenuContainer>
          <MenuButton
            to={HOME}
            icon={<DashboardSvg height={'100%'} width={'100%'} />}
          />
          <MenuButton
            to={TEMPLATES}
            icon={<TemplatesSvg height={'100%'} width={'100%'} />}
          />
          <MenuButton
            to={MANAGE_USER}
            icon={<UsersSvg height={'100%'} width={'100%'} />}
          />
          {pwaInstallEvent && (
            <>
              <Divider sx={(theme) => ({ margin: theme.spacing(2, 3) })} />
              <MenuButton
                onClick={() => {
                  pwaInstallEvent?.prompt();
                }}
                icon={
                  <SvgIcon htmlColor={'#757897'}>
                    <AddToHomeScreenIcon />
                  </SvgIcon>
                }
              />
            </>
          )}
        </MenuContainer>
        <FooterContainer>
          <MenuButton
            onClick={() => logout()}
            icon={<LogoutSvg height={'80%'} width={'80%'} />}
          />
          <Typography
            textAlign={'center'}
            variant={'caption'}
            color={'lightgray'}
          >{`v${PackageJson.version}`}</Typography>
        </FooterContainer>
      </SideMenu>
      <PageContainer>{children}</PageContainer>
    </ScreenContainer>
  );
};
