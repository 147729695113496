import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Contact, ContactCategory } from '@/common/types/protocol';
import { FormContainer } from '@/common/ui/containers';
import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

interface Props {
  control: Control<Contact>;
  formErrors: FieldErrors<Contact>;
}

export const UpsertContactForm = ({ control, formErrors }: Props) => {
  const { t } = useTranslation();
  const [variant] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.variant,
  ]);
  return (
    <FormContainer>
      {/*Contact Name*/}
      <Controller
        control={control}
        name={'name'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            autoCapitalize={'true'}
            type={'name'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={t(
              `page.edit_protocol.${variant}.header.inputs.mailing_list.contact_dialog_inputs.name`,
            )}
            autoComplete={'name'}
            error={!!formErrors.name}
            helperText={formErrors.name?.message}
          />
        )}
      />

      {/*Organisation*/}
      <Controller
        control={control}
        name={'organisation'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            autoCapitalize={'true'}
            type={'name'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={t(
              `page.edit_protocol.${variant}.header.inputs.mailing_list.contact_dialog_inputs.organisation`,
            )}
            error={!!formErrors.organisation}
            helperText={formErrors.organisation?.message}
          />
        )}
      />

      {/*Category*/}
      <FormControl>
        <FormLabel>
          {t(
            `page.edit_protocol.${variant}.header.inputs.mailing_list.contact_dialog_inputs.category.label`,
          )}
        </FormLabel>
        <Controller
          control={control}
          name={'category'}
          rules={{
            required: {
              value: true,
              message: t('common.validation.required'),
            },
          }}
          render={({ field }) => (
            <RadioGroup {...field}>
              {Object.values(ContactCategory).map((category) => {
                return (
                  <FormControlLabel
                    key={category}
                    value={category}
                    control={<Radio />}
                    label={t(
                      `page.edit_protocol.${variant}.header.inputs.mailing_list.contact_dialog_inputs.category.${category}`,
                    )}
                  />
                );
              })}
            </RadioGroup>
          )}
        />
      </FormControl>
    </FormContainer>
  );
};
