import { styled } from '@mui/material/styles';

import { RoundedContainer } from '@/common/ui/containers';

export const RoundedAuthContainer = styled(RoundedContainer)(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));
