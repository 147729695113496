import { Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SideMenuButton, useProtocolState } from '../../common';
import { SigekoStore } from '../sigeko-store';

export const ToggleDoneDefectsButton = () => {
  const { t } = useTranslation();

  const [showDone, toggleShowDone] = useProtocolState((s: SigekoStore) => [
    s.showDone,
    s.toggleShowDone,
  ]);

  return (
    <SideMenuButton
      icon={showDone ? <VisibilityOff /> : <Visibility />}
      onClick={() => toggleShowDone()}
    >
      {showDone
        ? t('page.edit_protocol.actions.show_done.in_active')
        : t('page.edit_protocol.actions.show_done.active')}
    </SideMenuButton>
  );
};
