import { omit } from 'lodash';
import { StateCreator, StoreApi, createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ProtocolType, TemplateType } from '@ibag/common';

import {
  BaseProtocol,
  ImageSize,
  Protocol,
  RichTextSelector,
} from '@/common/types/protocol';
import { EditorContentState } from '@/common/types/protocol/common';
import { Database, DatabaseImage } from '@/services/database';

import { DatabaseStorage } from './database-storage';
import { protocolVersion } from './protocol-version-middleware';

export interface BaseStore<S> {
  protocol: S;
  replaceProtocol: (newProtocol: S) => void;

  updateText: (
    selector: RichTextSelector<S>,
    editorState: EditorContentState,
  ) => void;

  sortImages: (
    selector: RichTextSelector<S>,
    startIdx: number,
    targetIdx: number,
  ) => void;
  addImages: (selector: RichTextSelector<S>, images: DatabaseImage[]) => void;
  changeImageSize: (
    selector: RichTextSelector<S>,
    imageId: string,
    size: ImageSize,
  ) => void;

  deleteImage(selector: RichTextSelector<S>, imageId: string): void;

  activeEditorId: string | null;
  setActiveEditorId: (id: string | null) => void;

  // When the user opens the protocol, we need the protocol number to limit increasing or decreasing
  protocolNumberOnOpen: number;
  setProtocolNumberOnOpen: (protocolNumber: number) => void;

  templateTypeFilter: TemplateType | null;
  setTemplateTypeFilter: (newTemplateTypeFilter: TemplateType | null) => void;

  title: string;
  setTitle: (newTitle: string) => void;

  changeProtocolNumber: (newProtocolNumber: number) => void;
}

export type ProtocolState<T extends BaseProtocol> = Omit<
  T,
  | 'revision'
  | 'createdAt'
  | 'updatedAt'
  | 'lockedBy'
  | 'localVersion'
  | 'offlineAvailable'
>;

function convertProtocolToProtocolState<T extends BaseProtocol>(
  protocol: T,
): ProtocolState<T> {
  const protocolState = omit(protocol, [
    'revision',
    'createdAt',
    'updatedAt',
    'lockedBy',
    'localVersion',
    'offlineAvailable',
  ]);
  return protocolState as ProtocolState<T>;
}

export function createStoreWithMiddleware<T extends { protocol: any }>(
  protocolId: string,
  database: Database,
  initializer: ProtocolStoreInitializer<T>,
) {
  return createStore<T>()(
    persist(protocolVersion(immer(initializer)), {
      name: protocolId,
      storage: new DatabaseStorage(database),
    }),
  );
}

export type ProtocolStoreInitializer<T extends { protocol: any }> =
  StateCreator<
    T,
    [['zustand/immer', never]],
    [
      ['zustand/persist', unknown],
      ['protocolVersion', unknown],
      ['zustand/immer', never],
    ]
  >;

type Creator = (initialState: any, database: Database) => StoreApi<any>;

export class ProtocolStoreFactory {
  private static initializers = new Map<ProtocolType, Creator>();

  static register(type: ProtocolType, creator: Creator) {
    this.initializers.set(type, creator);
  }

  static createProtocolStore(protocol: Protocol, database: Database) {
    const creator = this.initializers.get(protocol.type);
    if (!creator) {
      throw new Error(
        `No store creator for protocol ${protocol.type} registered!`,
      );
    }

    return creator(convertProtocolToProtocolState(protocol), database);
  }
}
