import { UpdateTemplatesService } from '@/services/template';

import { database } from './database-instance';
import { taskQueue } from './task-queue-instance';
import { templateService } from './template-service';

export const updateTemplatesService = new UpdateTemplatesService(
  templateService,
  taskQueue,
  database,
);
