import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, SecondaryButton } from '../buttons';

interface Props {
  onConfirm: () => Promise<void> | void;
  title: ReactNode;
  text: ReactNode;
  children: (onPress: () => void) => ReactNode;
  className?: string;
  cancelButtonTitle?: string;
  confirmButtonTitle?: string;
  closeOnError?: boolean;
}

export type ConfirmProps = Props;

export const Confirm = ({
  children,
  title,
  className,
  text,
  onConfirm,
  cancelButtonTitle,
  confirmButtonTitle,
  closeOnError = true,
}: Props) => {
  const { isOpen, open, close } = useDialog<void, void>();

  const { t } = useTranslation();

  return (
    <>
      {children(open)}

      <Dialog open={isOpen} onClose={() => close()} className={className}>
        <DialogTitle color={'primary'}>{title}</DialogTitle>
        <DialogContent>
          <div>{text}</div>
        </DialogContent>
        <DialogActions>
          <SecondaryButton color={'secondary'} onClick={() => close()}>
            {cancelButtonTitle ? cancelButtonTitle : t('common.actions.cancel')}
          </SecondaryButton>
          <PrimaryButton
            onClick={async () => {
              try {
                await onConfirm();
              } finally {
                if (closeOnError) close();
              }
            }}
          >
            {confirmButtonTitle ? confirmButtonTitle : t('common.actions.ok')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
