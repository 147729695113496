import { Contact } from '@/common/types/protocol';
import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

export const useEditContact = (id: string) => {
  const [contacts, updateHeaderField] = useProtocolState((s: SigekoStore) => [
    s.protocol.header.contacts.filter((contact) => contact.id !== id),
    s.updateHeaderField,
  ]);
  const editContact = (changedContact: Contact) => {
    updateHeaderField('contacts', [...contacts, changedContact]);
  };

  return { editContact };
};
