import React from 'react';

/*
 * Returns the latest not-undefined value
 * or fallbackValue, if value has not yet been defined
 * @param value
 * @param initialFallbackValue - the value returned if the passed value is undefined
 */
export function useNotUndefined<T>(
  value: T | undefined,
  initialFallbackValue: T,
): T {
  const [current, setCurrent] = React.useState(
    value === undefined ? initialFallbackValue : value,
  );
  React.useEffect(() => {
    setCurrent((previous) => (value !== undefined ? value : previous));
  }, [value, setCurrent]);

  return current;
}
