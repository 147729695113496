import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  fontWeight: 700,
  textTransform: 'none',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

/*Problem: with LoadingButtonProps, when we use the LoadingButtonProps typescript is in endless loop Bug https://github.com/mui/material-ui/issues/30038
Workaround: with extends ButtonProps*/
interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  loadingPosition?: 'start' | 'end' | 'center';
}

export const BaseButton = ({ ...props }: LoadingButtonProps & ButtonProps) => {
  return <StyledLoadingButton size={'large'} {...props} />;
};
