import { IconButton, IconButtonProps, styled } from '@mui/material';
import React from 'react';

import { MoreVerticalSvg } from '@/assets/svg';

export const StyledIconButton = styled(IconButton)(() => ({
  opacity: 0.5,
  width: 40,
  height: 40,
  '&.MuiIconButton-sizeSmall': {
    opacity: 1,
    width: 30,
    height: 30,
  },
})) as typeof IconButton;

export const OverflowIconButton = ({ ...props }: IconButtonProps) => {
  return (
    <StyledIconButton {...props}>
      <MoreVerticalSvg height={'100%'} width={'100%'} fill={'currentColor'} />
    </StyledIconButton>
  );
};
