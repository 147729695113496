import React from 'react';

import { SyncStatusIcon } from './SyncStatusIcon';

export const createSyncStatus = (isSynced: boolean) => {
  return {
    icon: <SyncStatusIcon status={isSynced} />,
    description: isSynced ? 'Synced' : 'not synced',
  };
};
