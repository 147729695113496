import { SigekoProtocolDefect } from '@/common/types/protocol';

// remove not done defects except for defects done after given protocol number
export const filterOpenDefects = (
  defects: SigekoProtocolDefect[],
  protocolNumber: number,
) => {
  return defects.filter(
    (defect) => defect.done === null || defect.done >= protocolNumber,
  );
};
