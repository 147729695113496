import { styled } from '@mui/material/styles';

import { Page } from './Page';

export const CenteredPage = styled(Page)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.main,
}));
