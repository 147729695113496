import { API_URL } from '@/common/config';

export interface DatabaseImage {
  id: string;
  image: File;
}

export function generateServerImageUrl(dbImage: DatabaseImage): string {
  return `${API_URL}/images/${dbImage.id}`;
}

export function isServerImageUrl(url: string): boolean {
  return url.startsWith(`${API_URL}/images/`);
}
