import { LockOpen, LockPerson } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLockProtocol } from '@/common/hooks/use-lock-protocol';
import {
  SideMenuButton,
  useEditable,
  useProtocol,
} from '@/pages/edit-protocol/common';

interface Props {
  protocolId: string;
}

export const LockProtocolButton = ({ protocolId }: Props) => {
  const { t } = useTranslation();

  const protocol = useProtocol();

  const { locked, canLock, canUnlock, lock, unlock, isLoading } =
    useLockProtocol(protocolId, protocol.lockedBy);

  const editable = useEditable();

  return locked ? (
    <SideMenuButton
      loading={isLoading}
      disabled={!editable || !canUnlock}
      onClick={unlock}
      icon={<LockOpen />}
    >
      {t('common.actions.unlock')}
    </SideMenuButton>
  ) : (
    <SideMenuButton
      loading={isLoading}
      disabled={!editable || !canLock}
      onClick={lock}
      icon={<LockPerson />}
    >
      {t('common.actions.lock')}
    </SideMenuButton>
  );
};
