import { Save } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useIsOfflineAvailable,
  useMakeOfflineAvailable,
} from '@/common/hooks/offline-available';
import { SideMenuButton } from '@/pages/edit-protocol/common';

interface Props {
  protocolId: string;
}

export const MakeOfflineAvailableButton = ({ protocolId }: Props) => {
  const { t } = useTranslation();

  const { canMakeOfflineAvailable } = useIsOfflineAvailable(protocolId);
  const { isLoading, makeOfflineAvailable } = useMakeOfflineAvailable(
    protocolId,
    { showErrorSnackbar: true, showSuccessSnackbar: true },
  );

  return (
    <SideMenuButton
      disabled={!canMakeOfflineAvailable}
      loading={isLoading}
      onClick={() => makeOfflineAvailable()}
      icon={<Save />}
    >
      {t('common.actions.make_offline_available')}
    </SideMenuButton>
  );
};
