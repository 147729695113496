import { ErrorType } from '@ibag/common';

import { AppError } from '@/common/types/errors';

export interface ServiceWorkerWrapper {
  update: () => void;
}

export class ServiceWorkerWrapperImpl implements ServiceWorkerWrapper {
  constructor(private readonly reload: () => Promise<void>) {}

  async update(): Promise<void> {
    const registrationWaiting = (
      await navigator.serviceWorker.getRegistration()
    )?.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if ((e.target as ServiceWorker | null)?.state === 'activated') {
          this.reload();
        }
      });
    } else {
      throw new AppError(ErrorType.SERVICE_WORKER_NOT_FOUND);
    }
  }
}
